
import {
  watch,
  defineComponent,
  reactive,
  ref,
  toRefs,
  PropType,
  toRef,
  onMounted,
  nextTick
} from "vue";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
import SignaturePad from "signature_pad";
import { message } from "ant-design-vue";
import { after } from "lodash";

export default defineComponent({
  emits: ["toNextTab", "receive"],
  components: {
    PlusOutlined,
    CloseOutlined
  },
  setup(props, context) {
    const state = reactive({
      visible: false,
      isShowFull: false, // 是否横屏显示
      signaturePad: null, // 存放竖屏SignaturePad对象
      signaturePadFull: null, // 存放横屏SignaturePad对象
      value: "",
      fullValue: "",
      config: {
        penColor: "black", //笔刷颜色
        minWidth: 1 //最小宽度
      },
      key: 0,
      orient: window.orientation,
      imgType: "image/png"
    });
    //   function handler(e:any){e.preventDefault()}
    //   /*解决iphone页面层级相互影响滑动的问题*/
    //  function closeTouch(){
    //           console.log('进入');

    //       document.getElementsByTagName("body")[0].addEventListener('touchmove',
    //           handler,{passive:false}as any);//阻止默认事件

    //   }
    //  function openTouch(){
    //       document.getElementsByTagName("body")[0].removeEventListener('touchmove',
    //           handler,{passive:false}as any);//打开默认事件
    //            console.log('离开');
    //   }

    function init() {
      console.log(state.signaturePad);

      if (!state.signaturePad) {
        state.key++;
        nextTick(() => {
          let canvas: any = document.querySelector(".signaturePad");
          state.signaturePad = new SignaturePad(canvas, state.config);
          state.signaturePad.onEnd = () => {
            context.emit(
              "receive",
              state.signaturePad.toDataURL(state.imgType)
            ); //通知父组件改变。
          };
          // 竖屏
          canvas.height = document.body.clientHeight - 100;
          canvas.width = document.body.clientWidth;
          console.log(
            canvas.width,
            canvas.height,
            document.body.clientHeight,
            document.body.clientWidth
          );
        });
      } else {
        state.isShowFull = false;
        setTimeout(() => {
          let canvas: any = document.querySelector(".signaturePad");
          state.signaturePad = new SignaturePad(canvas, state.config);
          // 竖屏
          canvas.height = document.body.clientHeight;
          canvas.width = document.body.clientWidth;
          let _flag = state.signaturePadFull.isEmpty();
          state.signaturePad.clear();

          if (!_flag) {
            state.value = state.signaturePadFull.toDataURL(state.imgType);
            rotateBase64Img(state.value, 90, res => {
              let _option = {
                width: document.body.clientWidth,
                height: document.body.clientHeight
              };
              state.signaturePad.fromDataURL(res, _option);
              try {
                state.signaturePadFull.clear();
              } catch (error) {}
            });
          }
        }, 100);
      }
    }
    function close() {
      state.visible = false;
    }
    function againFull() {
      if (state.isShowFull) {
        try {
          state.signaturePadFull.clear();
        } catch (error) {}
      } else {
        try {
          state.signaturePad.clear();
        } catch (error) {}
      }
    }
    function confirmFull() {
      try {
        if (state.isShowFull) {
          let _flag = state.signaturePadFull.isEmpty();
          if (!_flag) {
            state.fullValue = state.signaturePadFull.toDataURL(state.imgType);
            let orient = 0;
            if (state.orient == 90 || state.orient == -90) {
              orient = 0;
            } else {
              orient = 90;
            }
            rotateBase64Img(state.fullValue, orient, res => {
              // let _option = {
              //   width: document.body.clientWidth - 30,
              //   height: document.body.clientHeight / 3
              // }
              // state.signaturePad.fromDataURL(res, _option);
              state.visible = false;
              context.emit("receive", res); //通知父组件改变。
            });
          }
        } else {
          let _flag = state.signaturePad.isEmpty();

          if (!_flag) {
            state.fullValue = state.signaturePad.toDataURL(state.imgType);
            rotateBase64Img(state.fullValue, -90, res => {
              // let _option = {
              //   width: document.body.clientWidth - 30,
              //   height: document.body.clientHeight / 3
              // }
              // state.signaturePad.fromDataURL(res, _option);
              state.visible = false;
              context.emit("receive", res); //通知父组件改变。
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    function fullScreenShow() {
      state.key++;
      state.isShowFull = true;
      setTimeout(() => {
        let canvas: any = document.querySelector(".signaturePadFull");
        state.signaturePadFull = new SignaturePad(canvas, state.config);
        if (state.orient == 90 || state.orient == -90) {
          canvas.height = document.body.clientHeight;
          canvas.width = document.body.clientWidth;
        } else {
          canvas.height = document.body.clientHeight;
          canvas.width = document.body.clientWidth;
        }

        let _flag = state.signaturePadFull.isEmpty();
        state.signaturePadFull.clear();
        console.log(state.orient, "=========================");
        console.log(canvas.height, "canvas.height ");
        console.log(canvas.width, "canvas.width ");
        //  alert(canvas.height+'=height'+ canvas.width+'width'+state.orient);

        if (!_flag) {
          console.log(state.orient, "state.orientstate.orient");

          state.value = state.signaturePadFull.toDataURL(state.imgType);
          let orient = 0;
          if (state.orient == 90 || state.orient == -90) {
          } else {
          }
          rotateBase64Img(state.value, 0, res => {
            let _option = {};
            if (state.orient == 90 || state.orient == -90) {
              _option = {
                width: document.body.clientHeight,
                height: document.body.clientWidth
              };
            } else {
              _option = {
                width: document.body.clientWidth,
                height: document.body.clientHeight
              };
            }

            state.signaturePadFull.fromDataURL(res, _option);
            try {
              state.signaturePadFull.clear();
            } catch (error) {}
          });
        }
      }, 100);
    }
    function againSignature() {
      state.signaturePad.clear();
      context.emit("receive", ""); //通知父组件改变。
    }
    // base64图片旋转方法
    function rotateBase64Img(src, edg, callback) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = (edg % 360) + 360);
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = {
        sx: 0,
        sy: 0,
        ex: 0,
        ey: 0
      }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = function() {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);

        ctx.rotate((edg * Math.PI) / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        );
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        callback(canvas.toDataURL(state.imgType));
      };
    }
    function onVisible() {
      state.visible = true;
      let evt =
        "onorientationchange" in window ? "orientationchange" : "resize";
      window.addEventListener(
        evt,
        function() {
          // 监听旋转
          state.key++;
          state.orient = window.orientation;
        },
        false
      );
      // init()
      fullScreenShow();
    }
    onMounted(() => {
      console.log(123);
    });
    watch(
      () => state.orient,
      () => {
        // state.signaturePadFull = null
        // 刚进来
        // console.log('手机模式',val);
        // console.log(this.orient,this.key);
        // let canvas: any = document.querySelector('.canvas');
        // state.signaturePadFull = new SignaturePad(canvas, state.config);
        console.log(state.orient, "state.orient");
        fullScreenShow();
        if (state.orient === 180 || state.orient === 0) {
          // 竖屏
          // canvas.height = document.body.clientWidth - 100;
          // canvas.width = document.body.clientHeight;
          // init()
          //  fullScreenShow()
        }
        if (state.orient === 90 || state.orient === -90) {
          // 横屏
          // canvas.height = document.body.clientWidth - 100;
          // canvas.width = document.body.clientHeight;
          // fullScreenShow()
        }
      }
    );
    return {
      ...toRefs(state),
      againFull,
      againSignature,
      fullScreenShow,
      confirmFull,
      close,
      onVisible
    };
  }
});
