import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_UploadOutlined = _resolveComponent("UploadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    layout: "horizontal",
    ref: "formRef",
    model: _ctx.formState,
    labelCol: _ctx.labelCol,
    wrapperCol: _ctx.wrapperCol,
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "登记人",
                name: "registerIDName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.registerIDName,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.registerIDName) = $event)),
                    placeholder: "请输入登记人"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "登记日期",
                name: "registeDate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    value: _ctx.formState.registeDate,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.registeDate) = $event)),
                    format: "YYYY-MM-DD",
                    placeholder: "请选择登记日期",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "执业资格类型",
                name: "practiceType"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.practiceType,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.practiceType) = $event)),
                    placeholder: "请选择执业资格类型",
                    onSelect: _ctx.handPracticeTypeSelect
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qualifications, (item, key) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: key,
                          value: key
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "onSelect"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "执业资格名称",
                name: "qualificationName"
              }, {
                default: _withCtx(() => [
                  (_ctx.names)
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 0,
                        value: _ctx.formState.qualificationName,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.qualificationName) = $event)),
                        placeholder: "请选择执业资格类型",
                        onSelect: _ctx.handPracticeNameSelect
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.names, (item, key) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: key,
                              value: key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.text), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "onSelect"]))
                    : (_openBlock(), _createBlock(_component_a_input, {
                        key: 1,
                        value: _ctx.formState.qualificationName,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.qualificationName) = $event)),
                        placeholder: "请输入执业资格名称"
                      }, null, 8, ["value"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "执业资格证书编号",
                name: "qualificationCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.qualificationCode,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.qualificationCode) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "第一专业",
                name: "firstMajor"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.firstMajor,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.firstMajor) = $event)),
                    placeholder: "请选择执业资格类型",
                    onSelect: _ctx.handMajorSelect
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstMajors, (item, key) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: key,
                          value: key
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "onSelect"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "第二专业",
                name: "twoMajor"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.twoMajor,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.twoMajor) = $event)),
                    placeholder: "请选择执业资格类型"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.twoMajors, (text, key) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: key,
                          value: key
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(text), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "执业资格证书发证时间",
                name: "qualificationIssueDate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    value: _ctx.formState.qualificationIssueDate,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.qualificationIssueDate) = $event)),
                    format: "YYYY-MM-DD",
                    placeholder: "请选择发证时间",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "执业证书保管人",
                name: "qualificationKeeperIDName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.qualificationKeeperIDName,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formState.qualificationKeeperIDName) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册证书编号",
                name: "registerCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.registerCode,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.registerCode) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册证发证日期",
                name: "registeIssueDate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    value: _ctx.formState.registeIssueDate,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formState.registeIssueDate) = $event)),
                    format: "YYYY-MM-DD",
                    placeholder: "请选择发证时间",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册证书失效时间",
                name: "registelLoseDate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    value: _ctx.formState.registelLoseDate,
                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formState.registelLoseDate) = $event)),
                    format: "YYYY-MM-DD",
                    placeholder: "请选择失效时间",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册证书保管人",
                name: "registeKeeperIDName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.registeKeeperIDName,
                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formState.registeKeeperIDName) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册印章编号",
                name: "sealCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.sealCode,
                    "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formState.sealCode) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册印章失效时间",
                name: "sealLoseDate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    value: _ctx.formState.sealLoseDate,
                    "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formState.sealLoseDate) = $event)),
                    format: "YYYY-MM-DD",
                    placeholder: "请选择失效时间",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册印章保管者",
                name: "sealKeeperIDName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.sealKeeperIDName,
                    "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formState.sealKeeperIDName) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "继续教育参加时间",
                name: "continueTrainDate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    value: _ctx.formState.continueTrainDate,
                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.formState.continueTrainDate) = $event)),
                    format: "YYYY-MM-DD",
                    placeholder: "请选择继续教育参加时间",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "继续教育学时",
                name: "continueTrainLength"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.continueTrainLength,
                    "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formState.continueTrainLength) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "继续教育已完成学时",
                name: "continueTrainCompleteLength"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.continueTrainCompleteLength,
                    "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.formState.continueTrainCompleteLength) = $event)),
                    placeholder: "请输入职务等级"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "执业资格证书附件",
                name: "qualifyFile"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_upload, {
                    name: "file",
                    fileList: _ctx.qualifyFileList,
                    "onUpdate:fileList": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.qualifyFileList) = $event)),
                    multiple: true,
                    action: _ctx.action,
                    headers: _ctx.headers,
                    onChange: _cache[21] || (_cache[21] = (info) => _ctx.handleChange(info, 'qualifyFile'))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_UploadOutlined),
                          _createTextVNode("上传附件")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["fileList", "action", "headers"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "注册证附件",
                name: "registerFile"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_upload, {
                    name: "file",
                    fileList: _ctx.registerFileList,
                    "onUpdate:fileList": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.registerFileList) = $event)),
                    multiple: true,
                    action: _ctx.action,
                    headers: _ctx.headers,
                    onChange: _cache[23] || (_cache[23] = (info) => _ctx.handleChange(info, 'registerFile'))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_UploadOutlined),
                          _createTextVNode("上传附件")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["fileList", "action", "headers"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"justify-content":"center"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            "html-type": "submit",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("提交")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            style: {"margin-left":"10px"},
            onClick: _ctx.resetForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("置空")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "labelCol", "wrapperCol", "rules"]))
}