
import { defineComponent, reactive, toRefs, onMounted,watch } from "vue";
import { ajaxGetDictItems } from "@/api/hrUser"
export default defineComponent({
  name: "HMultiSelectTag",
  props: {
    tagType: {
      type: String,
      default: 'select'
    },
    value: {
      type: [String, Number],
    },
    spliter: {
      type: [String],
      defaule: ','
    },
    dictCode: {
      type: [String],
      defaule: ''
    },
    disabled: {
      type: [Boolean],
      defaule: false
    },
    mode: {
      type: [String],
      defaule: ''
    },
    placeholder: {
      type: [String],
      defaule: ''
    },
    allowClearShow:{
      type: [Boolean],
      defaule: true
    }
  },
  setup(props, context) {
    const state = reactive({
      arrayValue: props.tagType == 'radio' ? String(props.value) : (!props.value ? [] : (String(props.value).split(','))),
      dictOptions: []
    })
    onMounted(() => {
      //字典标签专用（通过code获取字典数组）
      ajaxGetDictItems(props.dictCode, null).then(res => {
        if (res) {
          state.dictOptions = res
        }

      }).catch((error) => {
        state.dictOptions = error;


      })
    })
    watch(
      () => props.value,
      () => {
        state.arrayValue = props.tagType == 'radio' ? String(props.value) : (!props.value ? [] : (String(props.value).split(',')))
      }
    )
    function onChange(selectedValue) {
      context.emit('change', selectedValue);
    }
    return {
      ...toRefs(state),
      onChange
    };
  },
});
