
import { 
  defineComponent, 
  onMounted, 
  ref, 
  toRef,
  getCurrentInstance, 
  onBeforeUnmount, 
  createVNode,
  reactive,
  watch,
  h
} from 'vue'
import { ParamEstimateFreight, SubmitPayment, AttachmentItem } from '@/interface/print'
import { message, message as Message } from 'ant-design-vue'
import {
  eleContractPrintOrder, 
  queryPaymentStatus,
  printByIdsPrintOrder,
  setPlaceOrders,
} from '@/api/manage'
import { useRouter, useRoute } from 'vue-router'
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

export default defineComponent({
  name: 'PrintOrderModal',

  components: {
    ReloadOutlined,
    ExclamationCircleOutlined
  },
  setup(props, context) {
    const router = useRouter();
    // const repositories = reactive<AttachmentItem[]>(props.repositories || [])
    const repositories = ref<AttachmentItem[]>([])
    const loadingMore = ref<boolean>(false)
    const confirmLoading = ref<boolean>(false)
    const payImg = ref<string>('')
    const tip = ref<string>('')
    const modalUpdate = ref<string>('')
    const paymentStatus = ref<boolean>(false)
    const timeCount = ref<number>(0)
    const timer = ref<any>(0)
    const params = reactive({
      total_fee: '1',
      out_trade_no: '',
      freightAmount: 0, // 快递费
      printAmount: 0, // 打印费
      totalAmount: 0, // 总价
    })
    async function resetPayment () {
      confirmLoading.value = true
      const result = await queryPaymentStatus({ outTradeNo: params.out_trade_no })
      if (result == 0) {
        Message.warning('查询失败')
      } else {
        // 支付成功处理逻辑
        payImg.value = ''
        paymentStatus.value = true
      }
      confirmLoading.value = false
    }
    function cancel () {
      tip.value = ''
      loadingMore.value = false
      confirmLoading.value = false
      payImg.value = ''
      paymentStatus.value = false
      // 清楚定时器
      if (timer.value) {
        clearTimeout(timer.value)
        timer.value = 0
      }
    }
    const returnPage = () => {
      // 清楚定时器
      if (timer.value) {
        clearTimeout(timer.value)
      }
      router.go(-1)
    }
    // 递归查询支付状态
    async function setTime (out_trade_no: string) {
      timeCount.value++
      const result = await queryPaymentStatus({ outTradeNo: out_trade_no })
      if (result == 0) {
        if (timeCount.value == 60) {
          // 清楚定时器
          if (timer.value) {
            clearTimeout(timer.value)
          }
          if (loadingMore.value) {
            showConfirm()
          }
        } else if (timeCount.value < 60 && timeCount.value > 0) {
          timer.value = 0
          timer.value = setTimeout(() => {
            setTime(out_trade_no)
          }, 1000)
        }
      } else {
        // 支付成功处理逻辑
        payImg.value = ''
        paymentStatus.value = true
        // 清楚定时器
        if (timer.value) {
          clearTimeout(timer.value)
        }
        confirmLoading.value = true
        toGo()
        // tip.value = '正在打印...'
        // printByIdsPrintOrder( { ids: out_trade_no } ).then(res=>{
        //   if (res.length && res[0].success) {
        //     if (expressChecked.value) {
        //       tip.value = '正在提交顺丰...'
        //       setPlaceOrders({ printOrderIds: out_trade_no }).then(result => {
        //         Message.success('提交打印成功；提交顺丰订单成功！')
        //         toGo()
        //       }).catch(err => {
        //         toGo()
        //         Message.success('顺丰提交异常，请联系管理员')
        //         tip.value = ''
        //       })
        //     } else {
        //       toGo()
        //       tip.value = ''
        //       Message.success('提交打印成功！')
        //     }
        //   } else {
        //     confirmLoading.value = false
        //     Message.warning('打印异常，请联系管理员')
        //     tip.value = ''
        //     toGo()
        //   }
        // }).catch(err => {
        //   tip.value = ''
        //   toGo()
        // })
        
      }
    }
    function toGo () {
      let secondsToGo = 4;
      const interval = setInterval(() => {
        secondsToGo -= 1;
        tip.value = `${secondsToGo} 秒后自动关闭.`
        modalUpdate.value = `${secondsToGo} 秒后自动关闭.`
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        window.close()
      }, secondsToGo * 1000);
    }
    async function onSubmit (contractId: string) {
      // 清楚定时器
      if (timer.value) {
        clearTimeout(timer.value)
      }
      loadingMore.value = true

      timeCount.value = 0
      timer.value = 0
      payImg.value = ''
      paymentStatus.value = false
      confirmLoading.value = true
      
      eleContractPrintOrder({ contractId }).then(res=> {
        params.out_trade_no = res.orderId
        params.freightAmount = res.freightAmount
        params.printAmount = res.printAmount
        params.totalAmount = res.totalAmount
        confirmLoading.value = false
        payImg.value = res.paymentCode
        loadingMore.value = true
        // 递归查询支付状态
        setTime(res.orderId)
      }).catch(err => {
        message.warning('请求失败，该合同不可用！')
        confirmLoading.value = false
      })
      
    }
    const showConfirm = () => {
      Modal.confirm({
        title: () => '订单是否已支付?',
        cancelText: () => '继续支付',
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          window.close()
        },
        onCancel() {
          // 清楚定时器
          if (timer.value) {
            clearTimeout(timer.value)
          }
          timeCount.value = 0
          setTime(params.out_trade_no)
        },
        class: 'test',
        closable: false
      });
    };
    onBeforeUnmount(()=> {
      // 清楚定时器
      cancel()
    })
    onMounted(() => {
      const data: any = router.currentRoute.value.query
      onSubmit(data.contractId)
    })
    watch(
      () => loadingMore.value,
      (count, prevCount) => {
        if (!count) {
          // 清楚定时器
          if (timer.value) {
            clearTimeout(timer.value)
          }
        }
      }
    )
    return {
      repositories,
      checked: ref(true),
      paymentStatus,
      loadingMore,
      confirmLoading,
      activeKey: ref('1'),
      paymentActiveKey: ref('1'),
      payImg,
      timeCount,
      resetPayment,
      cancel,
      params,
      returnPage,
      onSubmit,
      modalUpdate, tip,
    };
  },
  
});
