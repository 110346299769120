
import { 
  defineComponent,
  ref, 
  reactive,
  UnwrapRef,
  toRefs
} from 'vue'
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { _area } from '@/components/_util/Area'
import { addAndGetPayment, printUpdate } from '@/api/manage'
import { message as Message } from 'ant-design-vue'
import { CompanyInvoiceCode } from "@/views/InvoiceExpressOrder/data"

export default defineComponent({
  name: 'PrintAppoint',
  components: {
  },
  emits: ['ok'],
  setup(props, context) {
    
    // const formState: UnwrapRef<CompanyInvoiceCode> = reactive({})
    const state = reactive({
      loadingMore: false,
      confirmLoading: false,
      formState: {} as CompanyInvoiceCode
    })

    const rules = {
      invoiceCode: [{ required: true, message: "票号-必填" }],
      invoiceState: [{ required: true, message: "票号-状态" }],
      invoiceType: [{ required: true, message: "票号-类型" }],
    };
    const add = () => {
      state.loadingMore = true
    }
    const edit = (record: CompanyInvoiceCode) => {
      state.loadingMore = true
      state.formState = Object.assign({}, record)
    }
    const cancel = () => {
      state.loadingMore = false
      state.confirmLoading = false
    }
    function onOk() {
      state.loadingMore = false
      context.emit('ok')
    }
    const formRef = ref()
    function onSubmit() {
      formRef.value.validate().then(async () => {
        state.confirmLoading = true
        
        printUpdate(state.formState).then(res => { 
          if (res) {
            Message.success('更新成功')
          } else {
            Message.warning('操作失败！')
          }
          onOk()
          state.confirmLoading = false
        }).catch(err => {
          Message.warning('添加失败')
          state.confirmLoading = false
        })
      }).catch((error: ValidateErrorEntity<CompanyInvoiceCode>) => {
        console.log("error", error);
      });
      
    }

    return {
      ...toRefs(state),
      formRef,
      cancel,
      rules,
      onSubmit,
      onOk,
      add,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      edit
    };
  },
  
});
