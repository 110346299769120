
import { defineComponent, reactive, ref, toRefs, createVNode, toRaw, onMounted } from 'vue';
import { countAllTitle, pageUpTitlePeopleInfo } from '@/api/largeScreenApi'
import ChartCard from '@/components/ChartCard.vue'
import Trend from '@/components/Trend'
import PersonageModal from '@/views/largeScreen/modules/PersonageModal.vue'
import { QuestionCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons-vue';
import { _pvCity } from '@/components/_util/Area'
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router'
import moment from 'moment'
const columns = [
  {
    title: '职称',
    align: "center",
    dataIndex: 'title',
    slots: { customRender: 'title' }
  },
  {
    title: '名称',
    align: "center",
    dataIndex: 'name'
  },
  {
    title: '专业',
    align: "center",
    dataIndex: 'professionName'
  },
  // {
  //   title: '审批部门',
  //   align:"center",
  //   dataIndex: 'AuditDept'
  // },
  // {
  //   title: '证书编号',
  //   align:"center",
  //   dataIndex: 'CertificateNumber'
  // },
  // {
  //   title: '发证日期',
  //   align:"center",
  //   dataIndex: 'IssueDate'
  // },
  {
    title: '聘用时间',
    align: "center",
    dataIndex: 'employDate',
    customRender: function ({ text }) {
      return text ? moment(text).format('YYYY-MM-DD') : text
    }
  },
  // {
  //   title: '业绩数量',
  //   align:"center",
  //   dataIndex: 'achievementCount',
  //   customRender: function({ text }) {
  //     return (text || 0) + '个'
  //   }
  // },
  // {
  //   title: '论文专著数量',
  //   align:"center",
  //   dataIndex: 'demandCount',
  //   customRender: function({ text }) {
  //     if (text) {
  //       return (text) + '个'
  //     }
  //     return '0个'
  //   }
  // },

  // {
  //   title: '是否最高分',
  //   align:"center",
  //   dataIndex: 'isHighest',
  //   customRender:({text}) => {
  //     return text==1?'是':'否'
  //   }
  // },
  {
    title: '审批部门',
    align: "center",
    dataIndex: 'auditDept'
  },
  {
    title: '发证日期',
    align: "center",
    dataIndex: 'issueDate',
    customRender: function ({ text }) {
      return text ? moment(text).format('YYYY-MM-DD') : text
    }
  },
  {
    title: '省份',
    align: "center",
    dataIndex: 'province'
  },
  {
    title: '城市',
    align: "center",
    dataIndex: 'city'
  },
  {
    title: '当前部门',
    align: "center",
    dataIndex: 'deptName'
  },

  // {
  //   title: '距离升级',
  //   align:"center",
  //   dataIndex: 'flowRate',
  //   width: 160,
  //   slots: { customRender: 'flowRate' }
  // },
  {
    title: '操作',
    dataIndex: 'action',
    align: "center",
    width: 100,
    slots: { customRender: 'action' },
    fixed: 'right',
  }
];

export default defineComponent({
  name: 'MassTexting',
  mixins: [],
  props: {
  },
  components: {
    ChartCard,
    Trend,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    PersonageModal,
  },
  setup() {
    const loading = ref(false)
    const loadingTitle = ref(false)
    const personModal = ref(null)
    const state = reactive({
      indexBottomTab: '全部',
      //是否可升级切换操作
      changeRegisterType: () => {
        state.ipagination.current = 1
        initData()
      },
      countList: [],
      countListTab: [{ titleName: '全部' }, { titleName: '技术员' }, { titleName: '助理工程师' }, { titleName: '工程师' }, { titleName: '高级工程师' }, { titleName: '正高级工程师' }],
      columns,
      dataSource: [],
      ipagination: {
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['5', '10', '20', '30'],
        showTotal: (total: string, range: string[]) => {
          return range[0] + "-" + range[1] + " 共" + total + "条"
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      queryParam: {
        name: '',
        isUpLev: false,//是否升级
        nativeList:[],
        province: '',
        city: '',
      },
      //搜索
      searchQuery: () => {
        console.log(state.queryParam)
        state.queryParam.province= state.queryParam?.nativeList[0] ?? ''
        state.queryParam.city = state.queryParam?.nativeList[1]
        
        initData()
      },
      //重置
      searchReset: () => {
        state.ipagination.current = 1
        state.queryParam.name = ''
        state.queryParam.province = ''
        state.queryParam.city = ''
        state.queryParam.isUpLev = false
        initData()
      }
    })
    //分页查询操作
    const tableChange = (pagination: { current: number; pageSize: number; }) => {
      state.ipagination.current = pagination.current
      state.ipagination.pageSize = pagination.pageSize
      initData()
    }
    // 省市
    const pcaaData = _pvCity;

    const getFlowRateNumber = (value: any) => {
      return Number(value)
    }
    const getPercentFormat = (value: string | number) => {
      if (value == 100) {
        return "超时"
      } else {
        return '剩余' + value + "天"
      }
    }
    const getPercentColor = (value: any) => {

      let p = Number(value)
      if (p >= 90 && p < 100) {
        return 'rgb(244, 240, 89)'
      } else if (p >= 100) {
        return 'red'
      } else {
        return 'rgb(16, 142, 233)'
      }
    }
    function filterIntPartFormat(value) {
      if (!value) {
        return '0'
      }
      let intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
      return intPartFormat
    }
    //查看人员详情
    function handleDetail(record: { id: string; }) {
      if (!record.id) {
        message.warning('没有人员id。请联系管理员')
        return
      }
      personModal.value.open(record)
      personModal.value.disabled = true
    }
    //编辑人员详情
    function handleEdit(record: { id: string; }) {
      if (!record.id) {
        message.warning('没有人员id。请联系管理员')
        return
      }
      personModal.value.open(record)
      personModal.value.disabled = false;
    }
    //职称变化操作
    function changTitle(title: string) {
      state.ipagination.current = 1
      state.indexBottomTab = title
      state.queryParam.isUpLev = false
      initData()
    }
    //可升级操作
    function changUpCount(title: string) {
      state.ipagination.current = 1
      state.indexBottomTab = title
      state.queryParam.isUpLev = true
      initData()
    }
    //职称变化
    function changeActiveKey() {
      state.ipagination.current = 1
      initData()
    }

    //初始化
    function initData() {
      loading.value = true;
      pageUpTitlePeopleInfo({ pageNo: state.ipagination.current, pageSize: state.ipagination.pageSize, titleName: state.indexBottomTab == '全部' ? '' : state.indexBottomTab, flag: state.queryParam.isUpLev ? '1' : '', name: state.queryParam.name, province: state.queryParam.province, city: state.queryParam.city }).then((res: { records: any; total: number; }) => {
        // console.log('======初始化数据',res)
        state.dataSource = res.records || res;
        state.ipagination.total = res.total;
      }).finally(() => {
        loading.value = false
      })
    }
    onMounted(() => {
      loadingTitle.value = true
      countAllTitle().then((res: any[]) => {
        state.countList = res
      }).finally(() => {
        loadingTitle.value = false
      })
      //初始化列表数据
      initData()
    })
    return {
      ...toRefs(state),
      tableChange,
      getFlowRateNumber,
      getPercentFormat,
      getPercentColor,
      loading,
      filterIntPartFormat,
      handleEdit,
      handleDetail,
      changTitle,
      changeActiveKey,
      loadingTitle,
      changUpCount,
      personModal,
      pcaaData
    };
  },

});
