
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    toRaw
} from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import moment, { Moment } from 'moment';
import {qualifications} from '@/utils/customExpression';
import { FileItem,FileInfo } from '@/views/HrUser/data';
import { message } from 'ant-design-vue';
import { getToken, getUserInfo } from "@/utils/auth";
import { UploadOutlined } from '@ant-design/icons-vue';
import {baseURL} from '@/config/index'
interface FormState {
    registerIDName:string;
    registeDate:Moment | undefined;
    practiceType:string;
    qualificationName:string;
    qualificationCode:string;
    firstMajor:string;
    twoMajor:string;
    qualificationIssueDate:Moment | undefined;
    qualificationKeeperIDName:string;
    registerCode:string;
    registeIssueDate:Moment | undefined;
    registelLoseDate:Moment | undefined;
    registeKeeperIDName:string;
    sealCode:string;
    sealLoseDate:Moment | undefined;
    sealKeeperIDName:string;
    continueTrainDate:Moment | undefined;
    continueTrainLength:string;
    continueTrainCompleteLength:string;
    qualifyFile:string;
    registerFile:string;
}
export default defineComponent({
    name:'base-info',
    description:'继续教育',
    components:{UploadOutlined},
    setup(props, context){
        const state = reactive({
            headers: {
                "X-Access-Token": getToken()
            },
            action:baseURL+'/sys/oss/api/uploadRequest',
            names:{},
            firstMajors:{},
            twoMajors:{}
        })
        // form表单数据
        const tm = new Date().getTime();
        const tempStr = moment(tm)
        const formRef = ref();
        const formState:UnwrapRef<FormState> = reactive({
            registerIDName:'',
            registeDate:tempStr,
            practiceType:'',
            qualificationName:'',
            qualificationCode:'',
            firstMajor:'',
            twoMajor:'',
            qualificationIssueDate:undefined,
            qualificationKeeperIDName:'',
            registerCode:'',
            registeIssueDate:undefined,
            registelLoseDate:undefined,
            registeKeeperIDName:'',
            sealCode:'',
            sealLoseDate:undefined,
            sealKeeperIDName:'',
            continueTrainDate:undefined,
            continueTrainLength:'',
            continueTrainCompleteLength:'',
            qualifyFile:'',
            registerFile:''
        });
        // form表单数据验证
        const rules = {
            registerIDName: [{ required: true, message: '请输入登记人！' }],
            registeDate: [{ required: true, message: '请选择登记日期！' }],
            practiceType: [{ required: true, message: '请选择执业资格类型！' }],
            qualificationName: [{ required: true, message: '请选择/输入执业资格名称！' }],
        };
        //执业资格类型选择
        const handPracticeTypeSelect = (value:string) => {
            state.names =  qualifications[value].children;
            formState.qualificationName = '';
            formState.firstMajor = '';
            formState.twoMajor = '';
        }
        //执业资格名称选择
        const handPracticeNameSelect = (value:string) => {
            state.firstMajors =  qualifications[formState.practiceType].children[value].children;
            formState.firstMajor = '';
            formState.twoMajor = '';
        }
        //第一专业选择
        const handMajorSelect = (value:string) => {
            state.twoMajors =  qualifications[formState.practiceType].children[formState.qualificationName].children[value].children;
            formState.twoMajor = '';
        }
        //附件上传
        const qualifyFileList = ref<FileItem[]>();
        const registerFileList = ref<FileItem[]>();
        
        const handleChange = (info: FileInfo, key: string) => {
            if (info.file.status === 'uploading') {
                state[key] = true;
                return;
            }
            if (info.file.status === 'done') {
                // console.log('=======上传反馈',info);
                // Get this url from response in real world.
                let resFileList = [...info.fileList]
                let tempFile = resFileList.map(el =>el?.response?.result?.url)
                console.log('===========info',info,key,tempFile);
                formState[key] = tempFile;
                state[key] = false;
            }
            if (info.file.status === 'error') {
                state[key] = false;
                message.error('上传失败');
            }
        };
        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                    console.log('values', formState,toRaw(formState).continueTrainDate ? toRaw(formState).continueTrainDate.format('YYYY-MM-DD'):'');
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        };
        const resetForm = () => {
            formRef.value.resetFields();
        };
        return {
            ...toRefs(state),
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            formRef,
            formState,
            rules,
            qualifications,
            handPracticeTypeSelect,
            handPracticeNameSelect,
            handMajorSelect,
            qualifyFileList,
            registerFileList,
            handleChange,
            submitForm,
            resetForm
        };
    }
})
