
import '@/assets/less/TableExpand.less'

import { 
  SmileOutlined, 
  DownOutlined, 
  PrinterOutlined, 
  SearchOutlined, 
  ReloadOutlined, 
  ArrowLeftOutlined,
  ExclamationCircleOutlined, 
  CheckCircleOutlined,
  PlusOutlined,
  UploadOutlined
  
} from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, toRef, reactive, createVNode,watch} from 'vue';
import { selectAllCompanyList,downloadInformation } from '@/api/manage'
import{
getSplitASingleList,
getGenerateBillList,
getinvoiceSplittingBillPdf,
setSplittingBillPdf,
getParsingPdf,
editPDF,
deletePDF,
getInvoiceList,
ParsingTheInvoice,
GenerateSplitSheetFromInvoice,
editInvoice,
deleteInvoice,
editfgd,
getSplitSheetDetails
}from '@/api/hrUser'
import { getToken } from '@/utils/auth'
import {useStore} from 'vuex'
import { message, message as Message, Modal } from 'ant-design-vue'
import { pastTime } from '@/utils/index'
import { useRouter } from 'vue-router'
import {baseURL} from '@/config/index';
import moment from 'moment'


export default defineComponent({
  name: 'SegmentationXQ',
  mixins: [],
  props: {
    fengedanDetails:{
      type: Object,
      default:{}
    },
    segmentationXQShow:{
       type: Boolean,
      default:false
    },
    typeNum:{
      type:Number,
      default:1
    }
  },
  setup(props, context) {
    const uploadurl=ref<any>(baseURL+'/sfsj-server/sys/oss/api/uploadRequest')
    const actionUrl=ref<any>()
    const uploadvisible=ref<boolean>(false)
    const acceptCompanyName=ref<any>()
    const bjshow=ref<boolean>(false)
    const formID=ref<any>()
    const imgListRadio=ref<number>()
    const healthInsuranceListRadio=ref<number>()
    const printAppoint = ref(null);
    const visible=toRef(props,'segmentationXQShow')
    const modelTitle=ref<string>('详情/编辑')
    const fileUrl=ref<string>('')
    const previewImage=ref<string>('')
    const companyList=ref<any>([])
    const companyData=ref<string>('')
    const socialSecurityData=<any>({}) //当前选中的社保数据
    const healthInsuranceData=<any>({})//当前选中的医保数据
    const SelectCompanyId=ref<string>('')//当前选中公司的Id
    const imgList=ref<any>()//社保图片数据
    const healthInsuranceimgList=ref<any>()//医保图片数据
    const accumulationimgList=ref<any>()//公积金图片数据
    const houseRentimgList=ref<any>()//发票图片数据
    const billDate=ref<any>()
    const bjshowId=ref<any>()
    const value1=ref<any>()
    const uploadfileList=ref<any>()
    const fgdbjshow=ref<any>()
    const splitResonStr=ref<any>()
    const state = reactive({
      queryParam: {
        invoiceCode: '',
        invoiceState: '',
        invoiceType: '',
      },
       headers: {
        'X-Access-Token': getToken()
      },
      fileList:{
       socialSecurity:[], //社保
       healthInsurance:[], //医保
       accumulationFund:[], //公积金
       houseRent:[] //房租水电
      },
      SplitSheetDetails:null,//分割单详情
      editData:{  //编辑PDF数据
       correctAmount:'',//修正总金额
       date:'', //时间
       serialNumber:'',//编号
      },
      fengeData:{ //分割数据
      splitAmount:0,//分割金额
      splitReason:''//分割原因
      }
    });
    const uploadTypeNum=ref<number>(1)
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    const previewVisible=ref<boolean>(false)
    const typeNum=toRef(props,'typeNum')
    const yearQuarter=ref<any>()
    const yearShowOne=ref<any>()
    const fengemoney=ref<any>()

 

    // 上传数据
    const handleChange = (info: any,flag:any) => {
      console.log(info,'infoinfoinfo')
      // imgList.value=info.fileList
      // if (info.file.status == 'uploading') {
      //   loading.value = true
      //   return
      // }
        // loading.value = true
      if(info.file.status == 'done'){
        loading.value = false

        message.success('上传成功')

      }
      if (info.file.status == 'uploading' ) {
        loading.value = false
        // visible.value=false
           /* 
            //    flag=1 社保
            //    flag=2 医保
            //    flag=3 公积金
            //    flag=4 房租水电
                 //    */
                //  info.file.forEach((e,i)=>{
                //    if(e.status=='error'){
                //      info.file.splice(i,1)
                //    }
                //  })
         if(flag==1){
        imgList.value=info.fileList
        }else if(flag==2){
          healthInsuranceimgList.value=info.fileList
        }else if(flag==3){
          accumulationimgList.value=info.fileList
        }else if(flag==4){
         houseRentimgList.value=info.fileList
        }
      console.log(info,'kkkkkk')
  
      }
      if(info.file.status=="removed"){
         if(flag==1){
        imgList.value=info.fileList
        }else if(flag==2){
          healthInsuranceimgList.value=info.fileList
        }else if(flag==3){
          accumulationimgList.value=info.fileList
        }else if(flag==4){
         houseRentimgList.value=info.fileList
        }
        message.success('删除成功!')
      }
      if (info.file.status === 'error') {
        loading.value = false
        message.error('上传失败')
      }
    }


   //取消弹框
    function cancelModal(){
      context.emit('clore')
      bjshow.value=false
     
    }

 



    //编辑时间
    function EditChangeDate(i,e){
    console.log(e,123);
    state.editData.date=e
    }

 


   //删除已上传的文件
  //  function handleFileRemove(e,flag){
  //    console.log(e,flag,'eeeeeeeeeeeeee');
  //    if(flag==1){
  //   imgList.value.forEach((element,index)=>{
  //      if(e.response.result.id==element.response.result.id ||e.status=='error'){
  //     imgList.value.splice(index,1)
  //      }
  //    })
  //    }else if(flag==2){
  //   healthInsuranceimgList.value.forEach((element,index)=>{
  //      if(e.response.result.id==element.response.result.id ||e.status=='error'){
  //     healthInsuranceimgList.value.splice(index,1)
  //      }
  //    })
  //    }else if(flag==3){
  //   accumulationimgList.value.forEach((element,index)=>{
  //      if(e.response.result.id==element.response.result.id ||e.status=='error' ){
  //     accumulationimgList.value.splice(index,1)
  //      }
  //    })
  //    }else if(flag==4){
  //   houseRentimgList.value.forEach((element,index)=>{
  //      if(e.response.result.id==element.response.result.id ||e.status=='error' ){
  //     houseRentimgList.value.splice(index,1)
  //      }
  //    })
  //    }

     
  //    console.log(imgList.value,'imgList.value22222222222');

  //  }
  
   //提交PDF
   function submitOkBut(flag){
    //  console.log(imgList.value,'imgList.value');

     let imgListurls =<any>[]
     let healthInsuranceimgListurls =<any>[]
     let accumulationimgListurls=<any>[]
     let houseRentimgListimgListurls=<any>[]
     let urls=[]
     if(flag==1){
     imgList.value.forEach(e=>{
     imgListurls.push(e.response.result.url)
     urls=imgListurls
      })
     }else if(flag==2){
       healthInsuranceimgList.value.forEach(e=>{
     healthInsuranceimgListurls.push(e.response.result.url)
     urls=healthInsuranceimgListurls
     })}else if(flag==3){
      accumulationimgList.value.forEach(e=>{
      accumulationimgListurls.push(e.response.result.url)
      urls=accumulationimgListurls
     })}else if(flag==4){
      houseRentimgList.value.forEach(e=>{
      houseRentimgListimgListurls.push(e.response.result.url)
      urls=houseRentimgListimgListurls
     })}

     
   
     let obj={
         type:flag,
         urls
     }
     loading.value=true
     console.log();
     if(flag==4){
      //  let objurl={
      //    urls
      //  }
       ParsingTheInvoice(urls).then(res=>{
        if(res){
        loading.value=false
        message.success('提交成功!')
        console.log(res,'提交发票');
        res.forEach(e=>{
        state.fileList.houseRent.push(e)

        })
        let timer=null
        invoiceOkBut(1)
        if(timer){
          clearTimeout(timer)
        }
         timer=setTimeout(()=>{
          SplitSheetDetails()
        },1000)
        


        
        houseRentimgList.value=null
        
        }
      
       })
     }else{
       getParsingPdf(obj).then(res=>{
      console.log(res,'s');
      if(res){
        loading.value=false
        message.success('提交成功!')
        // billDate.value=''
        res.forEach(e=>{
          if(e.type==1){
            state.fileList.socialSecurity.push(e)
          }else if (e.type==2){
            state.fileList.healthInsurance.push(e)
          }else if(e.type==3){
            state.fileList.accumulationFund.push(e)
          }
        })
         let timer=null
        GeneratePartitionSheetBut()
        if(timer){
          clearTimeout(timer)
        }
         timer=setTimeout(()=>{
          SplitSheetDetails()
        },500)
        
        if(flag==1){
        imgList.value=null
        }else if(flag==2){
        healthInsuranceimgList.value=null
        }else if(flag==3){
          accumulationimgList.value=null
        }
        
      }

     })
     }
     
    
   
   }
   //编辑按钮
   function bjshowBut(item){
    console.log(item,'item');
    bjshow.value=bjshow.value?false:true
    bjshowId.value=item.id
    state.editData.correctAmount=item.correctAmount/100 as any || item.totalAmount/100 as any 
    state.editData.date=item.date
    state.editData.serialNumber=item.serialNumber
   }
   //分割单编辑按钮
   function fgdbjshowBut(){
    fgdbjshow.value=!fgdbjshow.value
    
   }
//分割单确定编辑按钮
   function editFgdPDFs(){
     console.log(1);
     editfgds()
     
   }
   //编辑分割单方法
   function editfgds(){
     state.SplitSheetDetails.splitAmount=fengemoney.value*100
     if(typeNum.value==3){
       console.log(123);
       
     invoiceOkBut(1)

     }else{
     GeneratePartitionSheetBut()

     }
     fgdbjshow.value=!fgdbjshow.value

   }
   //生成分割单按钮
  function GeneratePartitionSheetBut(){
  
    if(state.SplitSheetDetails){
    //  splitResonStr.value= splitResonStr.value.split("，")[0]+"，"
    state.SplitSheetDetails.splitReson=splitResonStr.value
    }
    if(typeNum.value==1){
     if(state.fileList.healthInsurance && state.fileList.socialSecurity && state.SplitSheetDetails.acceptCompanyId){
     let obj ={
       acceptCompanyId:state.SplitSheetDetails.acceptCompanyId||'',
      belongMonth:state.SplitSheetDetails.month ,
       belongYear: state.SplitSheetDetails.year ,
       billPdfs:[
         ...state.fileList.healthInsurance,
         ...state.fileList.socialSecurity
       ],
       billType:1,
       bill:state.SplitSheetDetails?{...state.SplitSheetDetails}:{}
     }
    setSplittingBillPdfs(obj)
   }else{
     message.error('请选择数据!')
   }
   }
   else if(typeNum.value==2){
    if(state.fileList.accumulationFund && state.SplitSheetDetails.acceptCompanyId){
      console.log(state.fileList.accumulationFund,'state.fileList.accumulationFund')
     let obj ={
       acceptCompanyId:state.SplitSheetDetails.acceptCompanyId||'',
       belongMonth:state.SplitSheetDetails.month ,
       belongYear: state.SplitSheetDetails.year ,
       billPdfs:[
         ...state.fileList.accumulationFund,
       ],
       billType:2,
       bill:state.SplitSheetDetails?{...state.SplitSheetDetails}:{}
     }
    setSplittingBillPdfs(obj)
   }else{
     message.error('请选择数据!')
   }
   }
   
  }


    //生成分割单
   function setSplittingBillPdfs(paramse){
  //     if(state.SplitSheetDetails){
  //   message.error('请勿重复生成!')
  //   return
  // } 
  console.log(paramse,'生成分割单参数');
  
    //  state.SplitSheetDetails=null
     setSplittingBillPdf(paramse).then(res=>{
     console.log(res);
     if(res){
    // splitResonStr.value=res.splitReson+(res.splitAmount/100)+'元'
    splitResonStr.value=res.splitReson
     state.SplitSheetDetails=res
     
     message.success('编辑成功!')
     }else{
     message.error('编辑失败!')
     }
     console.log(state.SplitSheetDetails,'state.SplitSheetDetails');
     
     
     })
   }
  
   //编辑PDF
   function editPDFs(item){
     console.log(item,'item');
     console.log(typeNum.value,'typeNum');
      item.type=Number(item.type)

     let billPDf={
       ...item
     }
     billPDf.date=state.editData.date+' 00:00:00'
     billPDf.correctAmount=(state.editData.correctAmount as any)*100 
     editPDF(billPDf).then(res=>{
       if(res=='修正成功'){
       message.success(res)
       if(typeNum.value==1){
         console.log(billPDf,'444');
         
         if(billPDf.type==1){
          state.fileList.socialSecurity.forEach((e,i)=>{
           if(e.id==billPDf.id){
              state.fileList.socialSecurity[i]=billPDf
           }
          
         })  
         }else{

         state.fileList.healthInsurance.forEach((e,i)=>{
           if( e.id==billPDf.id){
              state.fileList.healthInsurance[i]=billPDf
           }
          
         }) 
         }
       }else if(typeNum.value==2){
           state.fileList.accumulationFund.forEach((e,i)=>{
           if( e.id==billPDf.id){
              state.fileList.accumulationFund[i]=billPDf
           }
          
         })  
       }
          
        let timer=null
       
        GeneratePartitionSheetBut()

        if(timer){
          clearTimeout(timer)
        }
         timer=setTimeout(()=>{
          SplitSheetDetails()
        },500)
        
        

       }else{
       message.error(res)
       }
       bjshow.value=false


     })
   }
   //编辑发票信息
   function editInvoices(item){
     let billPDf={
       ...item
     }
     billPDf.date=state.editData.date+' 00:00:00'
     billPDf.correctAmount=(state.editData.correctAmount as any)*100 
    editInvoice(billPDf).then(res=>{
     console.log(res,'000');
     bjshow.value=false
           state.fileList.houseRent.forEach((e,i)=>{
           if( e.id==billPDf.id){
              state.fileList.houseRent[i]=billPDf
           }
         })  
        let timer=null

        invoiceOkBut(1)

        if(timer){
          clearTimeout(timer)
        }
         timer=setTimeout(()=>{
          SplitSheetDetails()
        },500)

        
    //  getInvoiceLists()
  
    })
   }
    //删除发票信息
   function deleteInvoices(item){
     deleteInvoice({id:item.id}).then(res=>{

        if(res){
       message.success(res)
         state.fileList.houseRent.forEach((e,i)=>{
              if(item.id==e.id){
                state.fileList.houseRent.splice(i,1)
              } 
            })
      
     
        let timer=null

        invoiceOkBut(1)

        if(timer){
          clearTimeout(timer)
        }
         timer=setTimeout(()=>{
          SplitSheetDetails()
        },500)
        


        
      //  getInvoiceLists()
      }

     })
   }
   //删除PDF
   function delshowBut(item){
    console.log(item,'item');
    item.type=Number(item.type)
    deletePDF({id:item.id}).then(res=>{
      console.log(res,'123');
      if(res){
       message.success(res)
        switch (item.type) {
          case 1:
            state.fileList.socialSecurity.forEach((e,i)=>{
              if(item.id==e.id){
                state.fileList.socialSecurity.splice(i,1)
              } 
            })
            break;
         case 2:
              state.fileList.healthInsurance.forEach((e,i)=>{
              if(item.id==e.id){
                state.fileList.healthInsurance.splice(i,1)
              } 
            })
            break;
         case 3:
               state.fileList.accumulationFund.forEach((e,i)=>{
              if(item.id==e.id){
                state.fileList.accumulationFund.splice(i,1)
              } 
            })
            break;
          default:
            break;
        }
        if(typeNum.value=1){
          
        let timer=null

        GeneratePartitionSheetBut()

        if(timer){
          clearTimeout(timer)
        }
         timer=setTimeout(()=>{
          SplitSheetDetails()
        },500)
        
        } 
     
        
      

      }
      
    })
   }
   //获取发票数据列表
  //  function getInvoiceLists(){
  //     let obj={
  //       pageNo:1,
  //       pageSize:100
  //     }
  //   getInvoiceList(obj).then(res=>{
  //   console.log(res,'发票数据');
  //   message.success('请求成功!')
  //   state.fileList.houseRent=res.records
  //    })
  //    }
    function invoiceOkBut(flag){
     console.log(flag,'flag');
    if(state.SplitSheetDetails){
    //  splitResonStr.value= splitResonStr.value.split("，")[0]+"，"
     state.SplitSheetDetails.splitReson=splitResonStr.value
    }
     if(flag==2){
     if( fengemoney.value==0||!splitResonStr.value){
       message.error('请填写分割原因和金额,并选择公司和发票文件!')
         return
       }
     }
      
       if(fengemoney.value>0&&splitResonStr.value&&state.SplitSheetDetails.acceptCompanyId){
       let obj={
        acceptCompanyId: state.SplitSheetDetails.acceptCompanyId,
        bill:state.SplitSheetDetails?{...state.SplitSheetDetails}:{},
        billReceipts: [
          
          ...state.fileList.houseRent
          
        ],
        splitAmount: fengemoney.value*100,
        splitReason: splitResonStr.value
          }
      GenerateSplitSheetFromInvoice(obj).then(res=>{
      console.log(res,'发票详情--');
      if(res){
        message.success('请求成功!')
        companyData.value=''
        // splitResonStr.value=res.splitReson+(res.splitAmount/100)+'元'
        splitResonStr.value=res.splitReson
   
        state.SplitSheetDetails=res as any
        fengemoney.value=0
        // state.fengeData.splitReason=res.splitReason
        // state.fengeData.splitReason=''
        // cancelModal()
        if(flag==2){
        //  butJy.value=true
        }
        
      }else{
        message.error('请求失败!')

      }
      })
    }
   }
 function SplitSheetDetails(){
    getSplitSheetDetails({id:props.fengedanDetails.id}).then(res=>{
      //  state.SplitSheetDetails=null
      state.fileList={
      socialSecurity:[], //社保
       healthInsurance:[], //医保
       accumulationFund:[], //公积金
       houseRent:[] //房租水电
     }
    console.log(res,'resssss')
     res.billPdfs.forEach((e,i) => {
    // e.totalAmount=(e.totalAmount/100)
     if(e.type==1){
       state.fileList.socialSecurity.push(e)
     }else if(e.type==2){
       state.fileList.healthInsurance.push(e)
     }else if(e.type==3){
       state.fileList.accumulationFund.push(e)
     }
       
     });
     res.billReceipts.forEach((e,i)=>{
       state.fileList.houseRent.push(e)
     })
    // state.fileList.houseRent= res.billReceipts
       state.SplitSheetDetails=res
      //  splitResonStr.value=res.splitReson+(res.splitAmount/100)+'元'
       splitResonStr.value=res.splitReson
        console.log(state.fileList,'state.fileList');

     }).catch(err=>{
       message.error('服务器繁忙!')
     })
 }
   



watch(()=>state.SplitSheetDetails,()=>{
       if(state.SplitSheetDetails){
       fengemoney.value=state.SplitSheetDetails.splitAmount
       if(fengemoney.value){
         fengemoney.value=fengemoney.value/100
       }}})
       

    //  watch(()=>state.fileList,()=>{
    //    let s=state.fileList.socialSecurity
    //    state.fileList.socialSecurity=s
    //    state.fileList.healthInsurance=state.fileList.healthInsurance
    //  })
    onMounted(() => {
      
  console.log(props.fengedanDetails,'555');
  billDate.value=props.fengedanDetails.belongYear+'-'+props.fengedanDetails.belongMonth
      
  SplitSheetDetails()
       
    })
    return {
      GeneratePartitionSheetBut,
   splitResonStr,
setSplittingBillPdfs,
      SplitSheetDetails,
   fengemoney,
      cancelModal,
      editfgds,
      editFgdPDFs,
      fgdbjshow,
      fgdbjshowBut,
      uploadurl,
      actionUrl,
      uploadTypeNum,
      uploadfileList,
      uploadvisible,
      yearQuarter,
      yearShowOne,
      acceptCompanyName,
      value1,
      deleteInvoices,
      editInvoices,
      invoiceOkBut,
      houseRentimgList,
      // getInvoiceLists,
      accumulationimgList,
      delshowBut,
      EditChangeDate,
      bjshowId,
      bjshowBut,
      editPDFs,
      bjshow,
      healthInsuranceimgList,
      submitOkBut,
      billDate,
      imgList,
      SelectCompanyId,
      healthInsuranceData,
      socialSecurityData,
      healthInsuranceListRadio,
      formID,
      // handleimgListRadio,
      imgListRadio,
      companyData,
      companyList,
      dataSource,
      loading,
      state,
      printAppoint,
      handleChange,
      selectAllCompanyList,
      handleResizeColumn: (w, col) => {
        col.width = w;
      },
      visible,
      
      modelTitle,
      fileUrl,
      previewVisible,
      previewImage,
      typeNum
      
    };
  },
  components: {
    // CompanyInvoiceModal,
    // PrintAppoint,
    PrinterOutlined,
    SearchOutlined,
    ArrowLeftOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    DownOutlined,
    PlusOutlined,
    UploadOutlined
    
  },
});
