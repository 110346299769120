
import {
  computed,
  defineComponent,
  reactive,
  unref,
  watchEffect,
  toRefs,
  ref,
  onMounted,
} from "vue";
import { useCountdown } from "@/views/HrUser/useCountdown";
import { RuleObject } from "ant-design-vue/es/form/interface";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons-vue";
import {
  UserRegister,
  checkOnlyUserPhone,
  GET_CODE,
  ADD_USER,
  getProcessByActi,
  getTaskList,
  getFormKey,
} from "@/api/hrUser";
import { isCanSubmit } from "@/api/companySettled";
import { message } from "ant-design-vue";
import type { UnwrapRef } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getUserPhone } from "@/utils/auth";

interface FormState {
  phone: string;
  code: string;
}
export default defineComponent({
  components: {
    PhoneOutlined,
    MailOutlined,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      loadingCode: false,
      count: 60,
      loading: false,
      checked: true,
      visible: false,
      isType: "",
      agreementTime: 6,
      path: "hrUser",
      query: {
        activitiId: "",
        instanceId: "",
      },
    });
    const { currentCount, isStart, start, reset } = useCountdown(state.count);
    const {
      currentCount: currentCountAgreement,
      isStart: isStartAgreement,
      start: startAgreement,
      reset: resetAgreement,
    } = useCountdown(state.agreementTime);
    const validatePhone = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return Promise.reject("请输入手机号码");
      } else {
        if (
          new RegExp(
            /^1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4(?:(?:10|4[01])\d{3}|[68]\d{4}|[579]\d{2}))\d{6}$/
          ).test(value)
        ) {
          return new Promise<void>((resolve, reject) => {
            checkOnlyUserPhone({
              phone: value,
            })
              .then((res) => {
                console.log("res:", res);
                state.isType = "register";
                resolve();
              })
              .catch((err) => {
                if (err.success) {
                  state.isType = "register";
                  resolve();
                } else {
                  state.isType = "login";
                  resolve();
                }
              });
          });
        } else {
          return Promise.reject("请输入正确格式的手机号码!");
        }
      }
    };
    const rules = {
      phone: [{ required: true, validator: validatePhone, trigger: "change" }],
      code: [{ required: true, message: "请输入验证码", trigger: "change" }],
    };
    const formRef = ref();
    const getButtonText = computed(() => {
      return !unref(isStart) ? "获取验证码" : unref(currentCount);
    });
    const getButtonAgreementText = computed(() => {
      return !unref(isStartAgreement) ? "已阅读" : unref(currentCountAgreement);
    });
    const formState: UnwrapRef<FormState> = reactive({
      phone: "",
      code: "",
    });
    watchEffect(() => {
      formState.code === undefined && reset();
    });
    function handleStart() {
      if (!state.path) {
        message.warning("没有路径参数，请联系管理员！");
        return;
      }
      formRef.value
        .validate(["phone"])
        .then(async () => {
          state.loadingCode = true;
          const hide = message.loading("验证码发送中..", 0);
          const smsmode = state.isType === "login" ? "0" : "1";
          GET_CODE({ mobile: formState.phone, smsmode })
            .then((res) => {
              setTimeout(hide, 800);
              console.log(res,'res-----');
              
                message.warning(res.message);

              start();
            })
            .catch((err) => {
              if (err.success) {
                setTimeout(hide, 800);
              console.log(err,'err-----');

                message.warning(err.message);

                start();
              } else {
                setTimeout(hide, 0);
              console.log(err,'err-----');
                
                message.warning(err.message);
              }
            })
            .finally((res) => {
              console.log(res,'res-----');
              
                message.warning(res.message);


              state.loadingCode = false;
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function onSubmit() {
      if (!state.path) {
        message.warning("没有路径参数，请联系管理员！");
        return;
      }
      if (!state.checked) {
        message.warning("请阅读入职系统用户须知！");
        return;
      }
      formRef.value
        .validate()
        .then(async () => {
          state.loading = true;
          if (state.isType === "login") {
            login();
          } else if (state.isType === "register") {
            register();
          }
        })
        .catch((error) => {
          console.log("error", error);
          state.loading = true;
        });
    }
    function register() {
      UserRegister({
        username: formState.phone,
        password: "123456",
        phone: formState.phone,
        smscode: formState.code,
      })
        .then((res) => {
          login();
        })
        .catch((err) => {
          state.loading = false;
          message.error(err.message);
        });
    }
    function login() {
      store
        .dispatch("login", {
          mobile: formState.phone,
          captcha: formState.code,
          remember_me: true,
        })
        .then((res) => {
          if (state.isType === "login") {
            activiti();
          } else if (state.isType === "register") {
            activiti();
          }
        })
        .catch((err) => {
          state.loading = false;
          message.error(err.message);
        });
    }
    function activiti() {
      setTimeout(() => {
        let form = new FormData()
        form.append('phoneNum',formState.phone )
        isCanSubmit(form)
          .then((res) => {
            if (res) {
              toUrl()
            }
          })
          .catch((err) => {
            state.loading = false;
            // message.error(err.message);
          });
      }, 1000);
    }
    function toUrl() {
      router.push({
        path: "/companySettled/" + state.path,
        query: {},
      });
    }
    function agreement() {
      state.visible = true;
      startAgreement();
    }

    onMounted(() => {
      const UserPhone = getUserPhone();
      formState.phone = UserPhone || "";
      validatePhone("" as RuleObject, UserPhone);
      const data: any = router.currentRoute.value.query;
      console.log("router.currentRoute.value.query:", data);
      state.query = data;
      try {
        state.path = data.path || "companyInfo";
      } catch (error) {
        console.log(error);
      }
    });
    return {
      formState,
      ...toRefs(state),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      getButtonText,
      handleStart,
      isStart,
      onSubmit,
      agreement,
      rules,
      formRef,
      getButtonAgreementText,
      isStartAgreement,
      resetAgreement,
      startAgreement,
      currentCountAgreement,
    };
  },
});
