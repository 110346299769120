
import '@/assets/less/TableExpand.less'
import { 
  SmileOutlined, 
  DownOutlined, 
  PrinterOutlined, 
  SearchOutlined, 
  ReloadOutlined, 
  ArrowLeftOutlined,
  ExclamationCircleOutlined, 
  CheckCircleOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, toRef, reactive, createVNode} from 'vue';
import { segmentationList,downloadInformation } from '@/api/manage'
import { getToken } from '@/utils/auth'
import {useStore} from 'vuex'
import { message, message as Message, Modal } from 'ant-design-vue'
import { pastTime } from '@/utils/index'
import { useRouter } from 'vue-router'
import { FileInfo } from '../HrUser/data';
import { reject } from 'lodash';
const columns = [
  {
    title: '#',
    dataIndex: '',
    key:'rowIndex',
    width:60,
    align:"center",
    customRender:function ({text,record,index}) {
      return parseInt(index)+1;
    }
  },
  {
    title: '名字',
    dataIndex: 'fileName',
    key: 'fileName',
    align:"center",
    resizable: true,
  },
  {
    title:'类型',
    align:"center",
    dataIndex: 'category',
    resizable: true,
     customRender:function ({text,record,index}) {
       if(text=='1'){
         return '社保'
       }else{
         return '公积金'
       }
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    align:"center",
    fixed:"right",
    width:160,
    slots: { customRender: 'action' }
  }
];

export default defineComponent({
  name: 'Segmentation',
  mixins: [],
  props: {
  },
  setup(props, context) {
    const router = useRouter();
    const pageNo = ref<number>(1);
    const urlTime = ref<number>(0);
    const printAppoint = ref(null);
    const visible=ref<boolean>(true)
    const modelTitle=ref<string>('请选择上传数据')
    const fileUrl=ref<string>('')
    // const previewImage=ref<string>('')
    const state = reactive({
      queryParam: {
        invoiceCode: '',
        invoiceState: '',
        invoiceType: '',
      },
       headers: {
        'X-Access-Token': getToken()
      },
      uoloadData:{
        category:'',
        companyId:'',
        date:'',
        id:''
      },
      downloadData:{
        category:'',
        companyId:'',
        date:'',
      }
    });
    
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    const previewVisible=ref<boolean>(false)
    const pagination = computed(() => ({
      current: pageNo.value,
      pageSize: pageSize.value,
      pageSizeOptions: ['10', '20', '30'],
      showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: total.value
    }));
    const to = (path) => {
      if (path) {
        router.push({
          path: path
        })
      } else {
        router.go(-1)
      }
    };

    // 上传数据
    // const handleChange = (info: any) => {
    //   if (info.file.status === 'uploading') {
    //     loading.value = true
    //     return
    //   }
    //   if (info.file.status === 'done') {
    //     loading.value = false
    //     if({...info.file.response}.code === 200){
    //     message.success('上传成功')
    //     visible.value=false
    //     state.uoloadData = {
    //       category:'',
    //       companyId:'',
    //       date:'',
    //       id:''
    //     }
    //      getContractPrintOrderList()
    //     }else{
    //     message.error('上传失败')
    //     }
    //   }
    //   if (info.file.status === 'error') {
    //     loading.value = false
    //     message.error('上传失败')
    //   }
    // }
    // 翻页
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
      getContractPrintOrderList()
    }
    // 重置搜索
    const searchReset = () => {
      state.queryParam = {
        invoiceCode: '',
        invoiceState: '',
        invoiceType: '',
      }
      getContractPrintOrderList(1)
    }
   
    // 搜索
    const searchQuery = () => {
      getContractPrintOrderList(1)
    }
    const showModal=(scope,num)=> {
      console.log(scope,'scope');
      window.open(scope.fileUrl)
      // if(num=='1'){
      //   modelTitle.value='请选择上传的数据'
      // }else{
      //   modelTitle.value='请选择下载的数据'
      // }
      // state.uoloadData.companyId=scope.nameid
      // state.uoloadData.id=scope.id
      // state.downloadData.companyId=scope.nameid
      // visible.value = true;
    }
    // function handleCancel() {
    //     this.previewVisible = false;
    //   }
    // function  handlePreview (file) {
    //   console.log(file,'filefilefile');
      
    //     this.previewImage = file.fileUrl 
    //     this.previewVisible = true
    //   }
    //下载社保公积金数据
    // function  handleDownloadInformation(){
    //  downloadInformation(state.downloadData).then(res=>{
    //   fileUrl.value=res.fileUrl
    //   message.success('下载成功!')
    //   window.open(fileUrl.value)
    //   loading.value = false
    //   visible.value=false
    // }).catch(err=>{
    //   message.error('下载失败!')
    //   loading.value = false
    // })
    // }
    
    //选择上传类型数据
    function selectList(i){
      state.uoloadData.category=i  
      state.downloadData.category=i
    }
    //选择时间
    function onChangeDate(i,e){
      state.uoloadData.date=e
      state.downloadData.date=e
    }
    //取消上传弹框
    function cancelModal(){
      visible.value=false
        state.uoloadData = {
          category:'',
          companyId:'',
          date:'',
          id:''
        }
    }
   
    // 列表获取
    const getContractPrintOrderList = async (page?: number) => {
      if (page) {
        pageNo.value = page
      }
      loading.value = true
      let paramse = {
        pageNo: pageNo.value,
        pageSize:pageSize.value,
        UserId:router.currentRoute.value.query.UserId,
        category:state.queryParam.invoiceType||''
      }
      segmentationList(paramse).then(res=> {
        total.value = res.total
        console.log(res,'resresres');
        
        dataSource.value = res.records
        loading.value = false
      }).catch(err=> {
        Message.error('请求失败')
        loading.value = false
      })
    }
    
    
    onMounted(() => {
        getContractPrintOrderList()

    })
    return {
      dataSource,
      columns,
      loading,
      pagination,
      getContractPrintOrderList,
      handleTableChange,
      state,
      searchQuery,
      searchReset,
      printAppoint,
      // handleChange,
      to,
      segmentationList,
      handleResizeColumn: (w, col) => {
        col.width = w;
      },
      visible,
      showModal,
      selectList,
      onChangeDate,
      cancelModal,
      // handleDownloadInformation,
      modelTitle,
      fileUrl,
      // handleCancel,
      previewVisible,
      // previewImage,
      // handlePreview
    };
  },
  components: {
    // CompanyInvoiceModal,
    // PrintAppoint,
    PrinterOutlined,
    SearchOutlined,
    ArrowLeftOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    DownOutlined,
  },
});
