
// 忽略文件中所有警告
// /* eslint-disable */
import {
  defineComponent,
  onMounted,
  ref,
  getCurrentInstance,
  toRaw,
} from 'vue'
import { message as Message } from 'ant-design-vue'
import { useStore } from 'vuex'
import {
  addPrintItem,
  queryPrintPrice,
  parseAddress,
  printCenterAdsList,
  getNearPrintCenterLink
} from '@/api/manage'
import { useRouter } from 'vue-router'
import { AttachmentItem } from '@/interface/print'
import { ReloadOutlined } from '@ant-design/icons-vue';
// import debounce from "lodash/debounce"; // 防抖函数
// 忽略下一行警告

// eslint-disable-next-line
import PrintModel from '@/components/PrintModel.vue' // @ is an alias to /src
import ExpressOrder from '@/components/ExpressOrder.vue' // @ is an alias to /src
import PrintOrderModal from '@/views/component/PrintOrderModal.vue'
import { returnQuery } from '@/utils/index'

export default defineComponent({
  name: 'About',
  components: {
    PrintModel,
    ReloadOutlined,
    ExpressOrder,
    PrintOrderModal
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance() // 使用proxy代替ctx，因为ctx只在开发环境有效
    // getCurrentInstance别人的文章说只适用于调试，不能用于线上
    // const current: any = getCurrentInstance()
    // console.log('current=', current);
    // const options = ref<any>(inject('$pcaa'))
    // const valueArea = ref([])
    const store = useStore()
    const repositories = ref<any[]>([])
    repositories.value = store.getters.repositoriesArr
    repositories.value.forEach(elem => {
      elem.attachment = elem.attachment.filter(item => item.checked && item.pages).map(item => {
        return {
          amountPayable: 0,
          dmColor: '2',//elem.contractProperties//item.contractProperties=="1"?'1':'2'
          dmCopies: !!elem.isPrint ? 0 : item.printNumbers - 0,//elem.printNumbers
          dmDuplex: item.isDoublePrint,//elem.isDoublePrint--|| "2"
          dmOrientation: "1",
          dmPaperSize: "9",
          jobFile: item.contractAttachmentUrl,
          jobFileName: item.contractAttachmentFileName,
          jpPageRange: "",
          jpTotalPage: item.pages,
          jpAutoScale: '4',
          jpAutoAlign: 'z5',
          isPreview: '0',
          dmPrintQuality: '-4',
          contractId: elem.contractId,
          createTime: item.createTime,
          isDoublePrint: item.isDoublePrint,//elem.isDoublePrint
          contractProperties: item.contractProperties,//elem.contractProperties
          isPrint: elem.isPrint
        }
      })
    })
    repositories.value = repositories.value.filter(item => item.attachment.length)
    const address = ref([])
    const expressOrder = ref(null)
    //备注
    const note = ref<string>('')
    //文印中心-地址
    const printCenterList = ref([]);
    const expressSendAddressId = ref<string>('');
    const printCenter = ref<string>('');
    const monthlyCust = ref<string>('');//月结卡号
    const printerId = ref<string>('');//打印盒子id
    const faceSheetBoxId = ref<string>('');//面板框id
    const contractId = ref<any>('')
    const userName = ref<any>('')
    const mark = ref<any>('1')
    const printOrderModal = ref(null)

    const totalPayable = ref<number>(0)
    const loading = ref<boolean>(false)
    const expressChecked = ref<boolean>(true)
    const loadingMore = ref<boolean>(false)
    const changeChecked = (checked: boolean) => {
      expressChecked.value = checked
    }
    const isDoublePrint = ref<any>()
    const printNumbers = ref<any>()
    const refArr = ref([])
    const contractName = ref()
    const printPrice = ref()
    const handleClick = (e: Object) => {
      // console.log('home组件函数触发====', e)
    }
    const pushRef = (e) => {
      console.log('==========pushRef', e)
      let len = repositories.value.reduce((sum, item) => {
        return sum + item.attachment.length
      }, 0)
      if (refArr.value.length > 0 && refArr.value.length === len) return;
      refArr.value.push(e)
    }

    const returnPage = () => {
      router.go(-1)
    }
    const getParseAddress = async (contractId: any) => {
      let promiseArr = []
      let array = contractId.split(',')
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        promiseArr.push(parseAddress({ contractId: element }))
      }
      interface Address {
        value?: any;
        status: any;
      }
      Promise.allSettled(promiseArr).then((result) => {
        console.log('result-----------', result)
        address.value = result.map((item: Address) => item.value).filter(item => item)
        console.log(toRaw(address.value), '123');

      }).catch((error) => {
        console.log('error-----------', error)
      })
      // const res = await parseAddress({ contractId })
      // if (res) {
      //   address.value = res
      // }
    }
    // 修改总价
    const setTotalPayable = (data: any = {}): void => {
      for (let index = 0; index < repositories.value.length; index++) {
        const element = repositories.value[index];

        if (element.contractId === data.contractId) {
          element.attachment = element.attachment.map(item => {
            if (item.jobFile === data.jobFile) {
              item = Object.assign({}, data)
            }
            return item
          })
        }
      }
      // if (data.jobFile) {
      //   for (let index = 0; index < repositories.value.length; index++) {
      //     const element = repositories.value[index];

      //     if (element.jobFile === data.jobFile) {
      //       repositories.value[index] = Object.assign({}, data)
      //       break;
      //     }
      //   }
      // }
      // totalPayable.value = repositories.value.reduce( (sum, item,) => {
      //   return sum + item.amountPayable
      // }, 0)
    }
    async function getPrintItem(ids: string) {
      loading.value = true
      const data = await addPrintItem({ ids })
      loading.value = false
      // if (data.length && data[0]) {
      //   repositories.value = data
      // }
      setTotalPayable()
    }

    //文印中心--地址list
    const getPrintCenterList = async () => {
      const data = await printCenterAdsList({ pageNo: 1, pageSize: 10 });
      printCenterList.value = data.records
      // console.log('==========printCenterList',printCenterList)
    }
    const getNearPrintCenter = async () => {
      const data = await getNearPrintCenterLink()
      expressSendAddressId.value = data?.id
      printCenter.value = data?.printCenter;
      monthlyCust.value = data?.monthlyCust;
      printerId.value = data?.printerId;
      faceSheetBoxId.value = data?.faceSheetBoxId;
      console.log(expressSendAddressId)
    }
    // function adsChange(value) {
    //   console.log('=======文印中心选择',value);
    // }
    // 文印中心--地址选择
    const adsChange = (value: String) => {
      // expressSendAddressId,
      // printCenter,
      // monthlyCust:monthlyCust.value,//月结卡号
      // printerId:printerId.value //打印盒子id
      // console.log('文印中心选择====', value)
      // console.log('文印中心选择====expressSendAddressId', expressSendAddressId);
      let temp = printCenterList.value.filter(el => el.id === value)[0];
      printCenter.value = temp.printCenter;
      monthlyCust.value = temp.monthlyCust;
      printerId.value = temp.printerId;
      faceSheetBoxId.value = temp.faceSheetBoxId;
      // console.log('文印中心选择====printCenter', printCenter)
    }

    function onSubmitOrder() {
      if (!expressSendAddressId.value) {
        Message.warning('请选择文印中心,即打印发货地址!')
        return
      }
      let promiseArr = []
      // console.log('==========refArr---控制台',refArr.value)
      refArr.value.forEach((item, index) => {
        // console.log('==========refArr---控制台---item',item)
        promiseArr.push(
          new Promise((resolve, reject) => {
            item.onSubmit((video) => {
              if (video) {
                // console.log('=======video',video)
                resolve(video)
              } else {
                reject()
              }
            })
          })
        )
      })
      // console.log('==========promiseArr',promiseArr)
      Promise.all(promiseArr).then((result) => {
        if (expressChecked.value) {
          expressOrder.value.onSubmit((res) => {
            if (res) {
              // const { businessType, receiveAddress, receiveName, receivePhone, valueArea } = expressOrder.value.formState
              let obj = {
                ...expressOrder.value.formState
              }
              let otherForm = {
                note: note.value,
                expressSendAddressId: expressSendAddressId.value,//文印中心id
                printCenter: printCenter.value,//文印中心地址
                monthlyCust: monthlyCust.value,//月结卡号
                printerId: printerId.value, //打印盒子id
                faceSheetBoxId: faceSheetBoxId.value//面板框id
              }
              // printOrderModal.value.onSubmit(note.value, obj, repositories.value)
              printOrderModal.value.onSubmit(otherForm, obj, repositories.value)
            } else {
              Message.warning('请完善快递信息')
            }
          })
        } else {
          let otherForm = {
            note: note.value,
            expressSendAddressId: expressSendAddressId.value,//文印中心id
            printCenter: printCenter.value,//文印中心地址
            printerId: printerId.value, //打印盒子id
          }
          printOrderModal.value.onSubmit(otherForm, null, repositories.value)
        }
      }).catch((error) => {
        console.log('error-----------', error)
        Message.warning('请填写完整必要的参数！！111')
      })
    }
    const parmse = ref()
    const router = useRouter();

    // const route = useRoute();
    onMounted(async () => {
      // 获取路由参数的两种方式
      // console.log('toRaw(route)=', toRaw(route).query.value,'router.currentRoute=', router.currentRoute.value.query);
      const data: any = router.currentRoute.value.query.verify

      const query: any = returnQuery(data)
      const ids: any = query.ids
      //
      mark.value = query.mark
      contractName.value = query.contractName
      contractId.value = query.contractId
      userName.value = query.userName
      parmse.value = ids
      printNumbers.value = query.printNumbers
      isDoublePrint.value = query.isDoublePrint

      if (parmse.value) {
        // getPrintItem(parmse.value)
      }
      if (contractId.value) {
        getParseAddress(contractId.value)
      }
      //文印中心-地址
      getPrintCenterList();
      // 获取当前ip最近文印中心
      getNearPrintCenter()

      const result: any = await queryPrintPrice()
      printPrice.value = result
    })
    return {
      printNumbers,
      isDoublePrint,
      handleClick,
      repositories,
      expressChecked,
      pushRef,
      checked: ref(true),
      loading,
      address,
      note,
      userName,
      contractId,
      mark,
      loadingMore,
      returnPage,
      printPrice,
      parmse,
      contractName,
      totalPayable,
      setTotalPayable,
      expressOrder,
      printOrderModal,
      onSubmitOrder,
      changeChecked,

      printCenterList,
      expressSendAddressId,
      printCenter,
      monthlyCust,
      printerId,
      adsChange
    }
  }
})
