
  /**
   * 使用方法
   * 在form下直接写这个组件就行了，
   *<a-form layout="inline" :form="form" >
   *     <j-form-container :disabled="true">
   *         <!-- 表单内容省略..... -->
   *     </j-form-container>
   *</a-form>
   */
  
import { 
    defineComponent,
    onMounted
} from 'vue'
  export default defineComponent({
    name: 'JFormContainer',
    props:{
      disabled:{
        type:Boolean,
        default:false,
        required:false
      }
    },
    setup(props, context){
      onMounted(() => {
        {
          console.log("我是表单禁用专用组件,但是我并不支持表单中iframe的内容禁用")
        }
      })
    },
  })
