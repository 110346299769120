
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    toRaw
} from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
interface FormState {
    unit: string;
    area: string;
    post: string;
}
export default defineComponent({
    name:'base-info',
    description:'基础信息',
    setup(props, context){
        // form表单数据
        const formRef = ref();
        const formState:UnwrapRef<FormState> = reactive({
            unit: '',
            area: '',
            post: ''
        });
        // form表单数据验证
        const rules = {
            unit: [{ required: true, message: '请输入工作单位！' }],
            area: [{ required: true, message: '请输入所属区域！' }],
            post: [{ required: true, message: '请输入岗位类别！' }]
        };
        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                    console.log('values', formState,toRaw(formState));
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        };
        const resetForm = () => {
            formRef.value.resetFields();
        };

        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            formState,
            rules,
            formRef,
            submitForm,
            resetForm,
        };
    }
})
