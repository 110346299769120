/***
 * api 基础路径
 * 测试:dev.shoufusheji.com
 * 生产:prod.shoufusheji.com
 * **** */

const base = process.env.VUE_APP_API_BASE_URL
console.log('=======基础路由配置',base);
function base_url(base:String) {
    let temp = ''
    if(base.indexOf('dev') > -1) {
        temp = 'https://dev.shoufusheji.com/api'
    }else {
        temp = 'https://prod.shoufusheji.com/api'

    }
    return temp;
}
export const baseURL:string = base_url(base)

