
import { 
  defineComponent, 
  onMounted, 
  ref, 
  toRef,
  getCurrentInstance, 
  onBeforeUnmount, 
  nextTick,
  reactive,
} from 'vue'
import ContractPrintOrderForm from './ContractPrintOrderForm.vue'
export default defineComponent({
  name: 'ContractPrintOrderModal',
  props: {
  },
  components: {
    ContractPrintOrderForm
  },
  emits: ['ok'],
  setup(props, context) {
    const visible = ref<boolean>(false)
    const realForm = ref(null)
    const disableSubmit = ref<boolean>(false)
    
    const handleCancel = () => {
    }
    const edit = async (record: any) => {
      visible.value = true
      await nextTick()
      realForm.value.edit(record)
    }
    const submitCallback = () => {
      context.emit('ok');
      visible.value = false;
    }
    onMounted(() => {

    })
    const handleOk = () => {
      realForm.value.submitForm()
    }
    return {
      handleOk,
      disableSubmit,
      visible,
      handleCancel,
      submitCallback,
      edit,
      realForm
    };
  },
  
});
