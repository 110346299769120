
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { listHistory } from "@/api/hrUser";
import { GetDateDiff } from "@/utils/date";
import { Empty } from 'ant-design-vue';

export default defineComponent({
  props: {
    instanceId: String,
  },
  setup(props) {
    const state: {
      historicFlow: Array<any>;
      confirmLoading: boolean
    } = reactive({
      historicFlow: [],
      confirmLoading: false
    });
    // 审批历史
    function initHistoryList(instanceId: string): void {
      console.log(instanceId,'instanceId');
      
      if (!instanceId) return;
      state.confirmLoading = true
      listHistory({
        processInstanceId: instanceId,
      }).then((res) => {
        if (res.code === 200) {
          state.historicFlow = res.rows.sort((a, b) => {
            return b.id - a.id;
          });
        }
      }).finally(() => {
        state.confirmLoading = false
      })
    }
    onMounted(() => {
      initHistoryList(props.instanceId as string);
    });
    return {
      ...toRefs(state),
      initHistoryList,
      GetDateDiff,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
});
