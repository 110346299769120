
import { defineComponent, reactive, ref, toRef, createVNode, toRaw } from 'vue';

export default defineComponent({
  name: 'ChartCard',
  mixins: [],
  props: {
    title: {
      type: String,
      default: ''
    },
    total: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  emits: ['changTitle'],
  setup(props, context) {
    const title = toRef(props, 'title')
    const total = toRef(props, 'total')
    const loading = toRef(props, 'loading')
    const changTitle = () => {
      context.emit('changTitle')
    }
    return {
      title, total, loading, changTitle
    };
  },
  
});
