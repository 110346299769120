
import { defineComponent, onMounted, reactive, toRefs ,nextTick} from "vue";
import { generateCode ,getActiveUser} from "@/api/hrUser";
import QrcodeVue from 'qrcode.vue'
import { useRouter } from 'vue-router'
import { message } from "ant-design-vue";
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue';

export default defineComponent({
  components: {
    QrcodeVue
  },
  setup() {
    const router = useRouter()
   const store=useStore()
    const state: {
      toDataURLList: Array<any>,
      confirmLoading: Boolean
    } = reactive({
      toDataURLList: [],
      confirmLoading: false
    })
    onMounted(() => {



      const query = router.currentRoute.value.query

      
       
    if (query.userId) {
        state.confirmLoading = true
        generateCode({
          userId: query.userId as string,
        }).then((res) => {
          getActiveUser({id:query.userId as any}).then(ress=>{
          if(ress){
            query.deptHead=ress.username||''
    
            state.toDataURLList = res.map(item => {
           item.url = "https://print.shoufusheji.com/hr/login?departId=" + item.id + "&departName=" + item.name +"&branchtype=" +(item.branchtype?item.branchtype:'总院')+"&deptHead=" + query.deptHead||''
           return item
          })

          }
       }).catch((err)=>{
          if(err){
            query.deptHead=err.username||''
            state.toDataURLList = res.map(item => {
           item.url = "https://print.shoufusheji.com/hr/login?departId=" + item.id + "&departName=" + item.name +"&branchtype=" +(item.branchtype?item.branchtype:'总院')+"&deptHead=" + query.deptHead||''
           return item
          })
          }
       })

        
        })

       .finally(() => {
          state.confirmLoading = false
        })
      } else {
        message.warning('请配备userId！')
      }
       
  
     
    });
    return {
      ...toRefs(state),
    }
  },
});
