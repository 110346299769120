
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from '@ant-design/icons-vue';
import { queryEmployeeById, exportTable, getUpStep } from '@/api/largeScreenApi'
import {
  defineComponent,
  ref,
  toRefs,
  reactive,
} from 'vue'
import { DownCircleOutlined } from '@ant-design/icons-vue';
import EmployeeInfo from './modalContent/employeeInfo.vue'
import HistoryPerformance from './modalContent/HistoryPerformance.vue'
import PersonalInfo from './modalContent/Personal-Info.vue'
import Achievement from './modalContent/Achievement.vue'
import Monograph from './modalContent/Monograph.vue'
import Paper from './modalContent/Paper.vue'
import EducationInfo from './modalContent/EducationInfo.vue'
import ContinueEducationInfo from './modalContent/ContinueEducationInfo.vue'
import WorkExperience from './modalContent/WorkExperience.vue'
import { download } from '@/utils/download'
import { message } from 'ant-design-vue';
import moment from 'moment'
export default defineComponent({
  name: 'PersonageModal',
  props: {

  },
  components: {
    UserOutlined,
    SolutionOutlined,
    LoadingOutlined,
    SmileOutlined,
    PersonalInfo,
    Achievement,
    Monograph,
    Paper,
    EducationInfo,
    ContinueEducationInfo,
    EmployeeInfo,
    HistoryPerformance,
    WorkExperience,
    DownCircleOutlined
  },
  setup(props, context) {
    const state = reactive({
      confirmLoading: false,
      loadingMore: false,
      indexBottomTab: '0',
      loading: false,
      disabled: false,
      title: '人员详情',
      cancel: () => {
        // 清除填写数据
        declareMajor.value = ''
        declareTitle.value = ''
        ids.value = []
      },
      changeActiveKey: () => { },
      baseInfo: {
        personInfo: {},
        employeeId: null,
      },
      formState: {
        titleInfo: {
          flag: null,
          isHighest: null,
          level: null,
          majorCode: null,
          majorName: null,
          titleCode: null,
          titleName: null
        },
        employeeId: null,
        name: null,
        employDate: null,
        joincompanydate: null,
        id: null,
        countAuditContract:null,
        countPaperCategory:null
      },
      steps: [
        { title: "技术员", current: 0, description: '技术员', status: null, },
        { title: "助理工程师", current: 1, description: '初级', status: null, },
        { title: "工程师", current: 2, description: '中级', status: null, },
        { title: "高级工程师", current: 3, description: '高级', status: null },
        { title: "正高级工程师", current: 4, description: '正高级', status: null, },
      ],
      titleList: [],
      current: 1,
    })
    //人员详情查询
    const queryEmployee = (record: any) => {
      state.loading = true;
      queryEmployeeById({
        id: record.id,
        employeeId: record.employeeId
      }).then((res: { personInfo: {}; employeeId: any; }) => {

        state.formState = {
          ...state.formState,
          ...res
        }
        state.baseInfo = {
          ...res,
          employeeId: record.employeeId,
        }
        return getUpStep({
          employeeId: record.employeeId,
          id: record.id,
        })
        
      }).then((res: { otherInfo: any[]; countAuditContract: any; countPaperCategory: any; }) => {
        state.formState = {
          ...state.formState,
          titleInfo: res.otherInfo[0],
          countAuditContract:res.countAuditContract,
          countPaperCategory:res.countPaperCategory,
          id:res.otherInfo[0].titleId
        }
        for (let index = 0; index < state.steps.length; index++) {
          const element = state.steps[index];
          if (element.title === res.otherInfo[0].titleName) {
            state.current = element.current + 1;
            break;
          }
        }
      }).finally(() => {
        state.loading = false
      })
    }
    // 查询所有职称
    const selectTitle = (record: any) => {
      getUpStep({
        employeeId: record.employeeId,
      }).then((res: { otherInfo: any[]; }) => {
        state.titleList = res.otherInfo
      })
    }
    // 切换人员职称-专业
    const checkTitle = (record: any) => {
      // console.log('=====切换-专业', record)
      let form = {
        id: record.titleId,
        employeeId: record.employeeId,
      }
      queryEmployee(form)
    }
    async function open(record: object) {
      // console.log('======详情', record);
      state.loadingMore = true
      state.formState = Object.assign(record)
      selectTitle(record)
      queryEmployee(record);
    }
    //导出模块 
    const ids = ref([])
    const declareTitle = ref()
    const declareMajor = ref()
    const handleExportTable = () => {

      if (!declareTitle.value || !declareMajor.value) {
        return message.warning('请填写申报职称和申报专业')
      }

      exportTable({ declareMajor: declareMajor.value, declareTitle: declareTitle.value, ids: ids.value, employeeId: state.formState.employeeId,id:state.formState.id}).then((res: any) => {
        if (!res || res.size === 0) {
          message.warning('文件下载失败')
          return
        }
        //导出word
        var bstr = window.atob(res), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        download(u8arr, 'application/msword;', '专业技术职务任职资格评审表' + '.docx')
      })
    }
    // 
    // 格式化日期
    function formatDate(date) {
      return date?moment(date).format('YYYY-MM-DD'):date
    }
    return {
      ...toRefs(state),
      open,
      handleExportTable,
      checkTitle,
      declareTitle,
      declareMajor,
      ids,
      formatDate
    };
  },
});
