
import { ref, defineComponent, toRaw, toRef, reactive, UnwrapRef, watch, onMounted } from "vue";
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { expressEdit } from '@/api/manage'
import { ExpressOrderData, Option, ExpressEditVO, AddressItem } from '@/interface/print'
import { filterArea, _area } from '@/components/_util/Area'
export default defineComponent({
  name: "ExpressEditVO",
  props: {
  },
  emits: ['ok'],
  setup(props, context) {
    const addressValue = ref(0)
    const loadingMore = ref(false)
    const confirmLoading = ref(false)
    const valueArea = ref([])
    // const options = ref<any>(inject('$pcaa'))
    const pcaaData = _area
    
    let validatePhone = async (rule: RuleObject, value: string) => {
      if (value === '') {
        return Promise.reject('Please input the password');
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      receivePhone: [{ required: true, message: "请输入联系电话", validator: validatePhone, trigger: 'change' }],
      receiveName: [{ required: true, message: "请输入收件人姓名" }],
      receiveAddress: [{ required: true, message: "请输入详细地址" }],
      valueArea: [{ required: true, message: "请选择省市区" }],
    };
    const formState: UnwrapRef<ExpressOrderData> = reactive({
      receivePhone: '',
      receiveName: '',
      expressType: '1',
      receiveAddress: '',
      valueArea: [],
      payType: 1,
      id: ''
    });
    const edit = (record) => {
      console.log('edit-------------', record);
      
      loadingMore.value = true
      formState.id = record.id
      formState.receivePhone = record.receivePhone
      formState.receiveName = record.receiveName
      formState.expressType = record.expressType
      formState.receiveAddress = record.receiveAddress
      formState.payType = record.payType
      if (record.receiveProvince && record.receiveCity) {
        if (record.receiveCounty) {
          formState.valueArea = [record.receiveProvince, record.receiveCity, record.receiveCounty]
        }
      }
      if (record.receiveProvince == '四川省' || record.receiveProvince == '重庆市') {
        formState.expressType = '2'
      }
    }
    // const filter = ;
    const filter = (inputValue: string, path: Option[]) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    const onChange = (e: any) => {
      formState.valueArea = e
      if (e[0] === '四川省' || e[0] === '重庆市') {
        formState.expressType = '2'
      }
    }
    const cancel = () => {
      
    }
    const formRef = ref()
    const onSubmit = () => {
      
      formRef.value.validate().then(async () => {
        
        let obj = {
          ...formState,
          receiveProvince: formState.valueArea[0],
          receiveCity: formState.valueArea[1],
          receiveCounty: formState.valueArea[2],
        }
        delete obj.valueArea
        console.log(obj);
        confirmLoading.value = true
        await expressEdit(obj)
        context.emit('ok', obj)
        loadingMore.value = false
        confirmLoading.value = false
      }).catch((error: ValidateErrorEntity<ExpressOrderData>) => {
        console.log("error", error);
      });
      
    }
    return {
      formRef,
      rules,
      formState,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      filter,
      onChange,
      valueArea,
      pcaaData,
      addressValue,
      edit,
      onSubmit,
      cancel,
      loadingMore,
      confirmLoading,
    };
  },
});
