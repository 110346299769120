
import { watch, defineComponent, toRef, reactive, ref, toRefs, PropType, computed, onMounted } from 'vue'
import type { UnwrapRef } from 'vue'
import { useStore } from 'vuex'
import { PostTab, hrUserFormState, FileItem, FileInfo } from '@/views/HrUser/data'
import { message } from 'ant-design-vue';
import { _area } from '@/components/_util/Area'
import { EDIT_USER, getJhDictItems, updateVariables,parseBankCard } from '@/api/hrUser'
import { getToken, getUserInfo } from "@/utils/auth";

import { useRouter } from 'vue-router'
import { isArray, isNumber, isString } from 'lodash'
import moment from 'moment'
import { UploadOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { baseURL } from '@/config/index'
import {
  parseBusinessLicense,

} from "@/api/companySettled";
import { Option } from '@/interface/print'
export default defineComponent({
  emits: ['toNextTab'],
  props: {
    data: {
      type: Object as PropType<hrUserFormState>
    },
    type: {
      type: String,
      default: ''
    }

  },
  components: {
    UploadOutlined
  },
  setup(props, context) {
    const router = useRouter()
    const datas = toRef(props, 'data')
    const type = toRef(props, 'type')
    const disabled = computed(() => {
      if (type.value === 'detail') {
        return true
      } else {
        return false
      }
    })
    const postDisabled = computed(() => {
        if (formState.deptId) {
        return true
      }
        return false
    }
    )
      const pcaaDatafliter = computed(() => {
        let arr=_area
        let arr2=[]
         for (var i = 0; i < arr.length; i++) {
            var item = arr[i];
            if (item.children) {          // 如果当前元素有“children”属性
              var children = item.children;
              delete item.children;       // 删除“children”属性
              for (var j = 0; j < children.length; j++) {
                var child = children[j];
                if (child.children) {     // 如果当前子元素也有“children”属性
                  delete child.children;  // 删除它
                }
              }
              item.children = children;   // 将第二层childern赋值给item.children
            }
            arr2[i]=item

          }

          return arr2

     }
       )


    const store = useStore()
    const pcaaData = _area
 
  

    const state = reactive<{
      children: Array<Object>;
      childrenCity: Array<Object>;
      childrenCounty: Array<Object>;
      branchIndustry: Array<Object>;
      mainIndustryList: Array<Object>;
      confirmLoading: boolean;
      bankCardPicLoading: boolean,
      headers: Object;
      action: String;
      branchtypeisshow: boolean,
      postList:Array<Object>,
      branchtypeList:Array<Object>,
      employmentWayList:Array<Object>,
      aaumSignList:Array<Object>,
      contractShapeList:Array<Object>,
      socialSecurityTypeList:Array<Object>,
      socialSecurityCompanyList:Array<Object>,
      personnelTypeList:Array<Object>,
      socialsecurityAddress:Array<Object>,
      BranchcompanyAddress:Array<Object>,
      fileList:Array<Object>,
      CompanyDate:Array<Object>,
    }>({
      bankCardPicLoading: false,
      fileList: [],
      children: [],
      childrenCity: [],
      confirmLoading: false,
      childrenCounty: [],
      branchIndustry: [],
      mainIndustryList: [],
      headers: { "X-Access-Token": getToken() },
      action: baseURL + '/sfsj-server/sys/oss/api/uploadRequest',
      branchtypeisshow: false,
      CompanyDate:[],
      aaumSignList:[
        {title:'是',key:1},
        {title:'否',key:0},

      ],
      postList:[
        '职员',
        '分子公司负责人'
      ],
      employmentWayList:[
       '正式员工',
       '外聘员工',
       '协作人员'
      ],
      contractShapeList:[
        '固定期限',
        '无固定期限',
        '以完成一定任务为期限'
      ],
      socialSecurityTypeList:[
        '新参',
        '转入',
      ],
      socialSecurityCompanyList:[
        '总公司',
        '所属分公司',
        '其他',
      ],
      personnelTypeList:[
        '是',
        '否'
      ],
      branchtypeList:[
        '总院',
        '加盟公司',
        '直属公司',
      ],
      socialsecurityAddress:null,
      BranchcompanyAddress:null
    })
    const formState: UnwrapRef<PostTab> = reactive({
      mobilePhone: null,
      branchtype: null,
      deptId: '',
      post: null,
      joinCompanyDate: null,
      employmentWay: null,
      contractShape: null,
      salary: null,
      periodStartDate: null,
      periodEndDate: null,
      probationSalary: null,
      socialSecurityType: null,
      socialSecurityCompany: null,
      socialSecurityProvince: null,
      socialSecurityCity: null,
      actualWorkUnit: null,
      major: null,
      personnelType: null,
      deptIdname: null,
      id: null,
      province: null,
      city: null,
      county: null,
      scope: [],
      branchName: null,
      mainIndustry: null,
      aaumSign: null,
      license: null,
      bankCardNo: null,
      bank: null,
      bankCardPic: null,
    });
    const rules = {
      branchtype: [{ required: true, message: '请选择公司类型' }],
      personnelType: [{ required: true, message: '请选择人员类型' }],
      post: [{ required: true, message: '请选择职位' }],
      employmentWay: [{ required: true, message: '请选择用工形式' }],
      contractShape: [{ required: true, message: '请选择劳动合同类型' }],
      socialSecurityType: [{ required: true, message: '请选择社保类型！' }],
      socialSecurityCompany: [{ required: true, message: '请选择社保所在公司！' }],
      socialSecurityProvince: [{ required: true, message: '请选择社保所在地区！' }],
      // socialSecurityCity: [{ required: true, message: '请选择社保所在城市！' }],
      province: [{ required: true, message: '请选择分子公司所在地区！' }],
      // city: [{ required: true, message: '请选择分子公司所在城市！' }],
      // county: [{ required: true, message: '请选择分子公司所在区！' }],
      scope: [{ required: true, message: '请选择分子公司经营范围！' }],
      deptIdname: [{ required: true, message: '职员必填部门，请联系管理员！' }],
      actualWorkUnit: [{ required: true, message: '协作人员，必填实际工作地址！' }],
      major: [{ required: true, message: '协作人员，必填专业！' }],
      probationSalary: [{ required: true, message: '请输入数字，没有填 0 ' }],
      salary: [{ required: true, message: '请输入数字，没有填 0 ' }],
      mainIndustry: [{ required: true, message: '请输入主要产业 ' }],
      // branchName: [{ required: true, message: '请输入工商注册名称 ' }],
      joinCompanyDate: [{ required: true, message: '请输入入司时间 ' }],
      aaumSign: [{ required: true, message: '请选择是否需要工商注册 ' }],
      license: [{ required: true, message: '请上传分子公司营业执照 ' }],

    };
    const LicenseList = ref<FileItem[]>();
    const formRef = ref()
    
    const previewVisible = ref<boolean>(false);
    const previewImage = ref<string | undefined>('');
    function getBase64(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string;
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    }
    const handleCancel = () => {
      previewVisible.value = false;
    }

    const handleBeforeUpload = () => {
      if (formState) {
        if (isArray(formState.scope)) {
          formState.scope = formState.scope.join(',')
        }
      }
      context.emit('toNextTab', 2, { ...formState })
    }
    const handleChangebankCardPic = (info: FileInfo, key: string) => {

      if (info.file.status === 'uploading') {
        state.bankCardPicLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        formState.bankCardPic = info?.file?.response?.result?.url;
        parseBankCard({
            bankCardImgUrl: formState.bankCardPic
        }).then(res=> {
            formState.bankCardNo = res.bankCardNo || formState.bankCardNo
            formState.bank = res.bank || formState.bank
        }).finally(() => {
            state.bankCardPicLoading = false;
        })

      }
      if (info.file.status === 'error') {
        state.bankCardPicLoading = false;
        message.error('上传失败');
      }
    }
    const handleLicenseChange = (info: FileInfo, key: string) => {
      if (info.file.status === 'uploading') {
        state.confirmLoading = true
        return;
      }
      if (info.file.status === 'done') {
        let resFileList = [...info.fileList]
        resFileList = resFileList.slice(-1);
        LicenseList.value = resFileList
        let tempFile = resFileList.map(el => el?.response?.result?.url)
        formState[key] = tempFile.join(',');
        let form = new FormData();
        form.append("url", tempFile[0]);
        parseBusinessLicense(form)
              .then((res) => {
                if (res.success) {
                  console.log(res,'imgres');
                  
                  formState.branchName = res.name;
                }
                state.confirmLoading = false
              }).catch(()=>{
                state.confirmLoading = false
              })
      }
      if (info.file.status === 'error') {
        state.confirmLoading = false
        message.error('上传失败');
      }
    };

    function disabledperiodStartDate(current: { valueOf: () => any; }) {
      //current为当前日期选择页的全部日期
      let endTime = moment(formState.joinCompanyDate).valueOf();//valueOf()将moment(this.startTime)转化为毫秒数值
      let currentTime = current.valueOf();
      return currentTime < endTime;//禁用小于开始时间的日期
    }

    function disabledperiodEndDate(current: { valueOf: () => any; }) {
      //current为当前日期选择页的全部日期
      let endTime = moment(formState.periodStartDate).valueOf();//valueOf()将moment(this.startTime)转化为毫秒数值
      let currentTime = current.valueOf();
      return currentTime < endTime;//禁用小于开始时间的日期
    }

    function changeAaumSign(v: any) {
      console.log(v, '是否需要公司注册');

    }
    function toNextTab(key: number) {
      //点击上一步则不效验
      if (key === 1) {
        if (formState) {

          if (isArray(formState.scope)) {
            formState.scope = formState.scope.join(',')
          }
        }
        context.emit('toNextTab', key, { ...formState, stage: key })
      } else {
        formRef.value.validate().then(async () => {
          if (formState) {
            if (isArray(formState.scope)) {
              formState.scope = formState.scope.join(',')
            }
          }
          context.emit('toNextTab', key, { ...formState, stage: key })

          // state.confirmLoading = true
          // EDIT_USER({ ...formState, stage: key }).then(res => {
          //   context.emit('toNextTab', key, formState)
          // }).finally(() => {
          //   state.confirmLoading = false
          // })
        }).catch((error: any) => {
          console.log('error', error)
        })
      }

    }
    function handleChange(...agme) {
      console.log(...agme, '666');

      if (agme[1] == 1) {
        formState.socialSecurityCity = null
      }
      for (let index = 0; index < pcaaData.length; index++) {
        const element = pcaaData[index]
        if (element.label === agme[0] || element.value === agme[0]) {
          state.children = element.children
          break
        }
      }
    }
    function handleChangeProvince(value: string | number) {
      formState.city = null
      for (let index = 0; index < pcaaData.length; index++) {
        const element = pcaaData[index]
        if (element.label === value || element.value === value) {
          state.childrenCity = element.children
          break
        }
      }
    }
    function handleChangeCity(value: string | number) {
      formState.county = null
      for (let index = 0; index < state.childrenCity.length; index++) {
        const element = state.childrenCity[index] as { label: string; value: number | string; children: Array<Object>; }
        if (element.label === value || element.value === value) {
          state.childrenCounty = element.children
          break
        }
      }
    }
    function getDictItems(code: string) {
      getJhDictItems({ code }).then((res: { branch_business: any; }) => {
        const { branch_business } = res
        state.branchIndustry = branch_business
        // console.log(state.branchIndustry,'state.branchIndustry');

      })
    }
    function changeBranchtype(e: any) {
      // formState.post = null
    }
    function changePost(e: any) {
      formState.employmentWay = null
    }
    function setDept() {
      if (type.value === 'detail') {
        return
      }
      const data: any = router.currentRoute.value.query
      formState.deptIdname = data.departName || formState.deptIdname
      formState.deptId = data.departId || formState.deptId
      formState.branchtype = data.branchtype || formState.branchtype
    }
    onMounted(() => {
      const data: any = router.currentRoute.value.query
      console.log(data, '43567');
      if (data.branchtype) {
        state.branchtypeisshow = true
      } else {
        state.branchtypeisshow = false

      }
      formState.branchtype = data.branchtype || formState.branchtype
      getDictItems('branch_business')
      console.log(formState.scope, '5555')
      //  if(formState.scope){
      //     formState.scope=formState.scope.split(',')
      //     }

    })
     const onChange = (e: any) => {
      formState.socialSecurityProvince = e[0] || ''
      formState.socialSecurityCity = e[1] || ''

    }
    const onChangeBranchcompany= (e: any) => {
      formState.province = e[0] || ''
      formState.city = e[1] || ''
      formState.county = e[2] || ''

    }
     const filter = (inputValue: string, path: Option[]) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    function pickerLdchange(e){
           console.log(e,'eeee');
        formState.periodStartDate=e[0] || null 
        formState.periodEndDate=e[1] || null 
    }
    watch(
      () => datas.value,
      () => {
        Object.keys(formState).forEach(item => {

          if ((datas.value as any)[item] || isNumber((datas.value as any)[item])) {
            formState[item] = (datas.value as any)[item]
          }
        })
        if( formState.periodStartDate &&  formState.periodEndDate){
          state.CompanyDate=[formState.periodStartDate,formState.periodEndDate]
        }else{
          state.CompanyDate=[]
        }
        if(formState.socialSecurityProvince || formState.socialSecurityCity){
   state.socialsecurityAddress=[formState.socialSecurityProvince || '',formState.socialSecurityCity||'']
        }else{
          state.socialsecurityAddress=null
        }
    if(formState.province || formState.city || formState.county) {
   state.BranchcompanyAddress=[formState.province || '',formState.city||'', formState.county||'']
        }else{
          state.BranchcompanyAddress=null
        }

        

        let s = formState.socialSecurityCity
        if (formState.socialSecurityProvince) {
          handleChange(formState.socialSecurityProvince)
        }
        formState.socialSecurityCity = s
        // const c = formState.city
        // if (formState.province) {
        //   handleChangeProvince(formState.province)
        // }
        if (formState.license) {
          LicenseList.value = formState.license.split(',').map((e: any) => {
            let data = e.split('/').pop()
            return { name: data.slice(data.indexOf('_') + 1), url: e }
          })
        }
        // formState.city = c
        // const f = formState.county
        // if (formState.city) {
        //   handleChangeCity(formState.city)
        // }
        // formState.county = f
        let t = formState.scope
        console.log(t, '321');

        if (t == null) {
          formState.scope = []
        } else if (t) {
          if (t[0] == '') {
            formState.scope = []
          }
        }

        if (isString(t)) {
          console.log('11111');
          formState.scope = t.split(',') as any

        }

        // formState.aaumSign


        setDept()
      }
    )
  function selectBut(e,str){
      if( formState[str]==e){
        // formState[str]=''
      }else{
        formState[str]=e
      }

    }
 
    watch(() => formState.joinCompanyDate, (newvalue, oldvalue) => {
      if (!newvalue) {
        formState.periodStartDate = ''
        formState.periodEndDate = ''
      }
    },
      { immediate: false }
    )

    watch(() => formState.periodStartDate, (newvalue, oldvalue) => {
      if (!newvalue) {
        // formState.periodStartDate=''
        formState.periodEndDate = ''
      }
    },
      { immediate: false }
    )
    watch(() => formState.scope, (newvalue, oldvalue) => {
      console.log(newvalue, 'scopsssss');

      console.log(state.branchIndustry, 'branchIndustry');
      if (newvalue && newvalue.length > 0 && typeof formState.scope === 'object') {
        state.mainIndustryList = []
        newvalue.forEach((el: any, index: any) => {
          state.branchIndustry.forEach((e, i) => {
            if (el == e['value']) {
              state.mainIndustryList.push(e)
            }
          })
        })
      } else {
        state.mainIndustryList = []
        formState['mainIndustry'] = null

      }



    },
      { immediate: false }
    )

    return {
      formState,
      formRef,
      ...toRefs(state),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules,
      toNextTab,
      pcaaData,
      handleChange,
      disabled,
      handleChangeProvince,
      handleChangeCity,
      postDisabled,
      changeBranchtype,
      changePost,
      disabledperiodStartDate,
      disabledperiodEndDate,
      changeAaumSign,
      handlePreview,
      previewVisible,
      previewImage,
      getBase64,
      handleCancel,
      handleLicenseChange,
      handleBeforeUpload,
      LicenseList,
      selectBut,
      onChange,
      onChangeBranchcompany,
      filter,
      pcaaDatafliter,
      handleChangebankCardPic,
      pickerLdchange
 
    };
  },
});
