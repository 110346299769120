
import { 
  SmileOutlined, 
  DownOutlined, 
  PrinterOutlined, 
  SearchOutlined, 
  ReloadOutlined, 
  ExclamationCircleOutlined, 
  CheckCircleOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, toRef, reactive, createVNode } from 'vue';
import { contractPrintOrderList, setPlaceOrders, printExpressImg, printRefund,printCenterAdsList } from '@/api/manage'
import { deliveryStatusColor, deliveryStatus, paymentStatus, paymentStatusColor, payType,printOrign } from './modules/statusType'
import { message as Message, Modal } from 'ant-design-vue'
import { PrintOrderList } from '@/interface/print'
import { returnQuery, pastTime } from '@/utils/index'
import ContractPrintOrderModal from './modules/ContractPrintOrderModal.vue'
import LogisticsModal from './modules/LogisticsModal.vue'
import { useRouter } from 'vue-router'
const columns = [
  {
    title: '#',
    dataIndex: '',
    key:'rowIndex',
    width:60,
    align:"center",
    customRender:function ({text,record,index}) {
      return parseInt(index)+1;
    }
  },
  {
    title:'合同名称',
    align:"center",
    width: 100,
    dataIndex: 'contractNames',
    slots: { customRender: 'contractNames' }
  },
  {
    title:'用户',
    align:"center",
    width: 80,
    dataIndex: 'userName'
  },
  {
    title:'创建时间',
    align:"center",
    width:120,
    dataIndex: 'createTime'
  },
  {
    title:'订单总金额',
    align:"center",
    width:100,
    dataIndex: 'totalAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  
  {
    title:'付款状态',
    align:"center",
    dataIndex: 'paymentStatus',
    slots: { customRender: 'paymentStatus' }
  },
  {
    title:'付款时间',
    align:"center",
    width:120,
    dataIndex: 'paymentTime'
  },
  {
    title: '付款方式',
    dataIndex: 'payType',
    align: 'center',
    key: 'payType',
    slots: { customRender: 'payType' },
  },
  {
    title:'物流状态',
    align:"center",
    dataIndex: 'deliveryStatus',
    slots: { customRender: 'deliveryStatus' }
  },
  {
    title:'物流单号',
    align:"center",
    dataIndex: 'deliverySn'
  },
  {
    title:'打印费',
    align:"center",
    width:100,
    dataIndex: 'printAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  {
    title:'快递费',
    align:"center",
    width:100,
    dataIndex: 'freightAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  {
    title:'附件数',
    align:"center",
    width: 70,
    dataIndex: 'printItemNum',
  },
  {
    title:'已打印数',
    align:"center",
    width: 80,
    dataIndex: 'printedNum',
  },
  {
    title:'收件人姓名',
    align:"center",
    width:100,
    dataIndex: 'receiveName'
  },
  {
    title:'收件人电话',
    align:"center",
    width:120,
    dataIndex: 'receivePhone'
  },
  {
    title:'文印中心',
    align:"center",
    width:120,
    dataIndex: 'printCenter'
  },
  // {
  //   title:'完成时间',
  //   align:"center",
  //   dataIndex: 'createTime',
  //   customRender:function (text) {
  //     return !text?"":(text.length>10?text.substr(0,10):text)
  //   }
  // },
  {
    title: '操作',
    dataIndex: 'action',
    align:"center",
    fixed:"right",
    width:160,
    slots: { customRender: 'action' }
  }
];

export default defineComponent({
  name: 'ContractPrintOrderList',
  mixins: [],
  props: {
  },
  setup(props, context) {
    const userName = ref('')
    const pageNo = ref<number>(1);
    const urlTime = ref<number>(0);
    const modalForm = ref(null);
    const logisticsModal = ref(null);
    const state = reactive({
      queryParam: {
        userName: '',
        status: '',
        receivePhone: '',
        receiveName: '',
        deliverySn: '',
        deliveryStatus: '',
        paymentStatus: '1',
        payType: '',
        type:'',
        printCenter:''
      },
    });
    
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    // const type = ref<any>('1')
    /* table选中keys*/
    const selectedRowKey = ref<any[]>([]);
    /* table选中records*/ 
    const selectionRow = ref<any[]>([]);
    const pagination = computed(() => ({
      current: pageNo.value,
      pageSize: pageSize.value,
      pageSizeOptions: ['10', '20', '30'],
      showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: total.value
    }));
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
      getContractPrintOrderList()
    }
    const searchReset = () => {
      state.queryParam = {
        userName: '',
        paymentStatus: '',
        status: '',
        receivePhone: '',
        receiveName: '',
        deliverySn: '',
        deliveryStatus: '',
        payType: '',
        type:'',
        printCenter:''
      }
      getContractPrintOrderList(1)
    }
    const onClearSelected = () => {
      selectedRowKey.value = []
      selectionRow.value = []
    }
    const searchQuery = () => {
      getContractPrintOrderList(1)
    }
    const onPrintRefund = (record, type) => {
      if (!record[type]) {
        Message.warning('无费用可退！')
        return;
      }
      Modal.confirm({
        title: () => '确定退款金额' + (record[type] / 100) + '元',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => '',
        onOk() {
          loading.value = true
          printRefund({
            id: record.id,
            refundAmount: record[type]
          }).then(res => {
            Message.success('提交成功')
            getContractPrintOrderList()
          }).catch(err=>{
            loading.value = false
          })
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
      
      
    }
    const handleEdit = (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      modalForm.value.edit(record);
      modalForm.value.disableSubmit = false;
    }
    const placeOrders = (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      if (!record.payType) {
        Message.error('没有快递信息!')
        return
      }
      loading.value = true
      setPlaceOrders({ printOrderIds: record.id }).then(res=>{
        Message.success('提交成功')
        getContractPrintOrderList()
        onClearSelected();
      }).catch(err=>{
        loading.value = false
      })
      
    }
    const onPrintExpressImg = (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      loading.value = true
      printExpressImg({ id: record.id }).then(res => {
        Message.success('提交成功')
        getContractPrintOrderList()
      }).catch(err=>{
        loading.value = false
      })
      
    }
    const getLogistics = async (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      if (!record.deliverySn) {
        Message.error('没有物流单号!')
        return
      }
      logisticsModal.value.getLogistics({ mailNos: record.deliverySn })
    }
    const batchPlaceOrders = () => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      if (selectedRowKey.value.length <= 0) {
        Message.warning('请选择一条记录！');
        return;
      } else {
        var printOrderIds = "";
        for (var a = 0; a < selectedRowKey.value.length; a++) {
          printOrderIds += selectedRowKey[a] + ",";
        }
        Modal.confirm({
          title: () => '确定提交选中的订单吗?',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => '',
          async onOk() {
            await setPlaceOrders({ printOrderIds })
            Message.success('提交成功')
            getContractPrintOrderList()
            onClearSelected();
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel() {},
        });
        
      }
    }
    const modalFormOk = () => {
      getContractPrintOrderList()
      onClearSelected();
    }
    function onSelectChange(selectedRowKeys, selectionRows) {
      selectedRowKey.value = selectedRowKeys;
      selectionRow.value = selectionRows;
    }
    const getContractPrintOrderList = async (page?: number) => {
      if (page) {
        pageNo.value = page
      }
      loading.value = true
      let paramse: PrintOrderList = {
        pageNo: pageNo.value,
        pageSize: pageSize.value,
        order:'desc',
        column: 'createTime',
        ...state.queryParam,
        userName: '*' + state.queryParam.userName +'*',
        deliverySn: '*' + state.queryParam.deliverySn +'*',
        receiveName: '*' + state.queryParam.receiveName +'*',
        receivePhone: '*' + state.queryParam.receivePhone +'*',
        printCenter:'*' + state.queryParam.printCenter +'*'
        // type: type.value//合同打印1，用印2，检测报告打印3
      }
      
      // const data = await contractPrintOrderList(paramse)
      contractPrintOrderList(paramse).then(res=> {
        console.log('contractPrintOrderList====', res);
        total.value = res.total
        dataSource.value = res.records
        loading.value = false
      }).catch(err=> {
        console.log('contractPrintOrderList--------',err);
        Message.error('请求失败')
        loading.value = false
      })
      
    }

    //文印中心--地址list
    //文印中心-地址
    const printCenterList = ref([]);
    const getPrintCenterList = async () => {
      const data = await printCenterAdsList({pageNo:1,pageSize:10});
      printCenterList.value = data.records
      // console.log('==========printCenterList',printCenterList)
    }

    const router = useRouter();
    onMounted(() => {
      const data: any = router.currentRoute.value.query.verify
      const query: any = returnQuery(data);
      //mark 分类:合同打印1(默认)，用印2，检测报告打印3
      // type.value = query.mark
      state.queryParam.type = query.mark;
      if (!query.time) {
        Message.warning('链接已失效，请重新进入')
      } else {
        urlTime.value = Number(query.time)
        getContractPrintOrderList()
      }
      
      getPrintCenterList()
    })
    return {
      dataSource,
      columns,
      loading,
      userName,
      pagination,
      paymentStatus,
      deliveryStatusColor,
      paymentStatusColor,
      deliveryStatus,
      payType,
      printOrign,
      getContractPrintOrderList,
      handleTableChange,
      onClearSelected,
      onSelectChange,
      selectedRowKey,
      selectionRow,
      handleEdit,
      batchPlaceOrders,
      modalFormOk,
      placeOrders,
      state,
      searchQuery,
      searchReset,
      onPrintExpressImg,
      modalForm,
      getLogistics,
      logisticsModal,
      onPrintRefund,
      printCenterList
    };
  },
  components: {
    ContractPrintOrderModal,
    LogisticsModal,
    PrinterOutlined,
    SearchOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    DownOutlined
  },
});
