import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4efb0ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_caret_up_outlined = _resolveComponent("caret-up-outlined")!
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!

  return (_openBlock(), _createElementBlock("div", {
    style: {"cursor":"pointer"},
    class: _normalizeClass([_ctx.prefixCls, _ctx.reverseColor && 'reverse-color' ])
  }, [
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "term", {}, undefined, true),
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.flag])
    }, [
      (_ctx.flag =='up')
        ? (_openBlock(), _createBlock(_component_caret_up_outlined, { key: 0 }))
        : (_ctx.flag =='down')
          ? (_openBlock(), _createBlock(_component_caret_down_outlined, { key: 1 }))
          : _createCommentVNode("", true)
    ], 2)
  ], 2))
}