
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    toRaw
} from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { Moment } from 'moment';
import { jobSery } from '@/utils/customExpression';
interface FormState {
    jobSeries:'',
    jobName:'',
    jobGrade:'',
    jobTime:Moment | undefined
}
export default defineComponent({
    name:'base-info',
    description:'职务信息', 
    setup(props, context){   
        const state = reactive({
            series:{},
            seriesVisible:false,
            noFlag:false
        })
        // form表单数据
        const formRef = ref();
        const formState:UnwrapRef<FormState> = reactive({
            jobSeries:'',
            jobName:'',
            jobGrade:'',
            jobTime:undefined
        });
        // form表单数据验证
        const rules = {
            jobSeries: [{ required: true, message: '请选择职务系列！' }],
            jobName: [{ required: true, message: '请选择/输入职务名称！' }],
            jobGrade: [{ required: true, message: '请输入职务等级！' }],
            jobTime: [{ required: true, message: '请选择任职时间！' }]
        };
        //职务系列选择
        const handlerSelect = (value: string) =>{
            formState.jobName = '';
            state.series = jobSery[value].children;
            console.log(value);
            state.seriesVisible = !!state.series;
            state.noFlag = value == '0';
        };
        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                    console.log('values', formState,toRaw(formState).jobTime ? toRaw(formState).jobTime.format('YYYY-MM-DD'):'');
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        };
        const resetForm = () => {
            formRef.value.resetFields();
        };
        return {
            ...toRefs(state),
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            formState,
            rules,
            formRef,
            jobSery,
            handlerSelect,
            submitForm,
            resetForm,
        }
    }
})
