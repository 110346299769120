
import { PropType, defineComponent, toRef, ref, watch  } from 'vue';
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { EDIT_USER } from "@/api/hrUser"
import { hrUserFormState } from "@/views/HrUser/data"

export default defineComponent({
    props: {
    data: {
      type: Object,
    },
   
  },
  setup(props,context) {
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
     const datas = toRef(props, 'data')
    function out() {
      store.dispatch('Logout').then(res=>{
        message.success('退出成功')
        if(router.currentRoute.value.query.SubsidiaryReg==1 as any){
           let obj={SubsidiaryReg:1}
            router.push({
            path: '/hr/login',
            query: {
              ...obj
            }}) 
        }else{
        router.go(-1)

        }
       
      })
    }
    function refresh() {
      // loading.value = true
      // reload()
      router.go(0)
      // context.emit('refresh',datas['instanceId'])
      // setTimeout(() => {
      //   message.success('刷新成功')
      //   loading.value = false
      // }, 1000)
    }
    return {
      out,
      refresh,
      loading,
      datas,
    };
  },
});
