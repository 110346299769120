
const columns = [
    {
        title: '毕业学校',
        align:"center",
        dataIndex: 'school'
    },
    {
        title: '专业',
        align:"center",
        dataIndex: 'firstProfession'
    },
    {
        title: '学历',
        align:"center",
        dataIndex: 'education'
    },
    {
        title: '毕业证编号',
        align:"center",
        dataIndex: 'graduationcertificatenumber'
    },
    {
        title: '学位',
        align:"center",
        dataIndex: 'degree'
    },
    {
        title: '学习形式',
        align:"center",
        dataIndex: 'schoolshape'
    },
    {
        title: '入学时间',
        align:"center",
        dataIndex: 'entranceldate'
    },
    {
        title: '毕业时间',
        align:"center",
        dataIndex: 'graduationdate'
    },
    {
        title: '学位授予时间',
        align:"center",
        dataIndex: 'degreegivedate'
    },
    {
        title: '学位授予国家',
        align:"center",
        dataIndex: 'degreegivecountry'
    },
    {
        title: '学历证书',
        align:"center",
        dataIndex: 'educationCard',
        slots: { customRender: 'educationCard' }
    },
    {
        title: '学位证书',
        align:"center",
        dataIndex: 'degreeCard',
        slots: { customRender: 'degreeCard' }
    }
]
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    onMounted,
    nextTick,
} from 'vue'
import { getRelevant } from '@/api/largeScreenApi'
export default defineComponent({
    name:'education',
    description:'教育经历-显示table',
    props:{
        id:{
            type:String,
            default:''
        }
    },
    setup(props, context){
        const id = toRef(props, 'id');
        const state = reactive({
            columns:columns,
            dataSource:[],
            ipagination:{
                current: 1,
                pageSize: 5,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0,
            },
            loading:false,
            tableChange: (pagination) => {
                console.log('========翻页',pagination)
                state.ipagination.current = pagination.current
                state.ipagination.pageSize = pagination.pageSize
                state.loading = true;
                initData(id.value)
            }
        })
        const initData = (id) => {
            state.loading = true;
            getRelevant({
                id:id,
                type:'4',
                pageNo:state.ipagination.current,
                pageSize:state.ipagination.pageSize
            }).then(res => {
                state.dataSource = res.records
                state.ipagination.total = res.total;
            }).finally(() => {
                state.loading = false
            })
        }
        onMounted(() => {
            nextTick(() => {
                console.log('======教育经历初始化',id.value)
                initData(id.value)
            })
        })
        return {
            ...toRefs(state)
        };
    }
})
