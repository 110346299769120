
import { computed, defineComponent, reactive, ref, toRefs, onMounted, toRef, PropType, watch } from 'vue'
import type { UnwrapRef } from 'vue'
import { useStore } from 'vuex'
import { BasicsTab, hrUserFormState } from '@/views/HrUser/data'
import { EDIT_USER, checkOnlyUser,checkOnlyemail } from '@/api/hrUser'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import { getUserPhone } from '@/utils/auth'
import {baseURL} from '@/config/index'
export default defineComponent({
  emits: ['toNextTab'],
  props: {
    data: {
      type: Object as PropType<hrUserFormState>
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    PlusOutlined, LoadingOutlined
  },
  setup (props, context) {
    const datas = toRef(props, 'data')
    const type = toRef(props, 'type')
    const disabled = computed(() => {
      if (type.value === 'detail') {
        return true
      } else {
        return false
      }
    })
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      url: baseURL+'/test/hr/bill/uploadPictures',
      confirmLoading: false
    })
    const formState: UnwrapRef<BasicsTab> = reactive({
      name: null,
      sex: null,
      mobilePhone: null,
      identityCardCode: null,
      birthday: null,
      age: null,
      political: null,
      joinPartyDate: null,
      nation: null,
      maritalStatus: null,
      nativePlace: null,
      hasAcademicDegree: null,
      address: null,
      emergencyContact: null,
      emergencyContactPhone: null,
      hasProfessionalTitle: null,
      hasProfessionalQualifications: null,
      email: null,
      healthStatus: null,
      id: null,
      
    })
    formState.mobilePhone = getUserPhone()
    const validateEmail = async (rule: RuleObject, value: string) => {
      if (value === '') {
        return Promise.reject('请输入邮箱号')
      } else {
        if (new RegExp(/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/).test(value)) {
          return new Promise<void>((resolve, reject) => {
            checkOnlyemail({
              email: value,
              id:formState.id
            }).then(res => {
              console.log(res,'ressssss');
              
              resolve()
            }).catch(err => {
              console.log(err,'errsssss');

              if (err.success) {
                resolve()
              } else {
                reject('邮箱已重复!')
              }
            })
          })
        } else {
          return Promise.reject('请输入正确格式的邮箱号!')
        }
      }
    }
    const rules = {
      name: [
        { required: true, message: '请输入姓名', trigger: 'blur' }
      ],
      joinPartyDate: [{ required: true, message: '请选择入党日期！' }],
      email: [{ required: true, validator: validateEmail, trigger: 'change' }, { type: 'email' }],
      identityCardCode: [{ required: true, message: '请输入正确的身份证号码！',max:18,min:18}],
      sex: [{ required: true, message: '请选择性别！'}],
      political: [{ required: true, message: '请选择政治面貌！'}],
    }
    const formRef = ref()
    function toNextTab (key) {
      if(key==1){
        context.emit('toNextTab', key, { ...formState, stage: key })
        return
      }
      formRef.value.validate().then(async () => {
        context.emit('toNextTab', key, { ...formState, stage: key })
      }).catch((error) => {
        console.log('error', error)
      })
    }
    function out () {
      store.dispatch('Logout').then(res => {
        message.success('退出成功')
        router.push({
          path: '/hr/login'
        })
      })
    }
    watch(
      () => datas.value,
      () => {
        Object.keys(formState).forEach(item => {
          formState[item] = (datas.value as hrUserFormState)[item]
          
        })
      }
    )
    return {
      ...toRefs(state),
      formState,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules,
      toNextTab,
      out,
      disabled
    }
  }
})
