import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"white-space":"nowrap","width":"100%","display":"flex","justify-content":"space-evenly"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { bordered: false }, {
    default: _withCtx(() => [
      _createVNode(_component_a_result, {
        status: "success",
        title: "提交成功!",
        "sub-title": "请等待工作人员审核通过."
      }, {
        extra: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_button, {
              style: {"width":"120px"},
              key: "buy",
              onClick: _ctx.out
            }, {
              default: _withCtx(() => [
                _createTextVNode("退出")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              style: {"width":"120px"},
              key: "console",
              type: "primary",
              onClick: _ctx.refresh,
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode("刷新")
              ]),
              _: 1
            }, 8, ["onClick", "loading"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}