
import { 
  defineComponent, 
  onMounted, 
  ref, 
  toRef,
  getCurrentInstance, 
  onBeforeUnmount, 
  createVNode,
  reactive,
  watch,
} from 'vue'
import { ParamEstimateFreight, SubmitPayment, AttachmentItem } from '@/interface/print'
import { message as Message } from 'ant-design-vue'
import {
  submitPayment, 
  queryPaymentStatus
} from '@/api/manage'
import { useRouter, useRoute } from 'vue-router'
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

export default defineComponent({
  name: 'ExpressOrderModel',
  props: {
    userName: String,
  },
  components: {
    ReloadOutlined,
    ExclamationCircleOutlined
  },
  emits: ['ok'],
  setup(props, context) {
    const router = useRouter();
    const userName = toRef(props, 'userName')
    const loadingMore = ref<boolean>(false)
    const confirmLoading = ref<boolean>(false)
    const payImg = ref<string>('')
    const modalUpdate = ref<string>('')
    const paymentStatus = ref<boolean>(false)
    const timeCount = ref<number>(0)
    const timer = ref<any>(0)
    const params = reactive({
      total_fee: '1',
      orderId: '',
      freightAmount: 0, // 快递费
      printAmount: 0, // 打印费
      totalAmount: 0, // 总价
    })
    async function resetPayment () {
      confirmLoading.value = true
      const result = await queryPaymentStatus({ outTradeNo: params.orderId })
      if (result == 0) {
        Message.warning('查询失败')
      } else {
        // 支付成功处理逻辑
        payImg.value = ''
        paymentStatus.value = true
      }
      confirmLoading.value = false
    }
    function cancel () {
      if (paymentStatus.value) {
        context.emit('ok')
      }
      loadingMore.value = false
      confirmLoading.value = false
      payImg.value = ''
      paymentStatus.value = false
      // 清楚定时器
      if (timer.value) {
        clearTimeout(timer.value)
        timer.value = 0
        timeCount.value = 0
      }
    }
    const returnPage = () => {
      // 清楚定时器
      if (timer.value) {
        clearTimeout(timer.value)
      }
      router.go(-1)
    }
    // 递归查询支付状态
    async function setTime (orderId: string) {
      timeCount.value++
      const result = await queryPaymentStatus({ outTradeNo: orderId })
      if (result == 0) {
        if (timeCount.value == 60) {
          // 清楚定时器
          if (timer.value) {
            clearTimeout(timer.value)
          }
          if (loadingMore.value) {
            showConfirm()
          }
        } else if (timeCount.value < 60 && timeCount.value > 0) {
          timer.value = 0
          timer.value = setTimeout(() => {
            setTime(orderId)
          }, 1000)
        }
      } else {
        // 支付成功处理逻辑
        payImg.value = ''
        paymentStatus.value = true
        // 清楚定时器
        if (timer.value) {
          clearTimeout(timer.value)
        }
        let secondsToGo = 4;
        const interval = setInterval(() => {
          secondsToGo -= 1;
          modalUpdate.value = `${secondsToGo} 秒后自动关闭页面.`
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          loadingMore.value = false
          context.emit('ok')
        }, secondsToGo * 1000);
      }
    }
    async function onSubmit (data?: any) {
      // 清楚定时器
      if (timer.value) {
        clearTimeout(timer.value)
      }
      loadingMore.value = true
      console.log(data);
      
      timeCount.value = 0
      timer.value = 0
      payImg.value = ''
      paymentStatus.value = false
      params.orderId = data.orderId
      params.freightAmount = data.freightAmount
      payImg.value = data.paymentCode
      // 递归查询支付状态
      setTime(data.orderId)
    }
    const showConfirm = () => {
      Modal.confirm({
        title: () => '订单是否已支付?',
        cancelText: () => '继续支付',
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          console.log('OK');
          context.emit('ok')
        },
        onCancel() {
          // 清楚定时器
          if (timer.value) {
            clearTimeout(timer.value)
          }
          timeCount.value = 0
          setTime(params.orderId)
        },
        class: 'test',
        closable: false
      });
    };
    onBeforeUnmount(()=> {
      // 清楚定时器
      if (timer.value) {
        clearTimeout(timer.value)
      }
    })
    onMounted(() => {

    })
    watch(
      () => loadingMore.value,
      (count, prevCount) => {
        if (!count) {
          // 清楚定时器
          if (timer.value) {
            clearTimeout(timer.value)
          }
        }
      }
    )
    return {
      checked: ref(true),
      paymentStatus,
      loadingMore,
      confirmLoading,
      activeKey: ref('1'),
      paymentActiveKey: ref('1'),
      payImg,
      timeCount,
      resetPayment,
      cancel,
      params,
      returnPage,
      onSubmit,
      userName,
      modalUpdate,
    };
  },
  
});
