
import { computed, defineComponent, reactive, ref, toRefs, onMounted, toRef, PropType, watch } from 'vue';
import type { UnwrapRef } from 'vue';
import { useStore } from 'vuex';
import { TreatmentTab, hrUserFormState, FileItem, FileInfo } from '@/views/HrUser/data';
import { EDIT_USER, parseBankCard } from '@/api/hrUser';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { getToken } from "@/utils/auth"
import {baseURL} from '@/config/index'

export default defineComponent({
  emits: ['toNextTab'],
  props: {
    data: {
      type: Object as PropType<hrUserFormState>,
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    PlusOutlined, LoadingOutlined
  },
  setup(props, context) {
    const datas = toRef(props, 'data')
    const type = toRef(props, 'type')
    const disabled = computed(() => {
      if (type.value === 'detail') {
        return true
      } else {
        return false
      }
    })
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      bankUrl:baseURL+'/sfsj-server/sys/oss/api/uploadRequest',
      url: baseURL+'/test/hr/bill/uploadPictures',
      confirmLoading: false,
      fileList: [],
      bankCardPicLoading: false,
      headers: {
        "X-Access-Token": getToken()
      },
    })
    const formState: UnwrapRef<TreatmentTab> = reactive({

      bankCardNo: null,
      bank: null,
      bankCardPic: null,
      id: null
    });
    const rules = {
      name: [
        { required: true, message: '请输入姓名', trigger: 'blur' },
      ],
    };
    const handleChange = (info: FileInfo, key: string) => {
      
      if (info.file.status === 'uploading') {
        state.bankCardPicLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        formState.bankCardPic = info?.file?.response?.result?.url;
        parseBankCard({
            bankCardImgUrl: formState.bankCardPic
        }).then(res=> {
            formState.bankCardNo = res.bankCardNo || formState.bankCardNo
            formState.bank = res.bank || formState.bank
        }).finally(() => {
            state.bankCardPicLoading = false;
        })
        
      }
      if (info.file.status === 'error') {
        state.bankCardPicLoading = false;
        message.error('上传失败');
      }
    };
    const formRef = ref()
    function toNextTab(key) {
      formRef.value.validate().then(async () => {
        context.emit('toNextTab', key, { ...formState, stage: key })

        // state.confirmLoading = true
        // EDIT_USER({ ...formState, stage: key }).then(res => {
        //   context.emit('toNextTab', key)
        // }).finally(() => {
        //   state.confirmLoading = false
        // })
      }).catch((error) => {
        console.log("error", error);
      })
      
    }
    function out() {
      store.dispatch('Logout').then(res=>{
        message.success('退出成功')
        router.push({
          path: '/hr/login',
        })
      })
    }
    watch(
      () => datas.value,
      () => {
        Object.keys(formState).forEach(item => {
          formState[item] = (datas.value as hrUserFormState)[item]
        })
      }
    )
    return {
      ...toRefs(state),
      formState,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules,
      toNextTab,
      out,
      handleChange,
      disabled
    };
  },
});
