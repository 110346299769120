
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    toRaw
} from 'vue'
import { message } from 'ant-design-vue';
import { FileItem, FileInfo } from '@/views/HrUser/data';
import { getToken, getUserInfo } from "@/utils/auth";
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { UploadOutlined,PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import {baseURL} from '@/config/index'
interface FormState {
    regBook:string;
    evaluateTable:string;
    unit:string;
    highLevel:string;
    recommendForm:string;
    ability:string;
    socialSecurity:string;
    commitLetter:string;
    fileKeep:string;
}
export default defineComponent({
    name:'annex-info',
    description:'证件信息&资料附件',
    components:{
        UploadOutlined,
        PlusOutlined, 
        LoadingOutlined
    },
    setup(props, context){
        const state = reactive({
            headers: {"X-Access-Token": getToken()},
            action:baseURL+'/sys/oss/api/uploadRequest'
        });
        const formState:UnwrapRef<FormState> = reactive({
            regBook:'',
            evaluateTable:'',
            unit:'',
            highLevel:'',
            recommendForm:'',
            ability:'',
            socialSecurity:'',
            commitLetter:'',
            fileKeep:''
        });
        // form表单数据验证
        const rules = {
            regBook:[{ required: true}],
            evaluateTable:[{ required: true}],
            unit:[{ required: true}],
            highLevel:[{ required: true}],
            recommendForm:[{ required: true}],
            ability:[{ required: true}],
            socialSecurity:[{ required: true}],
            commitLetter:[{ required: true}],
            fileKeep:[{ required: true}]
        };
        const formRef = ref();
        //附件上传
        const regBookFileList = ref<FileItem[]>();
        const evaluateFileList = ref<FileItem[]>();
        const unitFileList = ref<FileItem[]>();
        const highLevelFileList = ref<FileItem[]>();
        const recommendFileList = ref<FileItem[]>();
        const abilityFileList = ref<FileItem[]>();
        const socialFileList = ref<FileItem[]>();
        const commitLetterFileList = ref<FileItem[]>();
        const fileKeepFileList = ref<FileItem[]>();
        const handleChange = (info: FileInfo, key: string) => {
            // if (info.file.status === 'uploading') {
            //     state[key] = true;
            //     return;
            // }
            if (info.file.status === 'done') {
                // Get this url from response in real world.
                let resFileList = [...info.fileList]
                let tempFile = resFileList.map(el =>el?.response?.result?.url)
                console.log('===========info',info,key,tempFile);
                formState[key] = tempFile.join(',');
            }
            if (info.file.status === 'error') {
                message.error('上传失败');
            }
        };
        function getBase64(file: File) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
        const previewVisible = ref<boolean>(false);
        const previewImage = ref<string | undefined>('');
        const handlePreview = async (file: FileItem) => {
            if (!file.url && !file.preview) {
                file.preview = (await getBase64(file.originFileObj)) as string;
            }
            previewImage.value = file.url || file.preview;
            previewVisible.value = true;
        }
        const handleCancel = () => {
            previewVisible.value = false;
        }
        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                    console.log('values', formState,toRaw(formState));
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        };
        const resetForm = () => {
            formRef.value.resetFields();
        };
        return {
            ...toRefs(state),
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            formRef,
            formState,
            rules,
            previewVisible,
            previewImage,
            regBookFileList,
            evaluateFileList,
            unitFileList,
            highLevelFileList,
            recommendFileList,
            abilityFileList,
            socialFileList,
            commitLetterFileList,
            fileKeepFileList,
            handleChange,
            handlePreview,
            handleCancel,
            submitForm,
            resetForm
        }
    }
})
