
import { SmileOutlined, DownOutlined, ReloadOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, createVNode } from 'vue';
import { invoiceExpressOrderList, reacquirePayment, invoiceCancelOrder, invoiceCancelRefund } from '@/api/manage'
import { PrintOrderList } from '@/interface/print'
import LogisticsModal from '@/views/contractPrintOrder/modules/LogisticsModal.vue'
import ExpressOrderForm from '@/views/InvoiceExpressOrder/modules/ExpressOrderForm.vue'
import { message as Message, Modal } from 'ant-design-vue'
import { returnQuery } from '@/utils/index'
import { paymentStatus, paymentStatusColor, payType, status, statusColor } from '@/views/contractPrintOrder/modules/statusType'
import { useRouter } from 'vue-router'
import ExpressOrderModel from '@/views/InvoiceExpressOrder/modules/ExpressOrderModel.vue'

const columns = [
  {
    title:'创建时间',
    align:"center",
    dataIndex: 'createTime'
  },
  {
    title:'订单状态',
    align:"center",
    dataIndex: 'status',
    slots: { customRender: 'status' }
  },
  {
    title:'付款状态',
    align:"center",
    dataIndex: 'paymentStatus',
    slots: { customRender: 'paymentStatus' }
  },
  {
    title:'付款时间',
    align:"center",
    dataIndex: 'paymentTime',
    width: 120
  },
  {
    title: '付款方式',
    dataIndex: 'payType',
    align: 'center',
    key: 'payType',
    slots: { customRender: 'payType' },
  },
  {
    title:'物流单号',
    align:"center",
    dataIndex: 'deliverySn'
  },
  {
    title:'快递费',
    align:"center",
    dataIndex: 'freightAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  {
    title:'收件人姓名',
    align:"center",
    dataIndex: 'receiveName'
  },
  {
    title:'收件人电话',
    align:"center",
    dataIndex: 'receivePhone'
  },
  {
    title: '操作',
    align: 'center',
    key: 'action',
    fixed: "right",
    width: 160,
    slots: { customRender: 'action' },
  },
];

export default defineComponent({
  name: 'myInvoiceOrder',
  mixins: [],
  props: {
  },
  components: {
    SmileOutlined,
    DownOutlined,
    SearchOutlined,
    ReloadOutlined,
    LogisticsModal,
    ExpressOrderForm,
    ExpressOrderModel,
  },
  setup(props, context) {
    const userName = ref('')
    const sourceType=ref<any>()
    const pageNo = ref<number>(1);
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    const invoiceId = ref('');
    const plainAddress = ref('');
    const logisticsModal = ref(null);
    const expressOrderForm = ref(null);
    const expressOrderModel = ref(null);
    const pagination = computed(() => ({
      total: total.value,
      current: pageNo.value,
      pageSize: pageSize.value,
    }));
    const queryParam = ref({
      status: '',
      deliverySn: ''
    });
    const searchReset = () => {
      queryParam.value = {
        status: '',
        deliverySn: ''
      }
      getContractPrintOrderList(1)
    }
    const cancelOrder = (record) => {
      if (record.payType != 3 || !!record.deliverySn) {
        Message.error('到付订单同时未发货订单才可取消!')
        return
      }
      loading.value = true
      invoiceCancelOrder({ invoiceExpressId:record.id }).then(res => {
        Message.success('取消成功')
        getContractPrintOrderList()
      }).finally(() => {
        loading.value = false
      })
    }
    const batchPlaceOrders = () => {
      let obj = {
        userName: userName.value,
        invoiceId: invoiceId.value,
        sourceType:sourceType.value
      }
      expressOrderForm.value.edit(obj)
    }
    const toPayment = async (record) => {
      if (record.paymentStatus == 1) {
        Message.error('订单已付款无需重新付款!')
        return
      }
      if (record.payType == 3) {
        Message.error('到付无需付款!')
        return
      }
      loading.value = true
      reacquirePayment({ invoiceExpressId: record.id }).then(res => {
        expressOrderModel.value.onSubmit(res)
      }).finally(() => {
        loading.value = false
      })
      
    }
    const getLogistics = async (record) => {
      if (!record.deliverySn) {
        Message.error('没有物流单号!')
        return
      }
      logisticsModal.value.getLogistics({ mailNos: record.deliverySn })
    }
    const modalFormOk = async (record) => {
      getContractPrintOrderList(1)
    }
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
      getContractPrintOrderList()
    }
    const searchQuery = () => {
      getContractPrintOrderList(1)
    }
    // 退款
    function headleRefund(record) {
      Modal.confirm({
        title: () => '确定取消订单吗?',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => '',
        onOk() {
          loading.value = true
          invoiceCancelRefund({
            id: record.id,
            refundAmount: record.freightAmount || 0
          }).then(res=> {
            Message.success('取消成功！')
            getContractPrintOrderList()
          }).catch(err=> {
            loading.value = false
          })
          
        },
        onCancel() {},
      });
      
    }
    const getContractPrintOrderList = async (page?: number) => {
      if (page) {
        pageNo.value = page
      }
      loading.value = true
      let paramse: PrintOrderList = {
        userName: userName.value,
        pageNo: pageNo.value,
        pageSize: pageSize.value,
        order:'desc',
        column: 'createTime',
        sourceType: sourceType.value,
        ...queryParam.value
      }
      const data = await invoiceExpressOrderList(paramse)
      total.value = data.total
      dataSource.value = data.records
      loading.value = false
    }
    const router = useRouter();
    onMounted(() => {
      
      
      const data: any = router.currentRoute.value.query.verify
      const query: any = returnQuery(data);
      console.log(query,'query');
      if (query.userName && query.invoiceId && query.sourceType) {
        userName.value = query.userName
        invoiceId.value = query.invoiceId
        sourceType.value=query.sourceType
        let obj = {
          userName: userName.value,
          invoiceId: invoiceId.value,
          sourceType: sourceType.value
        }
        expressOrderForm.value.edit(obj)
        getContractPrintOrderList()
      } else {
        Message.warning('请重新跳转')
      }
      
    })
    return {
      sourceType,
      dataSource,
      columns,
      loading,
      userName,
      plainAddress,
      invoiceId,
      pagination,
      batchPlaceOrders,
      paymentStatus, paymentStatusColor, payType, status, statusColor,
      getContractPrintOrderList,
      handleTableChange,
      searchQuery,
      queryParam,
      searchReset,
      logisticsModal,
      getLogistics,
      expressOrderForm,
      modalFormOk,
      toPayment,
      expressOrderModel,
      cancelOrder,
      headleRefund
    };
  },
  
});
