import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af9973f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "bnt"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_CompanyTab = _resolveComponent("CompanyTab")!
  const _component_ResultTab = _resolveComponent("ResultTab")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_history_list = _resolveComponent("history-list")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_spin, { spinning: _ctx.confirmLoading }, {
    default: _withCtx(() => [
      _createVNode(_component_a_radio_group, {
        value: _ctx.mode,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mode) = $event)),
        onChange: _ctx.changeMode,
        style: { marginBottom: '8px' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_radio_button, { value: "left" }, {
            default: _withCtx(() => [
              _createTextVNode("分子公司注册信息")
            ]),
            _: 1
          }),
          _createVNode(_component_a_radio_button, { value: "right" }, {
            default: _withCtx(() => [
              _createTextVNode("审批历史")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "onChange"]),
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.mode,
        "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mode) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "left",
            tab: "left",
            forceRender: true
          }, {
            default: _withCtx(() => [
              (_ctx.activeKey !== 9)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_CompanyTab, {
                      type: _ctx.type,
                      activeKey: _ctx.activeKey,
                      onToNextTab: _ctx.toNextTab,
                      data: _ctx.formState
                    }, null, 8, ["type", "activeKey", "onToNextTab", "data"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeKey === 9)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_ResultTab, { data: _ctx.formState }, null, 8, ["data"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_form, {
                "label-col": _ctx.labelCol,
                "wrapper-col": _ctx.wrapperCol
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      (_ctx.taskId && _ctx.isActiviti == '1')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_a_button, {
                              style: {"width":"40%"},
                              danger: "",
                              onClick: _ctx.back
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("不同意")
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_a_button, {
                              style: {"width":"40%"},
                              type: "primary",
                              onClick: _ctx.pass
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("同意")
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["label-col", "wrapper-col"])
            ]),
            _: 1
          }),
          (_ctx.mode === 'right')
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: "right",
                tab: "right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_history_list, { instanceId: _ctx.instanceId }, null, 8, ["instanceId"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["activeKey"]),
      _createVNode(_component_a_modal, {
        title: _ctx.compObj.title,
        visible: _ctx.compObj.visible,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.compObj.visible) = $event)),
        onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.compObj.visible=false,_ctx.compObj.dealValue='')),
        onOk: _ctx.compHandler,
        maskClosable: false,
        okText: "提交",
        cancelText: "关闭"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_spin, { spinning: _ctx.confirmLoading }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form, {
                ref: "formRef",
                model: _ctx.compObj,
                "label-col": _ctx.labelCol,
                "wrapper-col": _ctx.wrapperCol
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "审批意见",
                    name: "dealValue"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        value: _ctx.compObj.dealValue,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.compObj.dealValue) = $event)),
                        placeholder: "请输入审批意见",
                        rows: 4
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "label-col", "wrapper-col"])
            ]),
            _: 1
          }, 8, ["spinning"])
        ]),
        _: 1
      }, 8, ["title", "visible", "onOk"])
    ]),
    _: 1
  }, 8, ["spinning"]))
}