
import {
    defineComponent,
    ref,
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    computed
} from 'vue'
// import JFormContainer from '@/components/JFormContainer.vue'
interface FormState {
    // nationality:string;

}
export default defineComponent({
    name: 'employee-info',
    description: '个人信息',
    components: {
        // JFormContainer
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default: () => { }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        declareTitle: {
            type: String,
            default: ''
        },
        declareMajor: {
            type: String,
            default: ''
        },
    },
    setup(props, { emit }) {
        // 双向绑定
        const declareTitle = computed({
            get() {
                return props.declareTitle
            },
            set(v) {
                emit('update:declareTitle', v)
            }
        })
        const declareMajor = computed({
            get() {
                return props.declareMajor
            },
            set(v) {
                emit('update:declareMajor', v)
            }
        })
        const loading = toRef(props, 'loading')
        const disableSubmit = toRef(props, 'disabled')
        const baseInfo = toRef(props, 'info')
        // console.log('==========info', baseInfo)
        const state = reactive({})
        const rules = {
            // declareTitle:[{ required: true, message: '请输入申报职称！' }],
            // declareMajor: [{ required: true, message: '请输入申报专业方向！' }],
        }
        const formState: UnwrapRef<FormState> = reactive({
            ...props.info
        })
        // console.log('========formState',formState)
        return {
            ...toRefs(state),
            labelCol: { span: 5 },
            wrapperCol: { span: 20 },
            loading,
            disableSubmit,
            baseInfo,
            rules,
            formState,
            declareTitle,
            declareMajor
        };
    }

})
