
import {
  defineComponent,
  ref,
  toRefs,
  reactive,
  watch,
  UnwrapRef,
  onMounted,
  computed
} from "vue";
import {
  RuleObject,
  ValidateErrorEntity
} from "ant-design-vue/es/form/interface";
import { _area } from "@/components/_util/Area";
import { AcademicTitle, FileInfo } from "@/views/HrUser/data";
import { UploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { getToken } from "@/utils/auth";
import {
  addAcademicTitle,
  editAcademicTitle,
  getJhDictItems,
  getMajorMappingList
} from "@/api/hrUser";
import { baseURL } from "@/config/index";
import { Option } from "@/interface/print";
export default defineComponent({
  name: "AcademicModal",
  props: {},
  components: {
    UploadOutlined
  },
  emits: ["ok"],
  setup(props, context) {
    const formState: UnwrapRef<AcademicTitle> = reactive({
      auditDept: null,
      certificate: null,
      certificateName: null,
      certificateNumber: null,
      city: null,
      hrEntryApplicationId: null,
      id: null,
      isHighest: null,
      issueDate: null,
      major: null,
      province: null,
      title: null
    });
    const state = reactive({
      certifyUrl: baseURL + "/sfsj-server/sys/oss/api/uploadRequest",
      fileList: [],
      isEdit: false,
      professionaltitle: [],
      PositionalTitle: [],
      socialsecurityAddress: null,
      headers: {
        "X-Access-Token": getToken()
      },
      loadingMore: false,
      confirmLoading: false,
      children: [],
      treeData: [],
      replaceFields: {
        children: "children",
        title: "name",
        key: "id",
        value: "id"
      },
      isHighestList: [
        { title: "是", key: "是" },
        { title: "否", key: "否" }
      ]
    });
    const pcaaData = _area;
    const pcaaDatafliter = computed(() => {
      let arr = _area;
      let arr2 = [];
      for (var i = 0; i < arr.length; i++) {
        var item = arr[i];
        if (item.children) {
          // 如果当前元素有“children”属性
          var children = item.children;
          delete item.children; // 删除“children”属性
          for (var j = 0; j < children.length; j++) {
            var child = children[j];
            if (child.children) {
              // 如果当前子元素也有“children”属性
              delete child.children; // 删除它
            }
          }
          item.children = children; // 将第二层childern赋值给item.children
        }
        arr2[i] = item;
      }

      return arr2;
    });
    const rules = {
      major: [{ required: true, message: "请填写专业" }],
      certificateName: [{ required: true, message: "请填写证书扫描件名称" }],
      title: [{ required: true, message: "请选择职称级别" }],
      certificate: [{ required: true, message: "请上传职称证书" }],
      
    };
    const handleChange = (info: FileInfo, key: string) => {
      if (info.file.status === "uploading") {
        state.confirmLoading = true;
        return;
      }
      if (info.file.status === "done") {
        const imgUrl = info?.file?.response?.result?.url;
        formState[key] = imgUrl;
        state.confirmLoading = false;
      }
      if (info.file.status === "error") {
        state.confirmLoading = false;
        message.error("上传失败");
      }
    };
    const add = (id: string) => {
      if (!id) {
        message.warning("没有人员id");
        return;
      }
      Object.keys(formState).forEach(item => {
        formState[item] = null;
      });
      state.loadingMore = true;
      formState.hrEntryApplicationId = id;
       state.socialsecurityAddress = null;
    };
    const edit = (record: AcademicTitle) => {
      state.loadingMore = true;
      state.isEdit = true;
      Object.keys(record).forEach(item => {
        formState[item] = record[item];
      });

      if (formState.province && formState.city) {
        state.socialsecurityAddress = [
          formState.province || "",
          formState.city || ""
        ];
      } else {
        state.socialsecurityAddress = null;
      }
    };
    const onChange = (e: any) => {
      formState.province = e[0];
      formState.city = e[1];
    };
    const filter = (inputValue: string, path: Option[]) => {
      return path.some(
        option =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    };
    const cancel = () => {
      // context.emit('ok')
    };
    function onOk() {
      state.loadingMore = false;
      context.emit("ok");
    }
    const formRef = ref();
    function onSubmit() {
      formRef.value
        .validate()
        .then(async () => {
          state.confirmLoading = true;
          if (!formState.id) {
            addAcademicTitle(formState)
              .then(res => {
                onOk();
              })
              .finally(() => {
                state.confirmLoading = false;
              });
          } else {
            editAcademicTitle(formState)
              .then(res => {
                onOk();
              })
              .finally(() => {
                state.confirmLoading = false;
              });
          }
        })
        .catch((error: ValidateErrorEntity<AcademicTitle>) => {
          console.log("error", error);
          message.warning("信息未填写完整");
        });
    }
    function handleChangeProvince(value, option) {
      formState.city = null;
      for (let index = 0; index < pcaaData.length; index++) {
        const element = pcaaData[index];
        if (element.label === value || element.value === value) {
          state.children = element.children;
          break;
        }
      }
    }
    function getMajorList() {
      getMajorMappingList({}).then(async res => {
        console.log(res, "789");
        if (res) {
          let treeData = res;
          treeData.forEach(e => {
            e.disabled = true;
          });
          state.treeData = treeData;
        }
      });
    }
    function handleTreeChange(e, row, name) {
      console.log(e, row, name);
      formState[name] = row[0];
    }
    function selectBut(e, str) {
      if (formState[str] == e) {
        formState[str] = "";
      } else {
        formState[str] = e;
      }
    }
    onMounted(() => {
      getMajorList();
      getJhDictItems({ code: "title" }).then(res => {
        const { title } = res;
        state.PositionalTitle = title;
      });
      // getJhDictItems({ dictCode: 'professionaltitle' }).then(res=> {
      //   state.professionaltitle = res
      // })
    });
    return {
      ...toRefs(state),
      formRef,
      add,
      cancel,
      rules,
      formState,
      onSubmit,
      onOk,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      handleChange,
      handleChangeProvince,
      edit,
      pcaaData,
      getMajorList,
      handleTreeChange,
      pcaaDatafliter,
      onChange,
      filter,
      selectBut
    };
  }
});
