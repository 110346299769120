
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from '@ant-design/icons-vue';
import { queryTitleByEmployeeId } from '@/api/largeScreenApi'
import { useRouter } from 'vue-router'
import { 
  defineComponent, 
  ref, 
  toRefs,
  reactive,
  onMounted,
} from 'vue'
const projectColumns = [
{
  title: '项目名称',
  align:"center",
  dataIndex: 'projectInfoName'
},
{
  title: '项目负责人名称',
  align:"center",
  dataIndex: 'projectManagerName'
},
{
  title: '设计人姓名',
  align:"center",
  dataIndex: 'designerName'
},
{
  title: '校核人名称',
  align:"center",
  dataIndex: 'collactorName'
},
{
  title: '审核人名称',
  align:"center",
  dataIndex: 'auditorName'
},
{
  title: '审定人名称',
  align:"center",
  dataIndex: 'approverName'
},
{
  title: '签字审定人名称',
  align:"center",
  dataIndex: 'qzApproverName'
},
{
  title: '签字审核人名称',
  align:"center",
  dataIndex: 'qzAuditorName'
},
{
  title: '签字校对人名称',
  align:"center",
  dataIndex: 'qzCollactorName'
},
{
  title: '签字设计人名称',
  align:"center",
  dataIndex: 'qzDesignerName'
},
{
  title: '签字专业负责人名称',
  align:"center",
  dataIndex: 'qzMajorPrincipleName',
},
{
  title: '签字项目负责人名称',
  align:"center",
  dataIndex: 'qzProjectManagerName',
},
];
const paperDateColumns = [
{
  title: '论文名称',
  align:"center",
  dataIndex: 'paperName'
},
{
  title: '论文文件',
  align:"center",
  dataIndex: 'paperUrl',
  slots: { customRender: 'paperUrl' }
},
{
  title: '上传时间',
  align:"center",
  dataIndex: 'updateTime'
},
];
const monographDateColumns = [
{
  title: '专著名称',
  align:"center",
  dataIndex: 'paperName'
},
{
  title: '专著文件',
  align:"center",
  dataIndex: 'paperUrl',
  slots: { customRender: 'paperUrl' }
},
{
  title: '上传时间',
  align:"center",
  dataIndex: 'updateTime',
},
];
const contractsColumns = [
  {
    title: '合同编号',
    align:"center",
    fixed: true,
    dataIndex: 'serialNumber'
  },
  {
    title: '合同名称',
    align:"center",
    fixed: true,
    dataIndex: 'name'
  },
  {
    title: '合同编号',
    align:"center",
    fixed: true,
    dataIndex: 'serialNumber'
  },
  {
    title: '甲方',
    align:"center",
    fixed: true,
    dataIndex: 'partyAname'
  },
  {
    title: '合同金额',
    align:"center",
    fixed: true,
    dataIndex: 'contractValue',
    customRender: function({ text }) {
      return text + '元'
    }
  },
  {
    title: '主设计师',
    align:"center",
    fixed: true,
    dataIndex: 'mainDesignUserName'
  },
  {
    title: '合同结算金额',
    align:"center",
    fixed: true,
    dataIndex: 'contractSettlementValue',
    customRender: function({ text }) {
      return text + '元'
    }
  },
];

export default defineComponent({
  name: 'Personage',
  props: {
    
  },
  components: {
    UserOutlined,
    SolutionOutlined,
    LoadingOutlined,
    SmileOutlined,
  },
  setup(props, context) {
    const router = useRouter();
    const state = reactive({
      confirmLoading: false,
      loadingMore: false,
      indexBottomTab: '1',
      loading: false,
      title: '人员详情',
      cancel: () => {},
      changeActiveKey: () => {},
      projectData: [],
      paperDate: [],
      contractsData: [],
      monographDate: [],
      projectColumns: projectColumns,
      paperDateColumns: paperDateColumns,
      contractsColumns: contractsColumns,
      monographDateColumns: monographDateColumns,
      projectIpagination:{
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条"
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      projectTableChange: (pagination) => {
        state.projectIpagination.current = pagination.current
        state.projectIpagination.pageSize = pagination.pageSize
      },
      formState: {
        GraduationYears: null,
        certificatesDate: null,
        title: ''
      },
      steps: [
        { title: "助理工程师", current: 0, description: '' },
        { title: "工程师", current: 1, description: '' },
        { title: "高级工程师", current: 2, description: '' },
      ],
      current: 1
    })
    function open(record: { employeeId: string }) {
      state.loadingMore = true
      // state.formState = Object.assign(record)
      state.loading = true
      queryTitleByEmployeeId({
        employeeId: record.employeeId
      }).then(res => {
        state.formState = Object.assign(res.essentialInformation)
        state.formState = {
          ...state.formState,
          ...res
        }
        state.projectData = res?.achievementDate?.audit
        state.contractsData = res?.achievementDate?.contracts
        state.paperDate = res?.paperDate
        state.monographDate = res?.monographDate
        for (let index = 0; index < state.steps.length; index++) {
          const element = state.steps[index];
          if (element.title === state.formState.title) {
            state.current = element.current + 1;
            if (state.current < state.steps.length) {
              state.steps[state.current].description = '毕业年限：' + (state.formState.GraduationYears || '不满足') + '证件年限：' + (state.formState.certificatesDate || '不满足')
              // {{ formState.certificatesDate ? '证件年限：' + formState.certificatesDate : '请填写证件信息！' }}
            }
            break;
          }
        }
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(() => {
      const data: any = router.currentRoute.value.query
      open(data)
    })
    return {
      ...toRefs(state),
      open
    };
  },
});
