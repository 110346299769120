
import '@/assets/less/TableExpand.less'
import { 
  SmileOutlined, 
  DownOutlined, 
  PrinterOutlined, 
  SearchOutlined, 
  ReloadOutlined, 
  ExclamationCircleOutlined, 
  CheckCircleOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, toRef, reactive, createVNode } from 'vue';
import { companyInvoiceList, companyInvoiceAppoint } from '@/api/manage'

import { message as Message, Modal } from 'ant-design-vue'
import { pastTime } from '@/utils/index'
import CompanyInvoiceModal from '@/views/InvoiceExpressOrder/modules/CompanyInvoiceModal.vue'
import PrintAppoint from '@/views/InvoiceExpressOrder/modules/PrintAppoint.vue'
import { useRouter } from 'vue-router'
const columns = [
  {
    title: '#',
    dataIndex: '',
    key:'rowIndex',
    width:60,
    align:"center",
    customRender:function ({text,record,index}) {
      return parseInt(index)+1;
    }
  },
  {
    title: '创建时间',
    dataIndex: 'creatDate',
    key: 'creatDate',
    resizable: true,
    width: 120,
  },
  {
    title:'发票',
    align:"center",
    dataIndex: 'imgUrl',
    resizable: true,
    slots: { customRender: 'imgUrl' }
  },
  {
    title:'发票编号',
    align:"center",
    resizable: true,
    dataIndex: 'invoiceCode',
  },
  {
    title:'发票状态',
    align:"center",
    dataIndex: 'invoiceState',
    resizable: true,
    customRender:function ({text}) {
      // 0:未使用 1:已使用
      const invoiceState = {
        0: '未使用',
        1: '已使用',
      }
      return invoiceState[text]
    }
  },
  {
    title:'发票类型',
    align:"center",
    dataIndex: 'invoiceType',
    customRender:function ({text}) {
      // SpecialVat-专票 VAT-普票
      const invoiceType = {
        'SpecialVat': '专票',
        'VAT': '普票',
      }
      return invoiceType[text]
    }
  },
  {
    title: '发票名称',
    dataIndex: 'invoiceName',
    align: 'center',
  },
  {
    title: '发票日期',
    dataIndex: 'invoiceDate',
    align: 'center',
  },
  
  {
    title: '备注',
    dataIndex: 'remarks',
    align: 'center',
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'action',
    align:"center",
    fixed:"right",
    width:160,
    slots: { customRender: 'action' }
  }
];

export default defineComponent({
  name: 'InvoiceExpressOrder',
  mixins: [],
  props: {
  },
  setup(props, context) {
    const router = useRouter();
    const pageNo = ref<number>(1);
    const urlTime = ref<number>(0);
    const modalForm = ref(null);
    const printAppoint = ref(null);
    const state = reactive({
      queryParam: {
        invoiceCode: '',
        invoiceState: '',
        invoiceType: '',
      },
    });
    
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    /* table选中keys*/
    const selectedRowKey = ref<any[]>([]);
    /* table选中records*/ 
    const selectionRow = ref<any[]>([]);
    const pagination = computed(() => ({
      current: pageNo.value,
      pageSize: pageSize.value,
      pageSizeOptions: ['10', '20', '30'],
      showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: total.value
    }));
    const to = (path) => {
      if (path) {
        router.push({
          path: path
        })
      } else {
        router.go(-1)
      }
      
    }
    // 翻页
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
      getContractPrintOrderList()
    }
    // 重置搜索
    const searchReset = () => {
      state.queryParam = {
        invoiceCode: '',
        invoiceState: '',
        invoiceType: '',
        
      }
      getContractPrintOrderList(1)
    }
    // 清楚勾选
    const onClearSelected = () => {
      selectedRowKey.value = []
      selectionRow.value = []
    }
    // 搜索
    const searchQuery = () => {
      getContractPrintOrderList(1)
    }
    // 详情
    const handleEdit = (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      modalForm.value.edit(record);
      modalForm.value.disableSubmit = false;
    }
    
    // 提交顺丰
    const batchSave = () => {
      modalForm.value.add();
    }
    const handerUpdate = (record) => {
      printAppoint.value.edit(record);
    }
    const handerPrintAppoint = (record) => {
      
      loading.value = true
      companyInvoiceAppoint({ code: record.invoiceCode, type: record.invoiceType }).then(res=> {
        Message.success('添加成功')
        getContractPrintOrderList()
        onClearSelected();
      }).catch(err => {
        loading.value = false
      })
    }
    // 表单回调
    const modalFormOk = () => {
      getContractPrintOrderList()
      onClearSelected();
    }
    // 选择列表
    function onSelectChange(selectedRowKeys, selectionRows) {
      selectedRowKey.value = selectedRowKeys;
      selectionRow.value = selectionRows;
    }
    // 列表获取
    const getContractPrintOrderList = async (page?: number) => {
      if (page) {
        pageNo.value = page
      }
      loading.value = true
      let paramse = {
        pageNo: pageNo.value,
        pageSize: pageSize.value,
        ...state.queryParam,
      }
      
      // const data = await invoiceExpressOrderList(paramse)
      
      companyInvoiceList(paramse).then(res=> {
        total.value = res.total
        dataSource.value = res.records
        loading.value = false
      }).catch(err=> {
        console.log('invoiceExpressOrderList--------',err);
        Message.error('请求失败')
        loading.value = false
      })
    }
    
    onMounted(() => {
    //   const data: any = router.currentRoute.value.query.verify
    //   const query: any = returnQuery(data);
      
    //   if (!query.time) {
    //     Message.warning('链接已失效，请重新进入')
    //   } else {
    //     urlTime.value = Number(query.time)
        getContractPrintOrderList()
    //   }
    })
    return {
      dataSource,
      columns,
      loading,
      pagination,
      getContractPrintOrderList,
      handleTableChange,
      onClearSelected,
      onSelectChange,
      selectedRowKey,
      selectionRow,
      handleEdit,
      batchSave,
      modalFormOk,
      state,
      searchQuery,
      searchReset,
      modalForm,
      printAppoint,
      handerPrintAppoint,
      to,
      handleResizeColumn: (w, col) => {
        col.width = w;
      },
      handerUpdate,
    };
  },
  components: {
    CompanyInvoiceModal,
    PrintAppoint,
    PrinterOutlined,
    SearchOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    DownOutlined,
  },
});
