
import { 
  defineComponent,
  ref, 
  toRefs,
  reactive,
  watch,
  UnwrapRef
} from 'vue'
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { _area } from '@/components/_util/Area'
import { WorkHistory, FileInfo } from '@/views/HrUser/data'
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue'
import { getToken } from "@/utils/auth"
import { addWorkHistory, editWorkHistory } from "@/api/hrUser"

export default defineComponent({
  name: 'WorkHistoryModal',
  props: {
  },
  components: {
    UploadOutlined
  },
  emits: ['ok'],
  setup(props, context) {
    const formState: UnwrapRef<WorkHistory> = reactive({
      "companyName": null,
      "deptAndPost": null,
      "description": null,
      "hrEntryApplicationId": null,
      "id": null,
      "joinCompanyDate": '',
      "leaveCompanyDate": '',
    })
    const state = reactive({
      loadingMore: false,
      confirmLoading: false,
       isEdit:false,
        CompanyDate:[],
    })

    const rules = {
    
      leaveCompanyDate: [{ required: true, message: '选择任职时间' },],
    };
    const add = (id: string) => {
      if (!id) {
        message.warning('没有人员id')
        return
      }
      Object.keys(formState).forEach(item=> {
        formState[item] = null
      })
      state.loadingMore = true
       state.CompanyDate=[]
      formState.hrEntryApplicationId = id
    }
    const edit = (record: WorkHistory) => {
      state.loadingMore = true
      state.isEdit=true
      Object.keys(record).forEach(item=> {
        formState[item] = record[item]
      })
       if( formState.joinCompanyDate &&  formState.leaveCompanyDate){
          state.CompanyDate=[formState.joinCompanyDate,formState.leaveCompanyDate]
        }else{
          state.CompanyDate=[]
        }
    }
    const cancel = () => {
      // context.emit('ok')
    }
    function onOk() {
      state.loadingMore = false
      context.emit('ok')
    }
    const formRef = ref()
    function onSubmit() {
      formRef.value.validate().then(async () => {
        state.confirmLoading = true
        if (!formState.id) {
          addWorkHistory(formState).then(res => {
            onOk()
          }).finally(() => {
            state.confirmLoading = false
          })
        } else {
          editWorkHistory(formState).then(res => {
            onOk()
          }).finally(() => {
            state.confirmLoading = false
          })
        }
        
      }).catch((error: ValidateErrorEntity<WorkHistory>) => {
        console.log("error", error);
        message.warning('信息未填写完整')
      });
      
    }
        function pickerLdchange(e){
           console.log(e,'eeee');
        formState.joinCompanyDate=e[0] || null 
        formState.leaveCompanyDate=e[1] || null 
    }

    return {
      ...toRefs(state),
      formRef,
      add,
      cancel,
      rules,
      formState,
      onSubmit,
      onOk,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      edit,
      pickerLdchange
    };
  },
  
});
