<template>
    <div>
        <!-- 查询区域 -->
        <div class="table-page-search-wrapper">
            <!-- <a-form v-on:keyup.enter="searchQuery">
            <a-row :gutter="24">
                <a-col :xl="6" :lg="7" :md="8" :sm="24">
                <a-form-item label="专业">
                    <a-input placeholder="请输入专业" v-model:value="queryParam.name"></a-input>
                </a-form-item>
                </a-col>
                <a-col :xl="6" :lg="7" :md="8" :sm="24">
                <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                    <a-button type="primary" @click="searchQuery">
                    <template #icon><SearchOutlined /></template>
                    查询
                    </a-button>
                    <a-button type="primary" @click="searchReset" style="margin-left: 8px">
                    <template #icon><ReloadOutlined /></template>
                    重置
                    </a-button>
                </span>
                </a-col>  
            </a-row>
            </a-form> -->
        </div>
        <a-table
            :dataSource="dataSource" 
            size="default" 
            bordered
            :scroll="{x:true}"
            :row-key="({ index }) => index"
            :columns="columns" 
            :loading="loading"
            :pagination="ipagination" 
            @change="tableChange">
            <template #paperUrl="{ text }">
                <a :href="text">{{ text }}</a>
            </template>
        </a-table>
    </div>
</template>

<script>
const columns = [
    {
        title: '专著名称',
        align:"center",
        dataIndex: 'paperName'
    },
    {
        title: '专著文件',
        align:"center",
        dataIndex: 'paperUrl',
        slots: { customRender: 'paperUrl' }
    },
    {
        title: '上传时间',
        align:"center",
        dataIndex: 'updateTime',
    }
]

import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    onMounted,
    nextTick
} from 'vue'
import { getRelevant } from '@/api/largeScreenApi'
export default defineComponent({
    name:'monograph',
    description:'专著',
    props:{
        id:{
            type:String,
            default:''
        }
    },
    setup(props, context){
        const id = toRef(props, 'id')
        const state = reactive({
            columns:columns,
            dataSource:[],
            ipagination:{
                current: 1,
                pageSize: 5,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0,
            },
            loading:false,
            tableChange: (pagination) => {
                console.log('========翻页',pagination)
                state.ipagination.current = pagination.current
                state.ipagination.pageSize = pagination.pageSize
                state.loading = true;
                initData(id.value)
            },
        })
        const initData = (id) => {
            getRelevant({
                id:id,
                type:'2',
                pageNo:state.ipagination.current,
                pageSize:state.ipagination.pageSize
            }).then(res => {
                state.dataSource = res.records
                state.ipagination.total = res.total;
            }).finally(() => {
                state.loading = false
            })
        }
        onMounted(() => {
            nextTick(() => {
                console.log('======专著初始化',id.value)
                state.loading = true;
                initData(id.value)
            })
        })
        return {
            ...toRefs(state)
        };
    }
})
</script>

<style lang="less" scoped>
@import '../index.less';
</style>