
import {
  defineComponent,
  ref,
  toRefs,
  reactive,
  watch,
  UnwrapRef,
  onMounted
} from "vue";
import {
  RuleObject,
  ValidateErrorEntity
} from "ant-design-vue/es/form/interface";
import { _area } from "@/components/_util/Area";
import { Qualification, FileInfo } from "@/views/HrUser/data";
import { UploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { getToken } from "@/utils/auth";
import {
  addQualification,
  editQualification,
  getPracticingList as getPracticing
} from "@/api/hrUser";
import { baseURL } from "@/config/index";


export default defineComponent({
  name: "QualificationModal",
  props: {},
  components: {
    UploadOutlined
  },
  emits: ["ok"],
  setup(props, context) {
    const formState: UnwrapRef<Qualification> = reactive({
      firstMajor: null,
      firstMajorName: null,
      hrEntryApplicationId: null,
      id: null,
      qualificationAttachment: null,
      qualificationAttachmentName: null,
      qualificationCode: null,
      qualificationIssueDate: null,
      qualificationName: null
    });
    const state = reactive({
      qualifyUrl: baseURL + "/sfsj-server/sys/oss/api/uploadRequest",
      fileList: [],
      firstMajor: [],
      isEdit: false,
      headers: {
        "X-Access-Token": getToken()
      },
      loadingMore: false,
      confirmLoading: false,
      treeData: [],
      replaceFields: {
        children: "children",
        title: "name",
        key: "id",
        value: "id"
      },
      major: [],
      list: []
    });

    const rules = {
      qualificationAttachment: [
        { required: true, message: "请上传执业资格证书" }
      ],
      firstMajor: [{ required: true, message: "请填写专业" }],
      qualificationName: [{ required: true, message: "请填写执业资格名称" }],
      // firstMajorName: [{ required: true, message: "请填写专业名称" }],
      qualificationIssueDate: [{ required: true, message: "请选择发证日期" }]
    };
    const handleChange = (info: FileInfo, key: string) => {
      if (info.file.status === "uploading") {
        state.confirmLoading = true;
        return;
      }
      if (info.file.status === "done") {
        const imgUrl = info?.file?.response?.result?.url;
        formState[key] = imgUrl;
        state.confirmLoading = false;
      }
      if (info.file.status === "error") {
        state.confirmLoading = false;
        message.error("上传失败");
      }
    };
    const add = (id: string) => {
      if (!id) {
        message.warning("没有人员id");
        return;
      }
      getMajorList();
      Object.keys(formState).forEach(item => {
        formState[item] = null;
      });
      state.loadingMore = true;
      state.firstMajor = [];
      formState.hrEntryApplicationId = id;
    };
    const edit = (record: Qualification) => {
      state.loadingMore = true;
      state.isEdit = true;
      getMajorList();
      Object.keys(record).forEach(item => {
        formState[item] = record[item];
      });
      state.firstMajor = formState.firstMajor
        ? formState.firstMajor.split(",")
        : [];
    };
    const cancel = () => {
      // context.emit('ok')
    };
    function onOk() {
      state.loadingMore = false;
      context.emit("ok");
    }
    const formRef = ref();
    function onSubmit() {
      formRef.value
        .validate()
        .then(async () => {
          state.confirmLoading = true;
          if (!formState.id) {
            addQualification(formState)
              .then(res => {
                onOk();
              })
              .finally(() => {
                state.confirmLoading = false;
              });
          } else {
            editQualification(formState)
              .then(res => {
                onOk();
              })
              .finally(() => {
                state.confirmLoading = false;
              });
          }
        })
        .catch((error: ValidateErrorEntity<Qualification>) => {
          console.log("error", error);
          message.warning("信息未填写完整");
        });
    }
    // 递归遍历
    function intetorFun(data, level, list) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        if (node.children) {
          node.disabled = true;
          if (level === 3) {
            node.disabled = false;
            // 如果是第3层，则记录 children
            list.push(...node.children);
            // 删除该节点的 children 属性
            delete node.children;
          } else {
            // 递归处理子节点
            intetorFun(node.children, level + 1, list);
          }
        } else {
          if (level == 4) {
            // 如果节点没有 children，且是第四层，则删除该节点
            data.splice(i--, 1);
          }
        }
      }
    }
    function getMajorList() {
      getPracticing({}).then(async res => {
        console.log(res, "789");

        if (res) {
          let treeData = JSON.parse(JSON.stringify(res || []));
          let list = [];
          await intetorFun(treeData, 1, list);
          state.treeData = treeData;
          state.list = list;
          if (formState.qualificationName) {
            let major = state.list.filter(
              i => i.parentId == formState.qualificationName
            );
            state.major = major;
          } else {
            state.major = list;
          }
        }
      });
    }
    function handlemajor(e, label) {
      console.log(1);
      // getMajorList()
      // this.model.qualificationNameName = label[0]
    }
    function handleTreeChange(e, row, name) {
      console.log(e, row, name);
      formState[name] = row[0];
      console.log(e, "eeee");

      let major = state.list.filter(i => i.parentId == e);
      state.major = major;
      formState.firstMajor = null;
      state.firstMajor = [];
    }
    function selectChange(e) {
      let firstMajorName = [];
      console.log(e, "eeeeeeee");
      state.firstMajor = e || [];
      e.forEach(i => {
        let name = state.list.filter(j => j.id == i);
        firstMajorName.push(name[0].name);
      });
      formState.firstMajorName = firstMajorName.join(",");
      formState.firstMajor = e.join(",");
    }
    onMounted(() => {});
    return {
      ...toRefs(state),
      formRef,
      add,
      cancel,
      rules,
      formState,
      onSubmit,
      onOk,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      handleChange,
      edit,
      getMajorList,
      intetorFun,
      handlemajor,
      handleTreeChange,
      selectChange
    };
  }
});
