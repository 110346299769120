
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
} from 'vue'
import BaseInfo from './BaseInfo.vue'
import WorkUnit from './WorkUnit.vue'
import PostInfo from './Post-Info.vue'
import EducationExperience from './Education-Experience.vue'
import AnnexInfo from './Annex-Info.vue'
import ContinueEducate from './Continue-Educate.vue'
import JFormContainer from '@/components/JFormContainer.vue'
export default defineComponent({
    name:'personal-info',
    description:'个人信息',
    components:{
        BaseInfo,
        WorkUnit,
        PostInfo,
        EducationExperience,
        AnnexInfo,
        ContinueEducate,
        JFormContainer
    },
    props:{
        loading:{
            type:Boolean,
            default:false
        },
        dataSource:{
            type:Object,
            default:() =>{}
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    setup(props, context){
        const loading = toRef(props, 'loading')
        const disableSubmit = toRef(props, 'disabled')
        const baseInfo = toRef(props, 'dataSource')
        const state = reactive({})
        return {
            ...toRefs(state),
            loading,
            disableSubmit,
            baseInfo
        };
    }
})
