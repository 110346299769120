
import { 
  SmileOutlined, 
  DownOutlined, 
  PrinterOutlined, 
  SearchOutlined, 
  ReloadOutlined, 
  ExclamationCircleOutlined, 
  CheckCircleOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, toRef, reactive, createVNode } from 'vue';
import { invoiceExpressOrderList, invoicePlaceOrders, printExpressImgInvoice, invoiceCancelRefund } from '@/api/manage'
import { 
  deliveryStatusColor, 
  deliveryStatus, 
  paymentStatus, 
  paymentStatusColor, 
  payType,
  status, statusColor 
} from '@/views/contractPrintOrder/modules/statusType'
import { message as Message, Modal } from 'ant-design-vue'
import { PrintOrderList } from '@/interface/print'
import { returnQuery, pastTime } from '@/utils/index'
import ContractPrintOrderModal from '@/views/contractPrintOrder/modules/ContractPrintOrderModal.vue'
import LogisticsModal from '@/views/contractPrintOrder/modules/LogisticsModal.vue'
import { useRouter } from 'vue-router'
const columns = [
  {
    title: '#',
    dataIndex: '',
    key:'rowIndex',
    width:60,
    align:"center",
    customRender:function ({text,record,index}) {
      return parseInt(index)+1;
    }
  },
  {
    title:'用户',
    align:"center",
    width: 80,
    dataIndex: 'userName'
  },
  {
    title:'创建时间',
    align:"center",
    width:120,
    dataIndex: 'createTime'
  },
  {
    title:'订单状态',
    align:"center",
    dataIndex: 'status',
    slots: { customRender: 'status' }
  },
  {
    title:'付款状态',
    align:"center",
    width:100,
    dataIndex: 'paymentStatus',
    slots: { customRender: 'paymentStatus' }
  },
  {
    title:'付款时间',
    align:"center",
    width:120,
    dataIndex: 'paymentTime'
  },
  {
    title: '付款方式',
    dataIndex: 'payType',
    align: 'center',
    width:100,
    key: 'payType',
    slots: { customRender: 'payType' },
  },
  {
    title:'物流状态',
    align:"center",
    width:100,
    dataIndex: 'deliveryStatus',
    slots: { customRender: 'deliveryStatus' }
  },
  {
    title:'物流单号',
    align:"center",
    width:100,
    dataIndex: 'deliverySn'
  },
  {
    title:'快递费',
    align:"center",
    width:100,
    dataIndex: 'freightAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  {
    title:'收件人姓名',
    align:"center",
    width:100,
    dataIndex: 'receiveName'
  },
  {
    title:'收件人电话',
    align:"center",
    width:120,
    dataIndex: 'receivePhone'
  },
  {
    title:'收件人地址',
    align:"center",
    width:180,
    dataIndex: 'receiveProvince',
    customRender:function ({text, record}) {
      return `${record.receiveProvince || ''}${record.receiveCity || ''}${record.receiveCounty || ''}${record.receiveAddress || ''}`
    }
  },
  // {
  //   title:'完成时间',
  //   align:"center",
  //   dataIndex: 'createTime',
  //   customRender:function (text) {
  //     return !text?"":(text.length>10?text.substr(0,10):text)
  //   }
  // },
  {
    title: '操作',
    dataIndex: 'action',
    align:"center",
    fixed:"right",
    width:160,
    slots: { customRender: 'action' }
  }
];

export default defineComponent({
  name: 'InvoiceExpressOrder',
  mixins: [],
  props: {
  },
  setup(props, context) {
    const router = useRouter();
    const sourceType=ref<any>()
    const userName = ref('')
    const pageNo = ref<number>(1);
    const urlTime = ref<number>(0);
    const modalForm = ref(null);
    const logisticsModal = ref(null);
    const state = reactive({
      queryParam: {
        userName: '',
        status: '',
        receivePhone: '',
        receiveName: '',
        deliverySn: '',
        deliveryStatus: '',
        paymentStatus: '',
        payType: ''
      },
    });
    
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    /* table选中keys*/
    const selectedRowKey = ref<any[]>([]);
    /* table选中records*/ 
    const selectionRow = ref<any[]>([]);
    const pagination = computed(() => ({
      current: pageNo.value,
      pageSize: pageSize.value,
      pageSizeOptions: ['10', '20', '30'],
      showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: total.value
    }));
    const to = () => {
      router.push({
        path: '/companyInvoice',

      })
    }
    // 翻页
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
      getContractPrintOrderList()
    }
    // 重置搜索
    const searchReset = () => {
      state.queryParam = {
        userName: '',
        paymentStatus: '',
        status: '',
        receivePhone: '',
        receiveName: '',
        deliverySn: '',
        deliveryStatus: '',
        payType: ''
        
      }
      getContractPrintOrderList(1)
    }
    // 清楚勾选
    const onClearSelected = () => {
      selectedRowKey.value = []
      selectionRow.value = []
    }
    // 搜索
    const searchQuery = () => {
      getContractPrintOrderList(1)
    }
    // 详情
    const handleEdit = (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      modalForm.value.edit(record);
      modalForm.value.disableSubmit = false;
    }
    // 提交顺丰
    const placeOrders = (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      if (!record.payType) {
        Message.error('没有快递信息!')
        return
      }
      if (record.status == 1 || record.deliveryStatus != 0) {
        Message.error('订单已关闭或者已发货不能提交物流!')
        return
      }
      loading.value = true
        let obj={
              invoiceExpressId:record.id,
              sourceType:sourceType.value
            }
      invoicePlaceOrders(obj).then(res => {
        Message.success('提交成功')
        getContractPrintOrderList()
        onClearSelected();
      }).catch(err=>{
        loading.value = false
      })
      
    }
    // 重新打印面单
    const onPrintExpressImg = async (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      await printExpressImgInvoice({ id: record.id })
      Message.success('提交成功')
      getContractPrintOrderList()
    }
    // 获取物流
    const getLogistics = async (record) => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      if (!record.deliverySn) {
        Message.error('没有物流单号!')
        return
      }
      logisticsModal.value.getLogistics({ mailNos: record.deliverySn })
    }
    // 提交顺丰
    const batchPlaceOrders = () => {
      if (new Date().getTime() - urlTime.value > pastTime()) {
        Message.error('链接已失效，请重新从合同评审跳转到此页面!')
        return
      }
      if (selectedRowKey.value.length <= 0) {
        Message.warning('请选择一条记录！');
        return;
      } else {
        var invoiceExpressId = "";
        for (var a = 0; a < selectedRowKey.value.length; a++) {
          invoiceExpressId += selectedRowKey[a] + ",";
        }
        Modal.confirm({
          title: () => '确定提交选中的订单吗?',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => '',
          onOk() {
            loading.value = true
            let obj={
              invoiceExpressId:invoiceExpressId,
              sourceType:sourceType.value
            }
            invoicePlaceOrders(obj).then(res=>{
              Message.success('提交成功')
              getContractPrintOrderList()
              onClearSelected();
            }).catch(err=>{
              loading.value = false
            })
            
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel() {},
        });
        
      }
    }
    // 表单回调
    const modalFormOk = () => {
      getContractPrintOrderList()
      onClearSelected();
    }
    // 选择列表
    function onSelectChange(selectedRowKeys, selectionRows) {
      selectedRowKey.value = selectedRowKeys;
      selectionRow.value = selectionRows;
    }
    // 退款
    function headleRefund(record) {
      Modal.confirm({
        title: () => '确定取消订单吗?',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => '',
        onOk() {
          loading.value = true
          invoiceCancelRefund({
            id: record.id,
            refundAmount: record.freightAmount || 0
          }).then(res=> {
            Message.success('取消成功！')
            getContractPrintOrderList()
            onClearSelected();
          }).catch(err=> {
            loading.value = false
          })
          
        },
        onCancel() {},
      });
      
    }
    // 列表获取
    const getContractPrintOrderList = async (page?: number) => {
      if (page) {
        pageNo.value = page
      }
      loading.value = true
      let paramse: PrintOrderList = {
        pageNo: pageNo.value,
        pageSize: pageSize.value,
        order:'desc',
        column: 'createTime',
        ...state.queryParam,
        userName: '*' + state.queryParam.userName +'*',
        deliverySn: '*' + state.queryParam.deliverySn +'*',
        receiveName: '*' + state.queryParam.receiveName +'*',
        receivePhone: '*' + state.queryParam.receivePhone +'*',
        sourceType: sourceType.value,
      }
      
      // const data = await invoiceExpressOrderList(paramse)
      
      invoiceExpressOrderList(paramse).then(res=> {
        console.log('invoiceExpressOrderList====', res);
        total.value = res.total
        dataSource.value = res.records
        loading.value = false
      }).catch(err=> {
        console.log('invoiceExpressOrderList--------',err);
        Message.error('请求失败')
        loading.value = false
      })
    }
    
    onMounted(() => {
      const data: any = router.currentRoute.value.query.verify
      const query: any = returnQuery(data);
      sourceType.value=query.sourceType
      if (!query.time) {
        Message.warning('链接已失效，请重新进入')
      } else {
        urlTime.value = Number(query.time)
        getContractPrintOrderList()
      }
    })
    return {
      sourceType,
      dataSource,
      columns,
      loading,
      userName,
      pagination,
      paymentStatus,
      deliveryStatusColor,
      paymentStatusColor,
      deliveryStatus, status, statusColor,
      payType,
      getContractPrintOrderList,
      handleTableChange,
      onClearSelected,
      onSelectChange,
      selectedRowKey,
      selectionRow,
      handleEdit,
      batchPlaceOrders,
      modalFormOk,
      placeOrders,
      state,
      searchQuery,
      searchReset,
      onPrintExpressImg,
      modalForm,
      getLogistics,
      logisticsModal,
      to,
      headleRefund
    };
  },
  components: {
    ContractPrintOrderModal,
    LogisticsModal,
    PrinterOutlined,
    SearchOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    DownOutlined,
  },
});
