
import { watch, defineComponent, reactive, ref, toRefs, PropType, toRef, computed } from 'vue';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
import type { UnwrapRef } from 'vue';
import { useStore } from 'vuex';
import { hrUserFormState, Qualification } from '@/views/HrUser/data';
import { EDIT_USER, listQualification, deleteQualification,listAcademicTitle,deleteAcademicTitle,listOtherCertificates,deleteOtherCertificates } from "@/api/hrUser"
import { message } from 'ant-design-vue';
import QualificationModal from "@/views/HrUser/HrUserStep/modules/QualificationModal.vue"
import AcademicTitleModal from "@/views/HrUser/HrUserStep/modules/AcademicTitleModal.vue"
import OtherCertificatesModal from "@/views/HrUser/HrUserStep/modules/OtherCertificatesModal.vue"
import { nextTick } from 'process';


type FormState = {
  dataSource?: Array<Qualification>,
  id: string
}
export default defineComponent({
  emits: ['toNextTab'],
  components: {
    PlusOutlined,
    UploadOutlined,
    QualificationModal,
    AcademicTitleModal,
    OtherCertificatesModal
  },
  props: {
    data: {
      type: Object as PropType<hrUserFormState>,
    },
    activeKey: {
      type: Number as PropType<number>,
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const datas = toRef(props, 'data')
    const type = toRef(props, 'type')
    const disabled = computed(() => {
      if (type.value === 'detail') {
        return true
      } else {
        return false
      }
    })
    const activeKey = toRef(props, 'activeKey')
    const store = useStore()
    const modalForm = ref(null)
    const AcademicTitleForm=ref(null)
    const OtherCertificatesModal=ref(null)
    const state = reactive({
      confirmLoading: false,
      loading: false,
        hasProfessionalQualificationsList:[
        {title:'是',key:'1'},
        {title:'否',key:'0'},
      ],
     dataSource2: [], 
     dataSource3:[],
     deleteShow:false,
     deleteStr:'',
     deleteId:'',
     modalFormShow:false,
     AcademicTitleFormShow:false,
     OtherCertificatesModalShow:false
    })
    const formState: any = reactive({
      dataSource: [],
      id: null,
      hasProfessionalQualifications:1,
      hasProfessionalTitle:1,
      hasOtherCertificate:1
    });
         const rules = {
      hasProfessionalQualifications: [{ required: true, message: '请选择是否有执业资格证书' }],
      hasProfessionalTitle: [{ required: true, message: '请选择是否有职称信息证书' }],
      hasOtherCertificate: [{ required: true, message: '请选择是否有其他证书' }],

    };
    const formRef = ref()
    function toNextTab(key) {
      // if ((datas.value as hrUserFormState).hasProfessionalQualifications == 1 && key === 5) {
      //   if (!formState.dataSource.length) {
      //     message.warning('上传一条执业资格信息')
      //     return
      //   }
      // }
      if(key ==3){
         context.emit('toNextTab', key, { stage: key,  ...formState })
      }else{
        formRef.value.validate().then(async () => {
        context.emit('toNextTab', key, { stage: key,  ...formState })

        // state.confirmLoading = true
        // EDIT_USER({ stage: key, id: formState.id }).then(res => {
        //   context.emit('toNextTab', key)
        // }).finally(() => {
        //   state.confirmLoading = false
        // })
      }).catch((error) => {
        console.log("error", error);
      })
      }
   
    }

    const modalFormOk = (str) => {
      switch (str) {
        case 'modalForm':
          state.modalFormShow=false
          break;
       case 'AcademicTitleForm':
          state.AcademicTitleFormShow=false
          break;
           case 'OtherCertificatesModal':
          state.OtherCertificatesModalShow=false
          break;
        default:
          break;
      }
      getDataSource(formState.id)
    }
    const getDataSource = (hrEntryApplicationId: string|number) => {
      if (!hrEntryApplicationId) {
        return
      }
      state.loading = true
      listQualification({
        pageSize: 999,
        pageNo: 1,
        hrEntryApplicationId
      }).then(res => {
        formState.dataSource = res.records
      }).finally(()=>{
        state.loading = false
      })
       listAcademicTitle({
        pageSize: 999,
        pageNo: 1,
        hrEntryApplicationId
      }).then(res2 => {
        state.dataSource2 = res2.records
      }).finally(()=>{
        state.loading = false
      })
listOtherCertificates({
        pageSize: 999,
        pageNo: 1,
        hrEntryApplicationId
      }).then(res3 => {
        state.dataSource3 = res3.records
      }).finally(()=>{
        state.loading = false
      })

    }
    const handleEidt = (record,str) => {
      if (!record.id) {
        message.warning('没有id')
        return
      }
      switch (str) {
        case 'AcademicTitleForm':
          state.AcademicTitleFormShow=true
          nextTick(()=>{
            AcademicTitleForm.value.edit(record)
          })
          break;
        case 'modalForm':
          state.modalFormShow=true
          nextTick(()=>{
          modalForm.value.edit(record)
          })
          break;
            case 'OtherCertificatesModal':
              state.OtherCertificatesModalShow=true
              nextTick(()=>{
              OtherCertificatesModal.value.edit(record)
              })
        
          break;
          
        default:
          break;
      }
      
    }
    const handleDelete = (record,str) => {
      if (!record.id) {
        message.warning('没有id')
        return
      }
      state.deleteShow=true
      state.loading = true
      state.deleteStr=str
      state.deleteId=record.id
    
   
    }
    const handleAdd = (str) => {
      if (!formState.id) {
        message.warning('没有人员id')
        return
      }
     
      switch (str) {
        case 'modalForm':
            state.modalFormShow=true
            nextTick(()=>{
          modalForm.value.add(formState.id)
            })
           
          break;
        case 'AcademicTitleForm':
          state.AcademicTitleFormShow=true
          nextTick(()=>{
  AcademicTitleForm.value.add(formState.id)
          })
         
          break;
           case 'OtherCertificatesModal':
             state.OtherCertificatesModalShow=true
             nextTick(()=>{
           OtherCertificatesModal.value.add(formState.id)

             })
          break;
          
        default:
          break;
      }
     
    }
      function selectBut(e,str){
      if( formState[str]==e){
        // formState[str]=''
      }else{
        formState[str]=e
      }

    }
    function deleteOk(){

    switch (state.deleteStr) {
        case 'AcademicTitleForm':
            deleteAcademicTitle({id: state.deleteId}).then(res=> {
        message.success('删除成功')
        getDataSource(formState.id)
      }).catch(err=> { 
        message.error('删除失败') 
      }).finally(()=>{
        state.loading = false
      })
          break;
        case 'modalForm':
      deleteQualification({id: state.deleteId}).then(res=> {
        message.success('删除成功')
        getDataSource(formState.id)
      }).catch(err=> {
        message.error('删除失败')
      }).finally(()=>{
        state.loading = false
      })
          break;
            case 'OtherCertificatesModal':
      deleteOtherCertificates({id: state.deleteId}).then(res=> {
        message.success('删除成功')
        getDataSource(formState.id)
      }).catch(err=> {
        message.error('删除失败')
      }).finally(()=>{
        state.loading = false
      })
          break;
        default:
          
          break;
      }
      state.deleteShow=false
    }
    watch(
      () => datas.value,
      () => {
        formState.id = (datas.value as hrUserFormState).id
         Object.keys(formState).forEach(item => {
          formState[item] = (datas.value)[item]
        })
        formState.dataSource=[]
        if (activeKey.value === 4) {
          getDataSource(formState.id)
        }
      }
    )
    watch(
      () => activeKey.value,
      () => {
        if (activeKey.value === 4) {
          getDataSource(formState.id)
        }
      }
    )
    return {
      ...toRefs(state),
      formState,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      toNextTab,
      modalFormOk,
      modalForm,
      handleAdd,
      handleDelete,
      handleEidt,
      disabled,
      selectBut,
      rules,
      AcademicTitleForm,
      OtherCertificatesModal,
      deleteOk
    };
  },
});
