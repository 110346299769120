import request from '@/utils/request'
import tokenRequest from '@/utils/tokenRequest'
import { EduForm,WorkForm,HistoryWork,Monograph} from '@/views/largeScreen/data'

enum Api {
    countAllTitle = '/hr-server/hr/title/countAllTitle',
    getTitleLists = '/hr-server/hr/hrEmployee/infolist',
    pageUpTitlePeopleInfo = '/hr-server/hr/title/pageUpTitlePeopleInfo',
    // queryTitleUPListByTitleName = '/hr-server/hr/title/queryTitleUPListByTitleName',
    queryCountTitleListIUpgrade = '/hr-server/hr/title/queryCountTitleListIUpgrade',
    queryEmployeeById = '/hr-server/hr/title/selectTitleManagementPeopleDetails',
    queryTitleByEmployeeId = '/hr-server/hr/title/queryTitleByEmployeeId',
    getRelevant = '/hr-server/hr/title/pageTitleManagementPeopleDetails',
    getInvoices = '/hr-server/hr/hrEmployee/queryCountTitle',
    getContinueEdu ='/hr-server/hr/title/pageTrainingExperience',
    addContinueEdu ='/hr-server/hr/title/insertTrainingExperience',
    editContinueEdu ='/hr-server/hr/title/updateTrainingExperience',
    delContinueEdu = '/hr-server/hr/title/deleteTrainingExperience',
    getWorkExp = '/hr-server/hr/title/pageWorkHistory',
    addWorkExp = '/hr-server/hr/title/insertWorkHistory',
    editWorkExp ='/hr-server/hr/title/updateWorkHistory',
    delWorkExp = '/hr-server/hr/title/deleteWorkHistory',
    getHistoryWork = '/hr-server/hr/title/pageHistoricalPerformance',
    addHistoryWork = '/hr-server/hr/title/insertHistoricalPerformance',
    editHistoryWork = '/hr-server/hr/title/updateHistoricalPerformance',
    delHistoryWork = '/hr-server/hr/title/deleteHistoricalPerformance',
    getNowWork = '/hr-server/hr/title/pageTitle',
    getMonograph ='/hr-server/hr/title/pageMyDemand',
    addMonograph = '/hr-server/hr/title/insertMyDemand',
    editMonograph = '/hr-server/hr/title/updateMyDemand',
    delMonograph = '/hr-server/hr/title/deleteMyDemand',
    exportTable = '/hr-server/hr/title/exportTitleTable',
    selectUpStep ='/hr-server/hr/title/selectUpStep'
}
interface SelectList {
    employeeId:string,
    pageNo:number,
    pageSize:number
}
interface SelectListById {
    id:string,
    pageNo:number,
    pageSize:number
}

// 职称-查询个人职称数据及所需条件
export const queryTitleByEmployeeId = (params: { employeeId: string }): any => request.get(Api.queryTitleByEmployeeId, params)

// 职称-查询员工职称详细信息
// export const queryEmployeeById = (params: { id: string;}): any => tokenRequest.get(Api.queryEmployeeById, params)
export const queryEmployeeById = (params: { id: string;employeeId:string;}): any => tokenRequest.get(Api.queryEmployeeById, params)

//职称-查询业绩&论文&专著
export const getRelevant = (params: any): any => tokenRequest.get(Api.getRelevant, params)
//获取单据数据
export const getInvoices = (params: any): any => request.get(Api.getInvoices, params)
// 职称-根据职称名称查询所有可升级的职称
// export const queryTitleUPListByTitleName = (params: { name: any }): any => request.get(Api.queryTitleUPListByTitleName, params)
// 职称-查询所有不同的职称统计
export const countAllTitle = (): any => tokenRequest.post(Api.countAllTitle)
// 职称-获取职称的数据列表
export const pageUpTitlePeopleInfo = (params: any): any => tokenRequest.post(Api.pageUpTitlePeopleInfo, params)
// export const getTitleLists = (params): any => tokenRequest.get(Api.getTitleLists, params)
// 职称-获取可升级的职称统计
export const queryCountTitleListIUpgrade = (): any => request.get(Api.queryCountTitleListIUpgrade)

// 职称-分页查询学习培训经历
export const getContinueEdu = (params:SelectList): any => tokenRequest.get(Api.getContinueEdu, params)
// 职称-新增学习培训经历
export const addContinueEdu = (params:EduForm): any => tokenRequest.post(Api.addContinueEdu, params)
// 职称-修改培训经历
export const editContinueEdu = (params:EduForm): any => tokenRequest.post(Api.editContinueEdu, params)
// 职称-删除培训经历
export const delContinueEdu = (params:FormData): any => tokenRequest.post(Api.delContinueEdu, params)
// 职称-分页查询工作经历
export const getWorkExp = (params:SelectList): any => tokenRequest.get(Api.getWorkExp, params)
// 职称-新增工作经历
export const addWorkExp = (params:WorkForm): any => tokenRequest.post(Api.addWorkExp, params)
// 职称-修改工作经历
export const editWorkExp = (params:WorkForm): any => tokenRequest.post(Api.editWorkExp, params)
// 职称-删除工作经历
export const delWorkExp = (params:FormData): any => tokenRequest.post(Api.delWorkExp, params)
// 职称-分页查询历史业绩
export const getHistoryWork = (params:SelectList): any => tokenRequest.get(Api.getHistoryWork, params)
// 职称-新增历史业绩
export const addHistoryWork = (params:HistoryWork): any => tokenRequest.post(Api.addHistoryWork, params)
// 职称-修改历史业绩
export const editHistoryWork = (params:HistoryWork): any => tokenRequest.post(Api.editHistoryWork, params)
// 职称-删除历史业绩
export const delHistoryWork = (params: FormData): any => tokenRequest.post(Api.delHistoryWork, params)
// 职称-分页查询现单位业绩
export const getNowWork = (params: SelectListById): any => tokenRequest.get(Api.getNowWork, params)
// 职称-分页查询论文&专著
export const getMonograph = (params: SelectList): any => tokenRequest.get(Api.getMonograph, params)
// 职称-新增论文&专著
export const addMonograph = (params:Monograph): any => tokenRequest.post(Api.addMonograph, params)
// 职称-修改论文&专著
export const editMonograph = (params:Monograph): any => tokenRequest.post(Api.editMonograph, params)
// 职称-删除论文&专著
export const delMonograph = (params:FormData): any => tokenRequest.post(Api.delMonograph, params)
// 职称-导出专业技术职务任职资格评审表
export const exportTable = (params:{declareMajor:string,declareTitle:string,ids: string[],employeeId:string,id:string}): any => tokenRequest.post(Api.exportTable, params)
// 职称-人员详情升级步骤信息
export const getUpStep = (params:{ employeeId: string ,id?:string}): any => tokenRequest.get(Api.selectUpStep, params)