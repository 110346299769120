
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    toRaw
} from 'vue'
import { RuleObject,ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { Moment } from 'moment';
import { message } from 'ant-design-vue';
import { UploadOutlined,CloudUploadOutlined } from '@ant-design/icons-vue';
import { FileItem,FileInfo } from '@/views/HrUser/data';
import { validateIdent } from '@/utils/date';
import { getToken, getUserInfo } from "@/utils/auth"
import { politicalOutlooks,maritalStatus } from '@/utils/customExpression'
import { _area } from '@/components/_util/Area'
import {baseURL} from '@/config/index'
interface FormState {
    nationality:string;
    identityCardType:string;
    identitycardcode:string;
    name:string;
    oldname:string;
    nation:string;
    portrait:string;
    sex:string;
    birthday:Moment | undefined;
    political:string;
    registNature:string;
    mobilephone:string;
    nativeplace:any;
    maritalstatus:string;
    joinworkdate:Moment | undefined;
    healthstatus:string;
    address:string;
    remark:string;
    identityCardBack: string;
    identityCardFace: string;
    identityCardFile: string;
}
export default defineComponent({
    name:'base-info',
    description:'基础信息',
    components:{
        UploadOutlined,
        CloudUploadOutlined
    },
    props:{
        disabled:{
            type:Boolean,
            default:false
        },
        baseInfo:{
            type:Object,
            default:() =>{}
        }
    },
    setup(props, context){
        console.log('=======baseInfo',props.baseInfo)
        const disableSubmit = toRef(props, 'disabled')
        const state = reactive({
            headers: {
                "X-Access-Token": getToken()
            },
            action:baseURL+'/sys/oss/api/uploadRequest',
            disabled:false,
            portrait:false,
            identityCardFace:false,
            identityCardBack:false,
            politicalOutlooks,
            maritalStatus,
        });
        //省市区
        const pcaaData = _area;
        // form表单数据
        const formRef = ref();
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };
        const formState:UnwrapRef<FormState> = reactive({
            nationality:'中国',
            identityCardType:'居民身份证(户口本)',
            identitycardcode:'',
            name:'',
            oldname:'',
            nation:'',
            portrait:'',
            sex:'',
            birthday:undefined,
            // peopleType:'',
            political:'',
            registNature:'',
            mobilephone:'',
            nativeplace:[] || '',
            maritalstatus:'',
            joinworkdate:undefined || null,
            healthstatus:'',
            address:'',
            remark:'',
            identityCardBack: '',
            identityCardFace: '',
            identityCardFile: '',
            ...props.baseInfo
        });
        // console.log('======进本信息',formState);
        // form表单数据验证
        let checkIdentity = async (rule: RuleObject, value: string) => {
            if (value === '') {
                return Promise.reject('请输入证件号！');
            } else if (!validateIdent.IdentityCodeValid(value)) {
                return Promise.reject("请输入正确的证件号！");
            } else {
                return Promise.resolve();
            }
        }
        const rules = {
            nationality: [{ required: true, message: '请输入国籍！' }],
            identityCardType: [{ required: true, message: '请输入证件类型！' }],
            // identitycardcode: [{ required: true, message: '请输入证件号！' }],
            identitycardcode: [{required: true, validator: checkIdentity, trigger: 'change' }],
            name: [{ required: true, message: '请输入姓名！' }],
            sex: [{ required: true, message: '请选择性别！' }],
            birthday: [{ required: true, message: '请选择出生日期！' }],
            nation: [{ required: true, message: '请输入民族！' }],
            political: [{ required: true, message: '请选择政治面貌！' }],
            registNature: [{ required: true, message: '请选择户口状况！' }],
            nativeplace: [{ required: true, message: '请选择籍贯！' }],
            mobilephone: [{ required: true, message: '请输入联系电话！' }],
            maritalstatus: [{ required: true, message: '请选择婚姻状况！' }],
            joinworkdate: [{ required: true, message: '请选择参加工作时间！' }],
            address: [{ required: true, message: '请输入现居地！' }],
            identityCardFace: [{ required: true, message: '请上传身份证正面照片！' }],
            identityCardBack: [{ required: true, message: '请上传身份证反面照片！' }],
            identityCardFile:[{ required: true, message: '请上传加盖单位印章的身份证复印件！' }],
        };
        //上传附件
        const portraitFileList = ref<FileItem[]>();
        const identityFaceFileList = ref<FileItem[]>();
        const identityBackFileList = ref<FileItem[]>();
        const identityFileList = ref<FileItem[]>();
        const handleChange = (info: FileInfo, key: string) => {
            if (info.file.status === 'uploading') {
                state[key] = true;
                return;
            }
            if (info.file.status === 'done') {
                // console.log('=======上传反馈',info);
                // Get this url from response in real world.
                // const url = info?.file?.response?.result?.url;
                let resFileList = [...info.fileList]
                let tempFile = resFileList.map(el =>el?.response?.result?.url)
                console.log('===========info',info,key,tempFile);
                // if(key==='identityCardFile') {
                    formState[key] = tempFile.join(',');
                // }else {
                //     formState[key] = url;
                    state[key] = false;
                // }
            }
            if (info.file.status === 'error') {
                state[key] = false;
                message.error('上传失败');
            }
        };
        
        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                    console.log('values', formState,toRaw(formState).joinworkdate ? toRaw(formState).joinworkdate.format('YYYY-MM-DD'):'');
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        };
        const resetForm = () => {
            formRef.value.resetFields();
        };
        return {
            ...toRefs(state),
            disableSubmit,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            pcaaData,
            formRef,
            formItemLayout,
            formState,
            rules,
            portraitFileList,
            identityFaceFileList,
            identityBackFileList,
            identityFileList,
            handleChange,
            submitForm,
            resetForm
        };
    }
})
