import request from '@/utils/request'
import ordinaryRequest from '@/utils/ordinaryRequest'
import tokenRequest from '@/utils/tokenRequest'
enum Api {
    pageIntentionCustomerList = '/hr-server/hr/hrIntentionCustomer/pageIntentionCustomerList',
    insertIntentionCustomer = '/hr-server/hr/hrIntentionCustomer/insertIntentionCustomer',
    updateIntentionCustomer = '/hr-server/hr/hrIntentionCustomer/updateIntentionCustomer',
    deleteIntentionCustomer = '/hr-server/hr/hrIntentionCustomer/deleteIntentionCustomer',
    getIntention = '/sfsj-server/sys/dict/getDictItem?code=customer_intent',
    getIndustry = '/sfsj-server/sys/dict/getDictItem?code=major_industry',
    getWorkspace = '/sfsj-server/sys/dict/getDictItem?code=shared_workspace'
}
interface AddIntentionCustomer {
    area: String,
    city: String,
    companyName: String,
    name: String,
    phone: String,
    province: String,
    type: String,
    nativeplace: String,
    source?:number,
  }
// 职称-查询意向客户
export const queryIntentionCustomerList = (params: { pageNo: String,pageSize:String }): any => request.get(Api.pageIntentionCustomerList, params)
// 职称-增加意向客户
export const addIntentionCustomer = (params:AddIntentionCustomer): any => request.post(Api.insertIntentionCustomer, params)
// 职称-更新意向客户
export const updateIntentionCustomer = (params:AddIntentionCustomer): any => request.post(Api.updateIntentionCustomer, params)
// 职称-更新意向客户
export const deleteIntentionCustomer = (params:{id:Number}): any => request.post(Api.deleteIntentionCustomer, params)
// 获取意向、行业和办公区字典
export const getIntention = (): any => ordinaryRequest.get(Api.getIntention)
export const getIndustry = (): any => ordinaryRequest.get(Api.getIndustry)
export const getWorkspace = (): any => ordinaryRequest.get(Api.getWorkspace)