
import { 
  defineComponent,
  ref, 
  toRefs,
  reactive,
  watch,
  UnwrapRef,
  onMounted,
  nextTick
} from 'vue'
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { _area } from '@/components/_util/Area'
import { AddAcademic, FileInfo } from '@/views/HrUser/data'
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue'
import { getToken } from "@/utils/auth"
import { parseGraduate, addAcademic, editAcademic ,getMajorList as getMajor} from "@/api/hrUser"
import {baseURL} from '@/config/index'
import HMultiSelectTag from '@/components/HMultiSelectTag.vue'

export default defineComponent({
  name: 'EducationModal',
  props: {
  },
  components: {
    UploadOutlined,
    HMultiSelectTag
  },
  emits: ['ok'],
  setup(props, context) {
    let formState: UnwrapRef<AddAcademic> = reactive({
      "degree": null,
      "degreeAttachment": null,
      "degreeAttachmentName": null,
      "diploma": null,
      "diplomaNumber": null,
      "educationAttachment": null,
      "educationAttachmentName": null,
      "educationKind": null,
      "entrancelDate": null,
      "firstProfession": null,
      "firstProfessionName": null,
      "graduationDate": null,
      "hrEntryApplicationId": null, // 入职申请id
      "id": null,
      "school": null,
      "schoolShape": null,
    })
    const state = reactive({
      degreeUrl:baseURL+'/sfsj-server/sys/oss/api/uploadRequest',
      fileList: [],
      isEdit:false,
      CompanyDate:[],
      headers: {
        "X-Access-Token": getToken()
      },
      loadingMore: false,
      confirmLoading: false,
      treeData:[],
      replaceFields: { children: 'children', title: 'name', key: 'id',value:'id' },
    })

    const rules = {
      degreeAttachment: [{ required: true, message: '请上传学历证书' },],
      diploma: [{ required: true, message: '请输入学历' },],
      firstProfession: [{ required: true, message: '请选择专业' },]
    };
    
    const handleChange = (info: FileInfo, key: string) => {
      
      if (info.file.status === 'uploading') {
        state.confirmLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        const imgUrl = info?.file?.response?.result?.url;
        const fileName = (info?.file?.response?.result as { fileName: string }).fileName;
        formState[key] = imgUrl
        if (key ==='degreeAttachment') {
          parseGraduate({
            graduateImgUrl: imgUrl
          }).then(res=> {
            Object.keys(res).forEach(item=> {
              if(item==='firstProfession'){
                return
              }else if(item==='diploma'){
                return
              }
              formState[item] = res[item]
              formState.degreeAttachmentName = fileName
            })
            console.log(formState)
          }).finally(() => {
            state.confirmLoading = false;
          })
        } else {
          formState.educationAttachmentName = fileName
          state.confirmLoading = false;
        }
      }
      if (info.file.status === 'error') {
        state.confirmLoading = false;
        message.error('上传失败');
      }
    };
    const add = (id: string) => {
      if (!id) {
        message.warning('没有人员id')
        return
      }
      Object.keys(formState).forEach(item=> {
        formState[item] = null
      })
      state.loadingMore = true
      state.CompanyDate=[]
      formState.hrEntryApplicationId = id
    }
    const edit = (record: any) => {
      console.log(record,'record123');
      
      state.loadingMore = true
      state.isEdit=true
      Object.keys(record).forEach(item=> {
        nextTick(()=>{
        formState[item] = record[item]
        

        })
      })
      nextTick(()=>{
 if( formState.entrancelDate &&  formState.graduationDate){
          state.CompanyDate=[formState.entrancelDate,formState.graduationDate]
        }else{
          state.CompanyDate=[]
        }
      })
  
      
    }
    const cancel = () => {
      // context.emit('ok')
    }
    function onOk() {
      state.loadingMore = false
      context.emit('ok')
    }
    const formRef = ref()
    function onSubmit() {
      formRef.value.validate().then(async () => {
        state.confirmLoading = true
        if (!formState.id) {
          addAcademic(formState).then(res => {
            onOk()
          }).finally(() => {
            state.confirmLoading = false
          })
        } else {
          editAcademic(formState).then(res => {
            onOk()
          }).finally(() => {
            state.confirmLoading = false
          })
        }
        
      }).catch((error: ValidateErrorEntity<AddAcademic>) => {
        console.log("error", error);
        message.warning('信息未填写完整')
      });
    
    }
     function handlemajor(){
       console.log(1);
        getMajorList()
       
     }
       function  handleTreeChange(e,row,name){
      console.log(e,row,name)
      formState[name] = row[0]
      // formState.firstProfession = e 
    }
     // 递归遍历
   function intetorFun(data) {
      data.forEach((e) => {
         if(e.children&&e.children.length!==0){
           e.disabled = true
           intetorFun(e.children)
         }
      })
    }
    function getMajorList(){
      getMajor({}).then( async (res) => {
      if (res) {
        let treeData = [
          {
            id: 1,
            name: '专科',
            disabled: true,
            children: res[1],
          },
          {
            id: 2,
            name: '本科',
            disabled: true,
            children: res[2],
          },
          {
            id: 3,
            name: '研究生',
            disabled: true,
            children: res[3],
          },
        ]
       await intetorFun(treeData)
       state.treeData = treeData
      }
    })
    }
    function diplomachange(v){
     formState.diploma=v
     
    }
     function pickerLdchange(e){
           console.log(e,'eeee');
        formState.entrancelDate=e[0] || null 
        formState.graduationDate=e[1] || null 
    }
      onMounted(() => {
       getMajorList()
       
    })

    return {
      ...toRefs(state),
      formRef,
      add,
      cancel,
      rules,
      formState,
      onSubmit,
      onOk,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      handleChange,
      edit,
      handleTreeChange,
      getMajorList,
      intetorFun,
      handlemajor,
      diplomachange,
      pickerLdchange,
      
    };
  },
  
});
