import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23ebbc14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "printDiv2" }
const _hoisted_2 = { class: "printDiv_data_box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QrcodeVue = _resolveComponent("QrcodeVue")!
  const _component_card = _resolveComponent("card")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, { spinning: _ctx.confirmLoading }, {
    default: _withCtx(() => [
      _createVNode(_component_card, { id: "opacity" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toDataURLList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "printDiv",
                key: index
              }, [
                _createVNode(_component_QrcodeVue, {
                  style: {"width":"200px","height":"200px"},
                  size: "500",
                  value: item.url
                }, null, 8, ["value"]),
                _createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["spinning"]))
}