import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "更新-票",
    visible: _ctx.loadingMore,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.loadingMore) = $event)),
    onCancel: _ctx.cancel,
    onOk: _ctx.onSubmit,
    maskClosable: false,
    okText: "提交",
    cancelText: "关闭"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, {
        spinning: _ctx.confirmLoading,
        tip: "正在打印，请勿关闭"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.formState,
            rules: _ctx.rules,
            "label-col": _ctx.labelCol,
            "wrapper-col": _ctx.wrapperCol
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "发票编号",
                name: "invoiceCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.invoiceCode,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.invoiceCode) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "发票状态",
                name: "invoiceState"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.invoiceState,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.invoiceState) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("未使用")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("已使用")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "发票类型",
                name: "invoiceType"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.invoiceType,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.invoiceType) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 'SpecialVat' }, {
                        default: _withCtx(() => [
                          _createTextVNode("专票")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 'VAT' }, {
                        default: _withCtx(() => [
                          _createTextVNode("普票")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "发票名称",
                name: "invoiceName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.invoiceName,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.invoiceName) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "发票日期",
                name: "invoiceDate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    value: _ctx.formState.invoiceDate,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.invoiceDate) = $event)),
                    type: "date",
                    "value-format": "YYYY-MM-DD HH:mm:ss",
                    "show-time": "",
                    format: "YYYY-MM-DD HH:mm:ss",
                    placeholder: "请选择发票日期",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "备注",
                name: "remarks"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.remarks,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.remarks) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "label-col", "wrapper-col"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["visible", "onCancel", "onOk"]))
}