import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { disabled: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.disabled?'jeecg-form-container-disabled':'')
  }, [
    _createElementVNode("fieldset", { disabled: _ctx.disabled }, [
      _renderSlot(_ctx.$slots, "detail")
    ], 8, _hoisted_1),
    _renderSlot(_ctx.$slots, "edit"),
    _createElementVNode("fieldset", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}