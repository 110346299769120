
import { watch, defineComponent, reactive, ref, toRefs, PropType, toRef, computed } from 'vue';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
import type { UnwrapRef } from 'vue';
import { useStore } from 'vuex';
import { hrUserFormState, WorkHistory,QdApplyHrUser } from '@/views/HrUser/data';
import { EDIT_USER, listWorkHistory, deleteWorkHistory,getProcessByActi,getTaskId,completeHandler,saveAndStartAct, 
  getActStartProcess,qdApply } from "@/api/hrUser"
import { message } from 'ant-design-vue';
import WorkHistoryModal from "@/views/HrUser/HrUserStep/modules/WorkHistoryModal.vue"
import { useRouter } from 'vue-router'

import { getUserInfo } from "@/utils/auth"
import { nextTick } from 'process';

type FormState = {
  dataSource?: Array<WorkHistory>,
  id: string
}
export default defineComponent({
  emits: ['toNextTab'],
  components: {
    PlusOutlined,
    UploadOutlined,
    WorkHistoryModal,
 
  },
  props: {
    data: {
      type: Object as PropType<hrUserFormState>,
    },
    activeKey: {
      type: Number as PropType<number>,
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
     const router = useRouter()
    const datas = toRef(props, 'data')
    const type = toRef(props, 'type')
    const disabled = computed(() => {
      if (type.value === 'detail') {
        return true
      } else {
        return false
      }
    })
    const activeKey = toRef(props, 'activeKey')
    const store = useStore()
    const modalForm = ref(null)
    const state = reactive({
      confirmLoading: false,
      loading: false,
      taskId: '',
      deleteShow:false,
      deleteId:'',
      modalFormShow:false
    })
    const userInfo = getUserInfo()
    const formState: UnwrapRef<FormState> = reactive({
      dataSource: [],
      id: null
    });
    const formRef = ref()
    function toNextTab(key) {

      if(key == 4){
          context.emit('toNextTab', key, { stage: key, id: formState.id })
          return
      }
       const data: any = router.currentRoute.value.query
     
      formRef.value.validate().then(async () => {
          state.confirmLoading = true
          // 流程处理并且需要保存表单
          if (Object.keys(data).includes('isActiviti')) {
            // 先处理流程
            getProc(data.activitiId).then(res => {
              getTaskId({ instanceId: res.instanceId }).then(ress => {
                state.taskId = ress || ""
                completeHandler({
                  taskId: state.taskId,
                  instanceId: res.instanceId,
                  variables: {
                    comment: '',
                    pass: true
                  }
                }).then(res => {
                  state.taskId = '';
                  message.success('提交成功');
                  // 流程审批完成后不需要保存步骤，直接下一页
                  context.emit('toNextTab', key)
                }).catch(err=> {
                  message.warning('提交失败,联系系统人员');
                }).finally(() => {
                  state.confirmLoading = false
                })
              }).catch(err=> {
                message.warning('查询流程信息失败')
                state.confirmLoading = false
              })
            }).catch(err=> {
              message.warning('查询流程信息失败')
              state.confirmLoading = false
            })
          // 非流程
          } else {
            getActStartProcess({
              key: 'hr_entry_application'
            }).then(result => {
              saveAndStartAct({
                ...(datas.value as hrUserFormState),
                username: userInfo.username,
                procDefId: result.id,
                id: formState.id,
                key: formState.id,
                processName: result.name,
                stage: 10
              }).then(res => {
                let variables = {}
                getProc(res).then(ress => {
                  variables = ress.variables ? JSON.parse(ress.variables) : {type:''};
                  qdApply({
                    username: userInfo.username,
                    definitionId: result.id,
                    id: formState.id,
                    key: formState.id,
                    variables: variables,
                    category: result.category
                  } as QdApplyHrUser).then(res => {
                    context.emit('toNextTab', key)
                  }).catch(() => {
                    message.warning('保存数据失败！')
                    state.confirmLoading = false
                  }).finally(() => {
                    state.confirmLoading = false
                  })
                })
                
              }).catch(() => {
                message.warning('保存数据失败！')
                state.confirmLoading = false
              })
            }).catch(() => {
              state.confirmLoading = false
            })
          }
          
        }).catch((error) => {
          console.log("error", error);
        })

      
    }
     //获取流程数据---编辑/查看/启动
    const getProc = (activitiId: string)=> {
      return getProcessByActi({ activitiId }).then(res => {
        if(res) {
          return res;
        }else {
          message.warning('获取variables失败，请联系管理员！');
        }
      })
    }
    const modalFormOk = () => {
      state.modalFormShow=false
      nextTick(()=>{
        getDataSource(formState.id)
      })
      
    }
    const getDataSource = (hrEntryApplicationId: string|number) => {
      if (!hrEntryApplicationId) {
        return
      }
      state.loading = true
      listWorkHistory({
        pageSize: 999,
        pageNo: 1,
        hrEntryApplicationId
      }).then(res => {
        formState.dataSource = res.records
      }).finally(()=>{
        state.loading = false
      })
    }
    const handleEidt = (record) => {
      if (!record.id) {
        message.warning('没有id')
        return
      }
      state.modalFormShow=true
      nextTick(()=>{
      modalForm.value.edit(record)
      })
     
    }
    const handleDelete = (record) => {
      if (!record.id) {
        message.warning('没有id')
        return
      }
       state.deleteShow=true
      state.loading = true
      state.deleteId=record.id
    
    }
       function deleteOk(){
       deleteWorkHistory({id: state.deleteId}).then(res=> {
        message.success('删除成功')
        getDataSource(formState.id)
      }).catch(err=> { 
        message.error('删除失败') 
      }).finally(()=>{
        state.loading = false
      })
      state.deleteShow=false
    }
    const handleAdd = () => {
      if (!formState.id) {
        message.warning('没有人员id')
        return
      }
      state.modalFormShow=true
      nextTick(()=>{
     modalForm.value.add(formState.id)
      })
     
    }
    watch(
      () => datas.value,
      () => {
        formState.id = (datas.value as hrUserFormState).id
        if (activeKey.value === 5) {
          getDataSource(formState.id)
        }
      }
    )
    watch(
      () => activeKey.value,
      () => {
        if (activeKey.value === 5) {
          getDataSource(formState.id)
        }
      }
    )
    return {
      ...toRefs(state),
      formState,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      userInfo,
      toNextTab,
      modalFormOk,
      modalForm,
      handleAdd,
      handleDelete,
      handleEidt,
      disabled,
      deleteOk
    };
  },
});
