import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a9a4d7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bnt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { bordered: false }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, {
        spinning: _ctx.confirmLoading,
        tip: "解析中..."
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            layout: "horizontal",
            ref: "formRef",
            model: _ctx.formState,
            labelCol: _ctx.labelCol,
            scrollToFirstError: "",
            wrapperCol: _ctx.wrapperCol,
            rules: _ctx.rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "姓名",
                name: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event)),
                    placeholder: "请输入姓名"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "性别",
                name: "sex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.sex,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.sex) = $event)),
                    placeholder: "请选择性别",
                    style: {"text-align":"left"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: "男" }, {
                        default: _withCtx(() => [
                          _createTextVNode("男")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "女" }, {
                        default: _withCtx(() => [
                          _createTextVNode("女")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "手机号码",
                name: "mobilePhone"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    disabled: true,
                    value: _ctx.formState.mobilePhone,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.mobilePhone) = $event)),
                    placeholder: "请输入手机号码"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "身份证号码",
                name: "identityCardCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.identityCardCode,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.identityCardCode) = $event)),
                    placeholder: "请输入身份证号码"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "出生日期1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_date_picker, {
                    valueFormat: "YYYY-MM-DD",
                    value: _ctx.formState.birthday,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.birthday) = $event)),
                    type: "date",
                    allowClear: false,
                    inputReadOnly: true,
                    placeholder: "请选择出生日期1",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "年龄" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    value: _ctx.formState.age,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.age) = $event)),
                    placeholder: "请输入年龄",
                    style: {"width":"100%"},
                    min: 1,
                    max: 100
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "政治面貌",
                name: "political"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.political,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.political) = $event)),
                    placeholder: "请选择政治面貌",
                    style: {"text-align":"left"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: "团员" }, {
                        default: _withCtx(() => [
                          _createTextVNode("团员")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "党员" }, {
                        default: _withCtx(() => [
                          _createTextVNode("党员")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "预备党员" }, {
                        default: _withCtx(() => [
                          _createTextVNode("预备党员")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "其他" }, {
                        default: _withCtx(() => [
                          _createTextVNode("其他")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "群众" }, {
                        default: _withCtx(() => [
                          _createTextVNode("群众")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              (_ctx.formState.political == '党员' || _ctx.formState.political == '预备党员')
                ? (_openBlock(), _createBlock(_component_a_form_item, {
                    key: 0,
                    label: "入党日期",
                    name: "joinPartyDate"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_date_picker, {
                        value: _ctx.formState.joinPartyDate,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.joinPartyDate) = $event)),
                        type: "date",
                        placeholder: "请选择入党日期",
                        style: {"width":"100%"}
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_form_item, { label: "民族" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.nation,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.nation) = $event)),
                    placeholder: "请输入民族"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "婚姻状况" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.maritalStatus,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formState.maritalStatus) = $event)),
                    placeholder: "请选择婚姻状况",
                    style: {"text-align":"left"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: "未婚" }, {
                        default: _withCtx(() => [
                          _createTextVNode("未婚")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "已婚" }, {
                        default: _withCtx(() => [
                          _createTextVNode("已婚")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "离异" }, {
                        default: _withCtx(() => [
                          _createTextVNode("离异")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "户籍" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.nativePlace,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.nativePlace) = $event)),
                    placeholder: "请输入户籍"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "通讯地址" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.address,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formState.address) = $event)),
                    placeholder: "请输入通讯地址"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "紧急联系人" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.emergencyContact,
                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formState.emergencyContact) = $event)),
                    placeholder: "请输入紧急联系人"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "紧急联系人号码" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.emergencyContactPhone,
                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formState.emergencyContactPhone) = $event)),
                    placeholder: "请输入紧急联系人号码"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "是否有职称(没有就选无或者不选)" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.hasProfessionalTitle,
                    "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formState.hasProfessionalTitle) = $event)),
                    placeholder: "请选择是否有职称",
                    style: {"text-align":"left"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("有")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("无")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "是否有执业资格(没有就选无或者不选)" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.hasProfessionalQualifications,
                    "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formState.hasProfessionalQualifications) = $event)),
                    placeholder: "请选择是否有执业资格",
                    style: {"text-align":"left"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("有")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("无")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "是否有毕业证书(没有就选无或者不选)" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.hasAcademicDegree,
                    "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formState.hasAcademicDegree) = $event)),
                    placeholder: "请选择是否有毕业证书",
                    style: {"text-align":"left"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("有")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("无")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                name: 'email',
                label: "邮箱"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.email,
                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.formState.email) = $event)),
                    placeholder: "请输入邮箱"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "身体情况" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.healthStatus,
                    "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formState.healthStatus) = $event)),
                    placeholder: "请选择身体情况",
                    style: {"text-align":"left"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: "健康" }, {
                        default: _withCtx(() => [
                          _createTextVNode("健康")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "有先天性疾病" }, {
                        default: _withCtx(() => [
                          _createTextVNode("有先天性疾病")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "有传染性疾病" }, {
                        default: _withCtx(() => [
                          _createTextVNode("有传染性疾病")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "有其他重大疾病" }, {
                        default: _withCtx(() => [
                          _createTextVNode("有其他重大疾病")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              (!_ctx.disabled)
                ? (_openBlock(), _createBlock(_component_a_form_item, { key: 1 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_a_button, {
                          style: {"width":"40%"},
                          key: "buy",
                          type: "primary",
                          onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.toNextTab(1)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("上一步")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_button, {
                          style: {"width":"40%"},
                          type: "primary",
                          onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.toNextTab(3)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("下一步")
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "labelCol", "wrapperCol", "rules"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }))
}