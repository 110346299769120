
import { defineComponent, reactive, ref, toRef, createVNode, toRaw } from 'vue';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'Trend',
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-trend'
    },
    /**
     * 上升下降标识：up|down
     */
    flag: {
      type: String,
      required: true
    },
    /**
     * 颜色反转
     */
    reverseColor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CaretUpOutlined,
    CaretDownOutlined,
  },
  setup(props, context) {
    const prefixCls = toRef(props, 'prefixCls')
    const flag = toRef(props, 'flag')
    const reverseColor = toRef(props, 'reverseColor')
    return {
      prefixCls, flag, reverseColor
    };
  },
  
});
