
import { 
  defineComponent,
  ref, 
  toRef,
  reactive,
  watch,
  UnwrapRef
} from 'vue'
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { _area } from '@/components/_util/Area'
import { ExpressOrderData, Option, SubmitPayment, AddressItem } from '@/interface/print'
import { addAndGetPayment, invoiceParseAddress } from '@/api/manage'
import ExpressOrderModel from '@/views/InvoiceExpressOrder/modules/ExpressOrderModel.vue'
import { message as Message } from 'ant-design-vue'

export default defineComponent({
  name: 'ExpressOrderForm',
  props: {
    userName: String,
    sourceType:String
  },
  components: {
    ExpressOrderModel
  },
  emits: ['ok'],
  setup(props, context) {
    const loadingMore = ref(false)
    const confirmLoading = ref(false)
    const expressOrderModel = ref(null)
    const userName = toRef(props, 'userName')
    const sourceType = toRef(props, 'sourceType')
    const formState: UnwrapRef<ExpressOrderData> = reactive({
      receivePhone: '',
      receiveName: '',
      expressType: '1',
      receiveAddress: '',
      valueArea: [],
      payType: 2,
      weight: 1,
      note: '',
      userName: userName.value,
      invoiceId: '',
      text: '',
    })
    const state = reactive({
      pcaaData: _area,
      address: [],
      addressValue: null
    })
    let validatePhone = async (rule: RuleObject, value: string) => {
      if (value === '') {
        return Promise.reject('请输入联系电话');
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      receivePhone: [{ required: true, message: "请输入联系电话", validator: validatePhone, trigger: 'change' }],
      receiveName: [{ required: true, message: "请输入收件人姓名" }],
      receiveAddress: [{ required: true, message: "请输入详细地址" }],
      valueArea: [{ required: true, message: "请选择省市区" }],
    };
    const cancel = () => {
      // context.emit('ok')
    }
    function onOk() {
      loadingMore.value = false
      context.emit('ok')
    }
    const formRef = ref()
    function onSubmit() {
      formRef.value.validate().then(async () => {
        confirmLoading.value = true
        let obj: SubmitPayment & ExpressOrderData = {
          ...formState,
          receiveProvince: formState.valueArea[0],
          receiveCity: formState.valueArea[1],
          receiveCounty: formState.valueArea[2],
          sourceType:sourceType.value
        }
        delete obj.valueArea
        addAndGetPayment(obj).then(res => { 
          if (formState.payType === 3) {
            Message.success('添加订单成功')
            onOk()
          } else {
            expressOrderModel.value.onSubmit(res)
          }
          confirmLoading.value = false
        }).catch(err => {
          console.log(err);
          Message.warning('添加订单失败')
          confirmLoading.value = false
        })
      }).catch((error: ValidateErrorEntity<ExpressOrderData>) => {
        console.log("error", error);
      });
      
    }
    const onChange = (e: any) => {
      formState.valueArea = e
      if (e[0] === '四川省' || e[0] === '重庆市') {
        formState.expressType = '2'
      }
    }
    const filter = (inputValue: string, path: Option[]) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    const edit = async (record: any) => {
      if (!record.invoiceId) {
        Message.warning('没有发票id')
        return
      }
      if (record.invoiceId) {
        confirmLoading.value = true
        invoiceParseAddress({ ids: record.invoiceId}).then((res: AddressItem[]) => {
          if (!res || !res.length || res[0] == null) return;
          state.addressValue = 0
          state.address = res
          let address = state.address[0]
          formState.receivePhone = address.phonenum || ''
          formState.receiveName = address.person || ''
          formState.receiveAddress = address.detail || ''
          formState.text = address.text || ''
          if (address.province && address.city) {
            if (address.county) {
              formState.valueArea = [address.province, address.city, address.county]
            } else {
              formState.valueArea = [address.province, address.city, address.town]
            }
          }
          if (address.province == '四川省' || address.province == '重庆市') {
            formState.expressType = '2'
          }
        }).finally(()=>{
          confirmLoading.value = false
        })
      }
      formState.invoiceId = record.invoiceId
      formState.userName = record.userName
      loadingMore.value = true
    }
    const changeAddress = (e: any) => {
      console.log('changeAddress-----', e)
      const count: AddressItem = state.address[e]
      formState.receivePhone = count.phonenum || ''
      formState.receiveName = count.person || ''
      formState.receiveAddress = count.detail || ''
      formState.text = count.text || ''
      if (count.province && count.city) {
        if (count.county) {
          formState.valueArea = [count.province, count.city, count.county]
        } else {
          formState.valueArea = [count.province, count.city, count.town]
        }
      }
      if (count.province == '四川省' || count.province == '重庆市') {
        formState.expressType = '2'
      }
    }
    return {
      state,
      formRef,
      loadingMore,
      confirmLoading,
      cancel,
      onChange,
      filter,
      edit,
      rules,
      formState,
      onSubmit,
      userName,
      expressOrderModel,
      onOk,
      changeAddress,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
  },
  
});
