/***政治面貌**** */
export const politicalOutlooks = {
    '1':'共青团员',
    '2':'中共党员',
    '4':'中共预备党员',
    '5':'民革会员',
    '6':'民盟盟员',
    '7':'民建会员',
    '8':'民进会员',
    '9':'农工党党员',
    '10':'致公党党员',
    '11':'九三学社社员',
    '12':'台盟盟员',
    '13':'无党派民主人士',
    '0':'群众',
    '3':'其他'
}
/*******婚姻状况 */
export const maritalStatus = {
    '1':'未婚',
    '2':'已婚',
    '3':'离异',
    '0':'丧偶'
}

/*****职务系列**** */
export const jobSery = {
    '1':{
        text:'高等学校教师',
        children:{
            '1-1':'教师',
            '1-2':'讲师',
            '1-3':'助教',
            '1-4':'副教授'
        }
    },
    '2':{
        text:'哲学社会科学研究人员',
        children:{
            '2-1':'研究员',
            '2-2':'助理研究员',
            '2-3':'研究实习员',
            '2-4':'副研究员'
        }
    },
    '3':{
        text:'自然科学研究人员',
        children:{
            '3-1':'研究员',
            '3-2':'助理研究员',
            '3-3':'研究实习员',
            '3-4':'副研究员'
        }
    },
    '4':{
        text:'卫生技术人员',
        children:{
            '4-1':'主任医师',
            '4-2':'主任药师',
            '4-3':'主任护师',
            '4-4':'主任技师',
            '4-5':'主任中医师',
            '4-6':'主任中药师',
            '4-7':'中西医结合主任医师',
            '4-8':'基层卫生主任医师',
            '4-9':'基层卫生主任药师',
            '4-10':'基层卫生主任护师',
            '4-11':'基层卫生主任技师',
            '4-12':'基层主任中医师',
            '4-13':'基层主任中药师',
            '4-14':'基层中西医结合主任医师',
            '4-15':'主治医师',
            '4-16':'主管药师',
            '4-17':'主管护师',
            '4-18':'主管技师',
            '4-19':'主治中医师',
            '4-20':'主管中药师',
            '4-21':'中西医结合主治医师',
            '4-22':'主管医师',
            '4-23':'药师',
            '4-24':'护师',
            '4-25':'技师',
            '4-26':'中医师',
            '4-27':'中药师',
            '4-28':'中西医结合医师',
            '4-29':'副主任医师',
            '4-30':'副主任药师',
            '4-31':'副主任护师',
            '4-32':'副主任技师',
            '4-33':'副主任中医师',
            '4-34':'副主任中药师',
            '4-35':'中西医结合副主任医师',
            '4-36':'基层卫生副主任医师',
            '4-37':'基层卫生副主任药师',
            '4-38':'基层卫生副主任护师',
            '4-39':'基层卫生副主任技师',
            '4-40':'基层副主任中医师',
            '4-41':'基层副主任中药师',
            '4-42':'基层中西医结合副主任医师',
            '4-43':'医士',
            '4-44':'药士',
            '4-45':'护士',
            '4-46':'技士',
            '4-47':'中医士',
            '4-48':'中药士',
            '4-49':'中西医结合医士',
        }
    },
    '5':{
        text:'工程技术人员',
        children:{
            '5-1':'正高级工程师',
            '5-2':'工程师',
            '5-3':'助理工程师',
            '5-4':'高级工程师',
            '5-5':'技术员'
        }
    },
    '6':{
        text:'农业技术人员',
        children:{
            '6-1':'农业技术推广研究员',
            '6-2':'正高级农艺师',
            '6-3':'正高级畜牧师',
            '6-4':'正高级兽医师',
            '6-5':'农艺师',
            '6-6':'畜牧师',
            '6-7':'兽医师',
            '6-8':'助理农艺师',
            '6-9':'助理畜牧师',
            '6-10':'助理兽医师',
            '6-11':'高级农艺师',
            '6-12':'高级畜牧师',
            '6-13':'高级兽医师',
            '6-14':'农业技术员',
            '6-15':'畜牧技术员',
            '6-16':'兽医技术员'
        }
    },
    '7':{
        text:'新闻专业人员',
        children:{
            '7-1':'高级记着',
            '7-2':'高级编辑',
            '7-3':'记着',
            '7-4':'编辑',
            '7-5':'助理记着',
            '7-6':'助理编辑',
            '7-7':'主任记着',
            '7-8':'主任编辑',
        }
    },
    '8':{
        text:'出版专业人员',
        children:{
            '8-1':'编审',
            '8-2':'技术编辑',
            '8-3':'一级校对',
            '8-4':'编辑',
            '8-5':'助理技术编辑',
            '8-6':'二级校对',
            '8-7':'助理编辑',
            '8-8':'副编审',
            '8-9':'技术设计员',
            '8-10':'三级校对',
        }
    },
    '9':{
        text:'图书资料专业人员',
        children:{
            '9-1':'研究馆员',
            '9-2':'馆员',
            '9-3':'助理馆员',
            '9-4':'副研究馆员',
            '9-5':'管理员',
        }
    },
    '10':{
        text:'文物博物专业人员',
        children:{
            '10-1':'研究馆员',
            '10-2':'馆员',
            '10-3':'助理馆员',
            '10-4':'副研究馆员',
        }
    },
    '11':{
        text:'档案专业人员',
        children:{
            '11-1':'研究馆员',
            '11-2':'馆员',
            '11-3':'助理馆员',
            '11-4':'副研究馆员',
            '11-5':'管理员',
        }
    },
    '12':{
        text:'工艺美术专业人员',
        children:{
            '12-1':'正高级工艺美术师',
            '12-2':'工艺美术师',
            '12-3':'助理工艺美术师',
            '12-4':'高级工艺美术师',
            '12-5':'工艺美术员',
        }
    },
    '13':{
        text:'技工院校教师',
        children:{
            '13-1':'正高级讲师',
            '13-2':'正高级实习 指导教师',
            '13-3':'讲师',
            '13-4':'一级实习指导教师',
            '13-5':'助理讲师',
            '13-6':'二级实习指导教师',
            '13-7':'高级讲师',
            '13-8':'高级实习指导教师',
            '13-9':'三级实习指导教师'
        }
    },
    '14':{
        text:'体育专业人员',
        children:{
            '14-1':'国家级教练',
            '14-2':'正高级运动防护师',
            '14-3':'中级教练',
            '14-4':'中级运动防护师',
            '14-5':'初级教练',
            '14-6':'初级运动防护师',
            '14-7':'高级教练',
            '14-8':'高级运动防护师'
        }
    },
    '15':{
        text:'翻译专业人员',
        children:{
            '15-1':'译审',
            '15-2':'二级翻译',
            '15-3':'三级翻译',
            '15-4':'一级翻译'
        }
    },
    '16':{
        text:'广播电视播音人员',
        children:{
            '16-1':'播音编导',
            '16-2':'二级播音员',
            '16-3':'三级播音员',
            '16-4':'一级播音员',
            '16-5':'主任播音员'
        }
    },
    '17':{
        text:'海关专业人员'
    },
    '18':{
        text:'会计人员',
        children:{
            '18-1':'正高级会计师',
            '18-2':'会计师',
            '18-3':'助理会计师',
            '18-4':'高级会计师'
        }
    },
    '19':{
        text:'统计专业人员',
        children:{
            '19-1':'正高级统计师',
            '19-2':'统计师',
            '19-3':'助理统计师',
            '19-4':'高级统计师'
        }
    },
    '20':{
        text:'审计',
        children:{
            '20-1':'正高级审计师',
            '20-2':'审计师',
            '20-3':'助理审计师',
            '20-4':'高级审计师'
        }
    },
    '21':{
        text:'经济专业人员',
        children:{
            '21-1':'正高级经济师',
            '21-2':'正高级人力资源管理师',
            '21-3':'正高级知识产权师',
            '21-4':'经济师',
            '21-5':'人力资源管理师',
            '21-6':'知识产权师',
            '21-7':'助理经济师',
            '21-8':'助理人力资源管理师',
            '21-9':'助理知识产权师',
            '21-10':'高级经济师',
            '21-11':'高级人力资源管理师',
            '21-12':'高级知识产权师'
        }
    },
    '22':{
        text:'实验技术人员',
        children:{
            '22-1':'正高级实验师',
            '22-2':'实验',
            '22-3':'助理实验师',
            '22-4':'高级实验室',
            '22-5':'实验员'
        }
    },
    '23':{
        text:'中等职业学校教师',
        children:{
            '23-1':'正高级讲师',
            '23-2':'正高级实习指导教师',
            '23-3':'讲师',
            '23-4':'一级实习指导教师',
            '23-5':'助理讲师',
            '23-6':'二级实习指导教师',
            '23-7':'高级讲师',
            '23-8':'高级实习指导教师',
            '23-9':'三级实习指导教师'
        }
    },
    '24':{
        text:'中小学教师(含幼儿园)',
        children:{
            '24-1':'正高级教师',
            '24-2':'一级教师',
            '24-3':'二级教师',
            '24-4':'高级教师',
            '24-5':'三级教师'
        }
    },
    '25':{
        text:'艺术专业',
        children:{
            '25-1':'一级编剧',
            '25-2':'一级作词',
            '25-3':'一级作曲',
            '25-4':'一级摄影(摄像)师',
            '25-5':'一级导演(编导)',
            '25-6':'一级艺术创意设计师',
            '25-7':'一级演员',
            '25-8':'一级演出监督',
            '25-9':'一级演奏员',
            '25-10':'一级舞台技术',
            '25-11':'一级指挥',
            '25-12':'一级录音师',
            '25-13':'一级美术师',
            '25-14':'一级剪辑师',
            '25-15':'一级舞美设计师',
            '25-16':'一级艺术研究',
            '25-17':'一级文学创作',
            '25-18':'三级编剧',
            '25-19':'三级作曲',
            '25-20':'三级导演(编导)',
            '25-21':'三级演员',
            '25-22':'三级指挥',
            '25-23':'三级美术师',
            '25-24':'三级舞美设计师',
            '25-25':'三级文学创作',
            '25-26':'三级作词',
            '25-27':'三级摄影(摄像)师',
            '25-28':'三级艺术创意设计师',
            '25-29':'三级演出监督',
            '25-30':'三级舞台技术',
            '25-31':'三级录音师',
            '25-32':'三级剪辑师',
            '25-33':'三级艺术研究',
            '25-34':'三级演奏员',
            '25-35':'四级编剧',
            '25-36':'四级作曲',
            '25-37':'四级导演(编导)',
            '25-38':'四级演员',
            '25-39':'四级演奏员',
            '25-40':'四级指挥',
            '25-41':'四级美术师',
            '25-42':'四级文学创作',
            '25-43':'四级舞美设计师',
            '25-44':'四级作词',
            '25-45':'四级摄影(摄像)师',
            '25-46':'四级艺术创意设计师',
            '25-47':'四级演出监督',
            '25-48':'四级舞台技术',
            '25-49':'四级录音师',
            '25-50':'四级剪辑师',
            '25-51':'四级艺术研究',
            '25-52':'二级编剧',
            '25-53':'二级作曲',
            '25-54':'二级导演(编导)',
            '25-55':'二级演员',
            '25-56':'二级指挥',
            '25-57':'二级美术师',
            '25-58':'二级舞美设计师',
            '25-59':'二级文学创作',
            '25-60':'二级作词',
            '25-61':'二级摄影(摄像)师',
            '25-62':'二级艺术创意设计师',
            '25-63':'二级演出监督',
            '25-64':'二级舞台技术',
            '25-65':'二级录音师',
            '25-66':'二级剪辑师',
            '25-67':'二级艺术研究',
            '25-68':'二级演奏员',
        }
    },
    '26':{
        text:'公证员',
        children:{
            '26-1':'公证员助理',
            '26-2':'一级公证员',
            '26-3':'二级公证员',
            '26-4':'四级公证员',
            '26-5':'三级公证员'
        }
    },
    '27':{
        text:'律师',
        children:{
            '27-1':'律师助理',
            '27-2':'一级律师',
            '27-3':'二级律师',
            '27-4':'四级律师',
            '27-5':'三级律师'
        }
    },
    '28':{
        text:'船舶技术人员',
        children:{
            '28-1':'正高级船长',
            '28-2':'正高级轮机长',
            '28-3':'正高级船舶电子员',
            '28-4':'正高级引航员',
            '28-5':'中级驾驶员',
            '28-6':'中级轮机员',
            '28-7':'中级船舶电子员',
            '28-8':'中级引航员',
            '28-9':'助理驾驶员',
            '28-10':'助理轮机员',
            '28-11':'助理船舶电子员',
            '28-12':'助理引航员',
            '28-13':'高级船长',
            '28-14':'高级轮机长',
            '28-15':'高级船舶电子员',
            '28-16':'高级引航员',
            '28-17':'驾驶员',
            '28-18':'轮机员',
            '28-19':'引航员',
            '28-20':'船舶电子员'
        }
    },
    '29':{
        text:'民用航空飞行技术人员',
        children:{
            '29-1':'正高级飞行员',
            '29-2':'正高级领航员',
            '29-3':'正高级飞行通信员',
            '29-4':'正高级飞行机械员',
            '29-5':'二级飞行员',
            '29-6':'二级领航员',
            '29-7':'二级飞行通信员',
            '29-8':'二级飞行机械员',
            '29-9':'三级飞行员',
            '29-10':'三级领航员',
            '29-11':'三级飞行通信员',
            '29-12':'三级飞行机械员',
            '29-13':'一级飞行员',
            '29-14':'一级领航员',
            '29-15':'一级飞行通信员',
            '29-16':'一级飞行机械员',
        }
    },
    '30':{
        text:'技能人才',
        children:{
            '30-1':'高级技师(国家职业资格)',
            '30-2':'技师(国家职业资格)',
            '30-3':'高级工(国家职业资格)'
        }
    },
    '31':{
        text:'党校教师',
        children:{
            '31-1':'教授',
            '31-2':'正高级讲师',
            '31-3':'讲师',
            '31-4':'助教',
            '31-5':'助理讲师',
            '31-6':'副教授',
            '31-7':'高级讲师',
            '31-8':'教员'
        }
    },
    '32':{
        text:'人力资源管理系列',
        children:{
            '32-1':'正高级人力资源管理师',
            '32-2':'人力资源管理师/经济师',
            '32-3':'助理人力资源管理师',
            '32-4':'高级人力资源管理师'
        }
    },
    '0':{
        text:'无'
    }
}

/****执业资格类型&名称&第一专业&第二专业***** */
export const qualifications = {
    'JS':{
        text:'设计',
        children:{
            'JS-1':{text:'一级注册建筑师'},
            'JS-2':{text:'二级注册建筑师'},
            'JS-3':{text:'一级注册结构师'},
            'JS-4':{text:'二级注册结构师'},
            'JS-5':{text:'环境影响评价工程师'},
            'JS-6':{
                text:'一级造价工程师',
                children:{
                    'JS-6-1':{text:'土木建筑工程'},
                    'JS-6-2':{text:'交通运输工程'},
                    'JS-6-3':{text:'水利工程'},
                    'JS-6-4':{text:'安装工程'},
                    'JS-6-5':{text:'电子信息',children:{
                        'JS-6-5-1':'电子',
                        'JS-6-5-2':'通信信息',
                        'JS-6-5-3':'广播电影电视'
                    }},
                    'JS-6-6':{text:'财经商贸'},
                }
            },
            'JS-7':{
                text:'二级造价工程师',
                children:{
                    'JS-7-1':{text:'土木建筑工程'},
                    'JS-7-2':{text:'交通运输工程'},
                    'JS-7-3':{text:'水利工程'},
                    'JS-7-4':{text:'安装工程'},
                    'JS-7-5':{text:'电子信息',children:{
                        'JS-7-5-1':'电子',
                        'JS-7-5-2':'通信信息',
                        'JS-7-5-3':'广播电影电视'
                    }},
                    'JS-7-6':{text:'财经商贸'},
                }
            },
            'JS-8':{text:'注册测绘师'},
            'JS-9':{text:'注册城乡规划师'},
            'JS-10':{text:'注册电气工程师(发输变电)'},
            'JS-11':{text:'注册电气工程师(供配电)'},
            'JS-12':{text:'注册公用设备工程师(动力)'},
            'JS-13':{text:'注册公用设备工程师（给水排水）'},
            'JS-14':{text:'注册公用设备工程师（暖通空调）'},
            'JS-15':{text:'注册化工工程师'},
            'JS-16':{text:'注册环保工程师'},
            'JS-17':{text:'注册机械工程师'},
            'JS-18':{text:'注册土木工程师(水利水电工程)'},
            'JS-19':{text:'注册土木工程师(岩土)'},
            'JS-20':{text:'注册土木工程师(港口与航道工程)'},
            'JS-21':{text:'注册土木工程师(道路工程)'},
            'JS-22':{
                text:'监理工程师',
                children:{
                    'JS-22-1':{text:'房屋建筑工程'},
                    'JS-22-2':{text:'冶炼工程'},
                    'JS-22-3':{text:'矿石工程'},
                    'JS-22-4':{text:'化工石油工程'},
                    'JS-22-5':{text:'水利水电工程'},
                    'JS-22-6':{text:'电力工程'},
                    'JS-22-7':{text:'农林工程'},
                    'JS-22-8':{text:'铁路工程'},
                    'JS-22-9':{text:'公路工程'},
                    'JS-22-10':{text:'港口与航道工程'},
                    'JS-22-11':{text:'航空航天工程'},
                    'JS-22-12':{text:'通信工程'},
                    'JS-22-13':{text:'市政公用工程'},
                    'JS-22-14':{text:'机电安装工程'},

                }
            },
            'JS-23':{
                text:'一级建造师',
                children:{
                    'JS-23-1':{text:'建筑工程'},
                    'JS-23-2':{text:'公路工程'},
                    'JS-23-3':{text:'铁路工程'},
                    'JS-23-4':{text:'民航机场工程'},
                    'JS-23-5':{text:'水利水电工程'},
                    'JS-23-6':{text:'港口与航道工程'},
                    'JS-23-7':{text:'市政公用工程'},
                    'JS-23-8':{text:'通信与广电工程'},
                    'JS-23-9':{text:'矿业工程'},
                    'JS-23-10':{text:'机电工程'},
                }
            },
            'JS-24':{
                text:'二级建造师',
                children:{
                    'JS-24-1':{text:'建筑工程'},
                    'JS-24-2':{text:'公路工程'},
                    'JS-24-3':{text:'水利水电工程'},
                    'JS-24-4':{text:'市政公用工程'},
                    'JS-24-5':{text:'矿业工程'},
                    'JS-24-6':{text:'机电工程'},
                }
            },
            'JS-25':{
                text:'咨询工程师(投资)',
                children:{
                    'JS-25-1':{text:'交通运输',children:{
                        'JS-25-1-1':'铁路',
                        'JS-25-1-2':'公路',
                        'JS-25-1-3':'城市轨道交通',
                        'JS-25-1-4':'民航',
                        'JS-25-1-5':'港口与航道',
                    }},
                    'JS-25-2':{text:'水利水电',children:{
                        'JS-25-2-1':'水利',
                        'JS-25-2-2':'水电'
                    }},
                    'JS-25-3':{text:'电子信息',children:{
                        'JS-25-3-1':'电子',
                        'JS-25-3-2':'通信信息',
                        'JS-25-3-3':'广播电影电视'
                    }},
                    'JS-25-4':{text:'农林',children:{
                        'JS-25-4-1':'农牧渔业',
                        'JS-25-4-2':'林业'
                    }},
                    'JS-25-5':{text:'采矿和矿物加工',children:{
                        'JS-25-5-1':'冶金矿山',
                        'JS-25-5-2':'非金属矿山',
                        'JS-25-5-3':'煤炭',
                        'JS-25-5-4':'钢铁',
                        'JS-25-5-5':'有色冶金',
                        'JS-25-5-6':'黄金',
                        'JS-25-5-7':'建筑材料'
                    }},
                    'JS-25-6':{text:'石油',children:{
                        'JS-25-6-1':'石油',
                        'JS-25-6-2':'天然气的开发',
                        'JS-25-6-3':'开采工程'
                    }},
                    'JS-25-7':{text:'化工与制药',children:{
                        'JS-25-7-1':'化工',
                        'JS-25-7-2':'医药',
                        'JS-25-7-3':'石油加工'
                    }},
                    'JS-25-8':{text:'发送变电',children:{
                        'JS-25-8-1':'火力发电',
                        'JS-25-8-2':'风力发电',
                        'JS-25-8-3':'新能源',
                        'JS-25-8-4':'送变电工程'
                    }},
                    'JS-25-9':{text:'核工程',children:{
                        'JS-25-9-1':'核工业',
                        'JS-25-9-2':'核电'
                    }},
                    'JS-25-10':{text:'机械',children:{
                        'JS-25-10-1':'机械',
                        'JS-25-10-2':'船舶',
                        'JS-25-10-3':'兵器',
                        'JS-25-10-4':'航空',
                        'JS-25-10-5':'航天'
                    }},
                    'JS-25-11':{text:'轻工',children:{
                        'JS-25-11-1':'轻工',
                        'JS-25-11-2':'食品'
                    }},
                    'JS-25-12':{text:'纺织',children:{
                        'JS-25-12-1':'纺织',
                        'JS-25-12-2':'化纤'
                    }},
                    'JS-25-13':{text:'环境工程'},
                    'JS-25-14':{text:'建筑市政'},
                    'JS-25-15':{text:'工程技术经济管理'}
                }
            },
            'JS-26':{text:'施工员'},
            'JS-27':{text:'质量员'},
            'JS-28':{text:'安全员'},
            'JS-29':{text:'标准员'},
            'JS-30':{text:'材料员'},
            'JS-31':{text:'机械员'},
            'JS-32':{text:'劳务员'},
            'JS-33':{text:'资料员'},
            'JS-34':{text:'监理员'},
            'JS-35':{text:'水利部造价工程师'},
            'JS-0':{text:'其他'}
        }
    },
    'JZ':{text:'建造'},
    'JL':{
        text:'监理',
        children:{
            'JL-1':{text:'监理工程师',children:{
                'JL-1-1':{text:'房屋建筑工程'},
                'JL-1-2':{text:'冶炼工程'},
                'JL-1-3':{text:'矿山工程'},
                'JL-1-4':{text:'化工石油工程'},
                'JL-1-5':{text:'水利水电工程'},
                'JL-1-6':{text:'电力工程'},
                'JL-1-7':{text:'农林工程'},
                'JL-1-8':{text:'铁路工程'},
                'JL-1-9':{text:'公路工程'},
                'JL-1-10':{text:'港口与航道工程'},
                'JL-1-11':{text:'航天航空工程'},
                'JL-1-12':{text:'通信工程'},
                'JL-1-13':{text:'市政公用工程'},
                'JL-1-14':{text:'机电安装工程'}
            }},
            'JL-2':{text:'监理员'}
        }
    },
    'JC':{text:'检测'},
    'SG':{
        text:'施工',
        children:{
            'SG-1':{
                text:'一级造价工程师',
                children:{
                    'SG-1-1':{text:'土木建筑工程'},
                    'SG-1-2':{text:'交通运输工程'},
                    'SG-1-3':{text:'水利工程'},
                    'SG-1-4':{text:'安装工程'},
                    'SG-1-5':{text:'电子信息',children:{
                        'SG-1-5-1':'电子',
                        'SG-1-5-2':'通信信息',
                        'SG-1-5-3':'广播电影电视'
                    }},
                    'SG-1-6':{text:'财经商贸'}
                }
            },
            'SG-2':{
                text:'二级造价工程师',
                children:{
                    'SG-2-1':{text:'土木建筑工程'},
                    'SG-2-2':{text:'交通运输工程'},
                    'SG-2-3':{text:'水利工程'},
                    'SG-2-4':{text:'安装工程'},
                    'SG-2-5':{text:'电子信息',children:{
                        'SG-2-5-1':'电子',
                        'SG-2-5-2':'通信信息',
                        'SG-2-5-3':'广播电影电视'
                    }},
                    'SG-2-6':{text:'财经商贸'}
                }
            },
            'SG-3':{
                text:'一级建造师',
                children:{
                    'SG-3-1':{text:'建筑工程'},
                    'SG-3-2':{text:'公路工程'},
                    'SG-3-3':{text:'铁路工程'},
                    'SG-3-4':{text:'民航机场工程'},
                    'SG-3-5':{text:'港口与航道工程'},
                    'SG-3-6':{text:'水利水电工程'},
                    'SG-3-7':{text:'市政公用工程'},
                    'SG-3-8':{text:'通信与广电工程'},
                    'SG-3-9':{text:'矿业工程'},
                    'SG-3-10':{text:'机电工程'}
                }
            },
            'SG-4':{
                text:'二级建造师',
                children:{
                    'SG-3-1':{text:'建筑工程'},
                    'SG-3-2':{text:'公路工程'},
                    'SG-3-3':{text:'水利水电工程'},
                    'SG-3-4':{text:'市政公用工程'},
                    'SG-3-5':{text:'矿业工程'},
                    'SG-3-6':{text:'机电工程'}
                }
            },
            'SG-5':{text:'施工员'},
            'SG-6':{text:'质量员'},
            'SG-7':{text:'安全员'},
            'SG-8':{text:'标准员'},
            'SG-9':{text:'材料员'},
            'SG-10':{text:'机械员'},
            'SG-11':{text:'劳务员'},
            'SG-12':{text:'资料员'}
        }
    },
    'QT':{text:'其他',children:{'QT-1':{text:'其他'}}}
}