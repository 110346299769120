import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fa81a34"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: { textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"text-align":"left"} }
const _hoisted_5 = {
  key: 0,
  style: {"margin-bottom":"3px"}
}
const _hoisted_6 = {
  key: 1,
  style: {"margin-bottom":"3px"}
}
const _hoisted_7 = {
  key: 2,
  style: {"margin-bottom":"3px"}
}
const _hoisted_8 = {
  key: 3,
  style: {"margin-bottom":"3px"}
}
const _hoisted_9 = {
  key: 4,
  style: {"margin-bottom":"3px"}
}
const _hoisted_10 = {
  key: 5,
  style: {"margin-bottom":"3px"}
}
const _hoisted_11 = {
  key: 6,
  style: {"margin-bottom":"3px"}
}
const _hoisted_12 = { class: "bnt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_AcademicTitleModal = _resolveComponent("AcademicTitleModal")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_back_top = _resolveComponent("a-back-top")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { bordered: false }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, {
        spinning: _ctx.confirmLoading,
        tip: "稍等片刻..."
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            layout: "horizontal",
            model: _ctx.formState,
            ref: "formRef",
            labelCol: _ctx.labelCol,
            wrapperCol: _ctx.wrapperCol
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "职称信息",
                name: "dataSource"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_list, {
                    class: "demo-loadmore-list",
                    "item-layout": "horizontal",
                    "data-source": _ctx.formState.dataSource,
                    loading: _ctx.loading
                  }, {
                    loadMore: _withCtx(() => [
                      (!_ctx.disabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              ghost: "",
                              onClick: _ctx.handleAdd
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("添加职称信息")
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    renderItem: _withCtx(({ item }) => [
                      _createVNode(_component_a_list_item, { style: {"flex-direction":"column","align-items":"stretch","flex-wrap":"nowrap"} }, _createSlots({
                        default: _withCtx(() => [
                          _createVNode(_component_a_list_item_meta, null, {
                            title: _withCtx(() => [
                              _createElementVNode("a", null, _toDisplayString(_ctx.professionaltitle[item.major]), 1)
                            ]),
                            avatar: _withCtx(() => [
                              _createVNode(_component_a_image, {
                                width: 60,
                                src: item.certificate
                              }, null, 8, ["src"])
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("div", _hoisted_4, [
                            (item.certificateName)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_5, "扫描件名称：" + _toDisplayString(item.certificateName), 1))
                              : _createCommentVNode("", true),
                            (item.certificateNumber)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_6, "职称证书编号：" + _toDisplayString(item.certificateNumber), 1))
                              : _createCommentVNode("", true),
                            (item.isHighest)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_7, "是否最高：" + _toDisplayString(item.isHighest), 1))
                              : _createCommentVNode("", true),
                            (item.qualificationCode)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_8, "执业资格或注册证书编号：" + _toDisplayString(item.qualificationCode), 1))
                              : _createCommentVNode("", true),
                            (item.title)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_9, "职称级别：" + _toDisplayString(item.title), 1))
                              : _createCommentVNode("", true),
                            (item.province)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_10, "职称登记省份：" + _toDisplayString(item.province), 1))
                              : _createCommentVNode("", true),
                            (item.city)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_11, "职称登记城市：" + _toDisplayString(item.city), 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, [
                        (!_ctx.disabled)
                          ? {
                              name: "actions",
                              fn: _withCtx(() => [
                                _createElementVNode("a", {
                                  onClick: ($event: any) => (_ctx.handleEidt(item))
                                }, "修改", 8, _hoisted_2),
                                _createElementVNode("a", {
                                  onClick: ($event: any) => (_ctx.handleDelete(item))
                                }, "删除", 8, _hoisted_3)
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1024)
                    ]),
                    _: 1
                  }, 8, ["data-source", "loading"])
                ]),
                _: 1
              }),
              (!_ctx.disabled)
                ? (_openBlock(), _createBlock(_component_a_form_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_a_button, {
                          style: {"width":"40%"},
                          type: "primary",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toNextTab(7)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("上一步")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_button, {
                          style: {"width":"40%"},
                          type: "primary",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toNextTab(9)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("提交")
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "labelCol", "wrapperCol"]),
          _createVNode(_component_AcademicTitleModal, {
            ref: "modalForm",
            onOk: _ctx.modalFormOk
          }, null, 8, ["onOk"])
        ]),
        _: 1
      }, 8, ["spinning"]),
      _createVNode(_component_a_back_top)
    ]),
    _: 1
  }))
}