
import { PropType, defineComponent, toRefs, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { EDIT_USER } from "@/api/hrUser";
import { hrUserFormState } from "@/views/HrUser/data";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "请等待工作人员审核通过.",
    },
  },
  setup(props) {
    const { title } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    function out() {
      store.dispatch("Logout").then((res) => {
        message.success("退出成功");
        router.go(-1);
      });
    }
    function refresh() {
      loading.value = true;
      setTimeout(() => {
        message.success("刷新成功");
        loading.value = false;
      }, 1000);
    }
    return {
      out,
      refresh,
      loading,
      title,
    };
  },
});
