
import { defineComponent, reactive, ref, UnwrapRef, createVNode, toRaw } from 'vue';
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { message as Message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { massTexting } from '@/api/hrUser'

type FormState = {
  content: string
}
export default defineComponent({
  name: 'MassTexting',
  mixins: [],
  props: {
  },
  components: {
  },
  setup(props, context) {
    const confirmLoading = ref(false)
    const rules = {
      content: [{ required: true, message: "请输入群发内容" }],
    };
    const formState: UnwrapRef<FormState> = reactive({
      content: '',
    });
    const formRef = ref()
    const showConfirm = () => {
      Modal.confirm({
        title: '确认群发此信息吗?',
        icon: createVNode(ExclamationCircleOutlined),
        content: '群发内容：' + formState.content,
        okText: '发送',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          massTexting(toRaw(formState)).then(res => { 
            Message.success('群发成功！')
            confirmLoading.value = false
          }).catch(err => {
            console.log(err);
            Message.warning('群发失败！')
            confirmLoading.value = false
          })
        },
        onCancel() {
          console.log('Cancel');
          confirmLoading.value = false
        },
      });
    };
    function onSubmit() {
      formRef.value.validate().then(async () => {
        confirmLoading.value = true
        showConfirm()
        
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log("error", error);
      });
    }
    return {
      rules,
      formState,
      formRef,
      confirmLoading,
      onSubmit,
      showConfirm,
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };
  },
  
});
