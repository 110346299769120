import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "物流信息",
    visible: _ctx.loadingMore,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.loadingMore) = $event)),
    onCancel: _ctx.cancel,
    onOk: _ctx.onSubmit,
    okText: "修改",
    maskClosable: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.confirmLoading }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.formState,
            rules: _ctx.rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "收件人姓名",
                name: "receiveName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.receiveName,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.receiveName) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "收件人电话",
                name: "receivePhone"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.receivePhone,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.receivePhone) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "收件人所在省市区",
                name: "valueArea"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_cascader, {
                    value: _ctx.formState.valueArea,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.valueArea) = $event)),
                    options: _ctx.pcaaData,
                    onChange: _ctx.onChange,
                    "show-search": { filter: _ctx.filter },
                    placeholder: "省市区选择"
                  }, null, 8, ["value", "options", "onChange", "show-search"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "收件人详细地址",
                name: "receiveAddress"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.receiveAddress,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.receiveAddress) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                name: "jpPageRange",
                label: "快递产品类型"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.expressType,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.expressType) = $event)),
                    placeholder: "请选择快递产品类型"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: "1" }, {
                        default: _withCtx(() => [
                          _createTextVNode("顺丰特快")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: "2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("顺丰标快")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                name: "payType",
                label: "付款方式"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.payType,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.payType) = $event)),
                    placeholder: "请选择付款方式"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("寄付现结")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 3 }, {
                        default: _withCtx(() => [
                          _createTextVNode("到付")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["visible", "onCancel", "onOk"]))
}