
import '@/assets/less/TableExpand.less'

import { 
  SmileOutlined, 
  DownOutlined, 
  PrinterOutlined, 
  SearchOutlined, 
  ReloadOutlined, 
  ArrowLeftOutlined,
  ExclamationCircleOutlined, 
  CheckCircleOutlined,
  PlusOutlined,
  UploadOutlined
  
} from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, toRef, reactive, createVNode,watch} from 'vue';
import { selectAllCompanyList,downloadInformation } from '@/api/manage'
import segmentationXQ from './modules/segmentationXQ.vue'
import{
getSplitASingleList,
getGenerateBillList,
getinvoiceSplittingBillPdf,
setSplittingBillPdf,
getParsingPdf,
editPDF,
deletePDF,
getInvoiceList,
ParsingTheInvoice,
GenerateSplitSheetFromInvoice,
editInvoice,
deleteInvoice,
editfgd,
getSplitSheetDetails,
}from '@/api/hrUser'
import { getToken } from '@/utils/auth'
import {useStore} from 'vuex'
import { message, message as Message, Modal } from 'ant-design-vue'
import { pastTime } from '@/utils/index'
import { useRouter } from 'vue-router'
import { FileInfo } from '../HrUser/data';
import { reject } from 'lodash';
import {baseURL} from '@/config/index';
import moment from 'moment'
const columns = [
  {
    title: '#',
    dataIndex: '',
    key:'rowIndex',
    width:60,
    align:"center",
    customRender:function ({text,record,index}) {
      return parseInt(index)+1;
    }
  },
  {
    title: '公司名称',
    dataIndex: 'acceptCompanyName',
    key: 'acceptCompanyName',
    align:"center",
    resizable: true,
  },
  {
    title:'公司地址',
    align:"center",
    dataIndex: 'acceptCompanyAddress',
    resizable: true,
    //  customRender:function ({text,record,index}) {
    //    if(text=='1'){
    //      return '社保'
    //    }else{
    //      return '公积金'
    //    }
    // }
  },
    {
    title:'所属年月',
    align:"center",
    dataIndex: 'belongYear',
    // resizable: true,
    slots: { customRender: 'belongYear' }
  },
    {
    title:'类型',
    align:"center",
    dataIndex: 'type',
    resizable: true,
     customRender:function ({text,record,index}) {
       if(text==1){
         return '社保'
       }else if(text==2){
         return '公积金'
       }else{
         return '房租水电发票'
       }
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    align:"center",
    fixed:"right",
    width:160,
    slots: { customRender: 'action' }
  }
];

export default defineComponent({
  name: 'Segmentation',
  mixins: [],
  props: {
  },
  setup(props, context) {
    const uploadurl=ref<any>(baseURL+'/sfsj-server/sys/oss/api/uploadRequest')
    const segmentationXQShow=ref<boolean>(false)
    const fengedanDetails=ref<any>()
    const actionUrl=ref<any>()
    const uploadvisible=ref<boolean>(false)
    const acceptCompanyName=ref<any>()
    const bjshow=ref<boolean>(false)
    const formID=ref<any>()
    const imgListRadio=ref<number>()
    const healthInsuranceListRadio=ref<number>()
    const router = useRouter();
    const pageNo = ref<number>(1);
    const urlTime = ref<number>(0);
    const printAppoint = ref(null);
    const visible=ref<boolean>(false)
    const modelTitle=ref<string>('请选择上传数据')
    const fileUrl=ref<string>('')
    const previewImage=ref<string>('')
    const companyList=ref<any>([])
    const companyData=ref<string>('')
    const socialSecurityData=<any>({}) //当前选中的社保数据
    const healthInsuranceData=<any>({})//当前选中的医保数据
    const SelectCompanyId=ref<string>('')//当前选中公司的Id
    const imgList=ref<any>()//社保图片数据
    const healthInsuranceimgList=ref<any>()//医保图片数据
    const accumulationimgList=ref<any>()//公积金图片数据
    const houseRentimgList=ref<any>()//发票图片数据
    const billDate=ref<any>()
    const bjshowId=ref<any>()
    const value1=ref<any>()
    const uploadfileList=ref<any>()
    const fgdbjshow=ref<any>()
    const fengemoney=ref<any>()
    const butJy=ref<any>(false)
    const SelectInvoiceList=ref<any>([])
    const checked=ref<boolean>(false)
    const state = reactive({
      queryParam: {
        invoiceCode: '',
        invoiceState: '',
        invoiceType: '',
      },
       headers: {
        'X-Access-Token': getToken()
      },
      uoloadData:{
        category:'',
        companyId:'',
        date:'',
        id:''
      },
      downloadData:{
        category:'',
        companyId:'',
        date:'',
      },
      fileList:{
       socialSecurity:[], //社保
       healthInsurance:[], //医保
       accumulationFund:[], //公积金
       houseRent:[] //房租水电
      },
      SplitSheetDetails:null,//分割单详情
      editData:{  //编辑PDF数据
       correctAmount:'',//修正总金额
       date:'', //时间
       serialNumber:'',//编号
      },
      fengeData:{ //分割数据
      splitAmount:0,//分割金额
      splitReason:''//分割原因
      }
    });
    const uploadTypeNum=ref<number>(1)
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    const previewVisible=ref<boolean>(false)
    const typeNum=ref<number>(1)
    const yearQuarter=ref<any>()
    const yearShowOne=ref<any>()
    const splitResonStr=ref<any>()
    const pagination = computed(() => ({
      current: pageNo.value,
      pageSize: pageSize.value,
      pageSizeOptions: ['10', '20', '30'],
      showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: total.value
    }));
    const to = (path) => {
      if (path) {
        router.push({
          path: path
        })
      } else {
        router.go(-1)
      }
    };

    // 上传数据
    const handleChange = (info: any,flag:any) => {
      console.log(info,'infoinfoinfo')
      // imgList.value=info.fileList
      // if (info.file.status == 'uploading') {
      //   loading.value = true
      //   return
      // }
        // loading.value = true
      if(info.file.status == 'done'){
        loading.value = false

        message.success('上传成功')

      }
      if (info.file.status == 'uploading' ) {
        loading.value = false
        // visible.value=false
           /* 
            //    flag=1 社保
            //    flag=2 医保
            //    flag=3 公积金
            //    flag=4 房租水电
                 //    */
                //  info.file.forEach((e,i)=>{
                //    if(e.status=='error'){
                //      info.file.splice(i,1)
                //    }
                //  })
         if(flag==1){
        imgList.value=info.fileList
        }else if(flag==2){
          healthInsuranceimgList.value=info.fileList
        }else if(flag==3){
          accumulationimgList.value=info.fileList
        }else if(flag==4){
         houseRentimgList.value=info.fileList
        }
      console.log(info,'kkkkkk')
  
      }
      if(info.file.status=="removed"){
         if(flag==1){
        imgList.value=info.fileList
        }else if(flag==2){
          healthInsuranceimgList.value=info.fileList
        }else if(flag==3){
          accumulationimgList.value=info.fileList
        }else if(flag==4){
         houseRentimgList.value=info.fileList
        }
        message.success('删除成功!')
      }
      if (info.file.status === 'error') {
        loading.value = false
        message.error('上传失败')
      }
    }
    //上传文件
    function uploadhandleChange(info: any,flag:any){
      if(info.file.status=="done"){
        message.success('上传成功!')
      }else if(info.file.status=="uploading"){
        uploadfileList.value=info.fileList

      }
      else if(info.file.status=="error"){
        message.error('上传失败!')
      }else if(info.file.status=="removed"){
        uploadfileList.value=info.fileList
        message.success('删除成功!')
      }
     console.log(info,'info');
     console.log(flag,'flag');
     
    }
    // 翻页
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
      getContractPrintOrderList()
    }
    // 重置搜索
    const searchReset = () => {
      state.queryParam = {
        invoiceCode: '',
        invoiceState: '',
        invoiceType: '',
      }
      getContractPrintOrderList(1)
    }
   
    // 搜索
    const searchQuery = () => {
      getContractPrintOrderList(1)
    }

    //控制新增弹窗
    const showModal=(scope,num)=> {
      getGenerateBillLists()

      if(typeNum.value==1||typeNum.value==2){
       //获取公司列表
      }else if(typeNum.value==3){

      //  getInvoiceLists()
      }
    
      // console.log(scope,'scope');
      // window.open(scope.fileUrl)
      if(typeNum.value==1){
        modelTitle.value='社保医保'
      }else if(typeNum.value==2){
        modelTitle.value='公积金'
      }else{
        modelTitle.value='房租水电发票'
      }
 
      visible.value = true;
    }

    //详情弹窗
    function showdetailsModal(scope,num){
  console.log(scope,num,'详情弹窗');
  
    }
    function handleCancel() {
        this.previewVisible = false;
      }
    //预览
    function  handlePreview (file) {
      console.log(file.url,'filefilefile');
        this.previewImage = file.url 
        this.previewVisible = true
      }
    // 下载社保公积金数据
    // function  handleDownloadInformation(){
    //  downloadInformation(state.downloadData).then(res=>{
    //   fileUrl.value=res.fileUrl
    //   message.success('下载成功!')
    //   window.open(fileUrl.value)
    //   loading.value = false
    //   visible.value=false
    // }).catch(err=>{
    //   message.error('下载失败!')
    //   loading.value = false
    // })
    // }
    
    //选择公司
    function  selectList(i,e){
      companyData.value=i
      // state.uoloadData.category=i  
      // state.downloadData.category=i
      console.log(e,'eeeeeeeeeeeee')
      if(e){
      SelectCompanyId.value=e.companyId as any
   
      }else{
        SelectCompanyId.value=''
        state.SplitSheetDetails=null
      }
   
    
    }

    //选择公司下拉框搜索
      const filterOption = (input: string, option: any) => {
           return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    //编辑时间
    function EditChangeDate(i,e){
    console.log(e,123);
    state.editData.date=e
    }
    //选择时间
    function onChangeDate(e){
      console.log(1);
      
     e = moment(e).format('YYYY-MM')
     if(typeNum.value==1||typeNum.value==2){
    getinvoiceSplittingBillPdfs(e)

     }else if(typeNum.value==3){
     getInvoiceLists(e)
     
     }
     imgList.value=null
     healthInsuranceimgList.value=null
     accumulationimgList.value=null
     
      billDate.value=e
      yearShowOne.value = false;

      state.uoloadData.date=e
      state.downloadData.date=e
    }
    //取消上传弹框
    function cancelModal(){
      butJy.value=false
      visible.value=false
      bjshow.value=false
      state.fengeData={
        splitAmount:0,
        splitReason:''
      }
      state.SplitSheetDetails=null
      imgList.value=null
      accumulationimgList.value=null
      healthInsuranceimgList.value=null
      houseRentimgList.value=null
      billDate.value=''
      state.fileList.accumulationFund=[]
      state.fileList.healthInsurance=[]
      state.fileList.houseRent=[]
      state.fileList.socialSecurity=[]
      splitResonStr.value=''
      fengemoney.value=''
      SelectInvoiceList.value=[]
      getContractPrintOrderList(1)

        // state.uoloadData = {
        //   category:'',
        //   companyId:'',
        //   date:'',
        //   id:''
        // }
    }
    //取消上传弹框
    function uploadcancelModal(){
      uploadvisible.value=false
      uploadfileList.value=[]
    }
   
    // 列表获取
    const getContractPrintOrderList = async (page?: number) => {
      if (page) {
        pageNo.value = page
      }
      loading.value = true
      let paramse = {
        pageNo: pageNo.value,
        pageSize:pageSize.value,
        type:typeNum.value,
        acceptCompanyName:acceptCompanyName.value?'*'+acceptCompanyName.value+'*':'',
        
        // UserId:router.currentRoute.value.query.UserId,
        // category:state.queryParam.invoiceType||''
      }
      getSplitASingleList(paramse).then(res=> {
        console.log(res,'resresres');
        total.value = res.total
        dataSource.value =res.records
        loading.value = false
      }).catch(err=> {
        Message.error('请求失败')
        dataSource.value =err.records
        loading.value = false
 
        
      })
    }
    //获取所有社保或医保或公积金的PDF
    function getinvoiceSplittingBillPdfs(date){
         let month=''
         let year=''
      if(date){
        year=date.substr(0,4)
       if(date.substr(-2)>9){
          month=date.substr(-2)
       }else{
         month=date.substr(-1)
       }
      }
       
      let paramse = {
        type:typeNum.value,
        month,
        year,
      }
getinvoiceSplittingBillPdf(paramse).then(res=>{
  state.SplitSheetDetails=null
  state.fileList={
      socialSecurity:[], //社保
       healthInsurance:[], //医保
       accumulationFund:[], //公积金
       houseRent:[] //房租水电
  }
  console.log(res,'resssss')
   res.forEach((e,i) => {
    // e.totalAmount=(e.totalAmount/100)
  
    
     if(e.type==1){
       state.fileList.socialSecurity.push(e)
     }else if(e.type==2){
       state.fileList.healthInsurance.push(e)
     }else if(e.type==3){
       state.fileList.accumulationFund.push(e)
     }
       
     });
     if(date){
  
    }else{
        state.SplitSheetDetails=null

     }
    
   console.log( state.fileList.socialSecurity,'7788')
})
    }

    //切换tab事件函数
   function callback(e){
     state.fileList={
      socialSecurity:[], //社保
       healthInsurance:[], //医保
       accumulationFund:[], //公积金
       houseRent:[] //房租水电
  }
     typeNum.value=e
        getContractPrintOrderList(1)

      console.log(e,122222)
    }
    //获取下拉选择框公司数据
    function  getGenerateBillLists(){
       let paramse = {
        // pageNo: pageNo.value,
        // pageSize:999,
        // type:typeNum.value
        // UserId:router.currentRoute.value.query.UserId,
        // category:state.queryParam.invoiceType||''
      }
     getGenerateBillList(paramse).then(res=>{
       res.forEach((e,i) => {
         companyList.value[i]={value:e.name,label:e.name,companyId:e.nameid}
       });
  // companyList.value=res.records

     })
    }
   function getSplitSingle(){

   }
   //生成分割单
   function setSplittingBillPdfs(paramse){
  //     if(state.SplitSheetDetails){
  //   message.error('请勿重复生成!')
  //   return
  // } 
  console.log(paramse,'生成分割单参数');
  
    //  state.SplitSheetDetails=null
     setSplittingBillPdf(paramse).then(res=>{
     console.log(res);
     if(res){
    // splitResonStr.value=res.splitReson+(res.splitAmount/100)+'元'
    splitResonStr.value=res.splitReson
     state.SplitSheetDetails=res
     
     message.success('生成成功!')
     }else{
     message.error('生成失败!')
     }
     console.log(state.SplitSheetDetails,'state.SplitSheetDetails');
     
     
     })
   }

   //删除已上传的文件
   function handleFileRemove(e,flag){
     console.log(e,flag,'eeeeeeeeeeeeee');
     if(flag==1){
    imgList.value.forEach((element,index)=>{
       if(e.response.result.id==element.response.result.id ||e.status=='error'){
      imgList.value.splice(index,1)
       }
     })
     }else if(flag==2){
    healthInsuranceimgList.value.forEach((element,index)=>{
       if(e.response.result.id==element.response.result.id ||e.status=='error'){
      healthInsuranceimgList.value.splice(index,1)
       }
     })
     }else if(flag==3){
    accumulationimgList.value.forEach((element,index)=>{
       if(e.response.result.id==element.response.result.id ||e.status=='error' ){
      accumulationimgList.value.splice(index,1)
       }
     })
     }else if(flag==4){
    houseRentimgList.value.forEach((element,index)=>{
       if(e.response.result.id==element.response.result.id ||e.status=='error' ){
      houseRentimgList.value.splice(index,1)
       }
     })
     }

     
     console.log(imgList.value,'imgList.value22222222222');

  //  imgList.value
   }
   //预览已上传的文件
   function handleFilePreview(e){
   console.log(e,'66ee');
   window.open(e.response.result.url)

   }
   //提交PDF
   function submitOkBut(flag){
    //  console.log(imgList.value,'imgList.value');

     let imgListurls =<any>[]
     let healthInsuranceimgListurls =<any>[]
     let accumulationimgListurls=<any>[]
     let houseRentimgListimgListurls=<any>[]
     let urls=[]
     if(flag==1){
     imgList.value.forEach(e=>{
     imgListurls.push(e.response.result.url)
     urls=imgListurls
      })
     }else if(flag==2){
       healthInsuranceimgList.value.forEach(e=>{
     healthInsuranceimgListurls.push(e.response.result.url)
     urls=healthInsuranceimgListurls
     })}else if(flag==3){
      accumulationimgList.value.forEach(e=>{
      accumulationimgListurls.push(e.response.result.url)
      urls=accumulationimgListurls
     })}else if(flag==4){
      houseRentimgList.value.forEach(e=>{
      houseRentimgListimgListurls.push(e.response.result.url)
      urls=houseRentimgListimgListurls
     })}

     
   
     let obj={
         type:flag,
         urls
     }
     loading.value=true
     console.log();
     if(flag==4){
      //  let objurl={
      //    urls
      //  }
       ParsingTheInvoice(urls).then(res=>{
        if(res){
        loading.value=false

        message.success('提交成功!')
        console.log(res,'提交发票');
        getInvoiceLists(billDate.value)
        houseRentimgList.value=null
        bjshow.value=false
        }
      
       })
     }else{
       getParsingPdf(obj).then(res=>{
      console.log(res,'s');
      if(res){
        loading.value=false
        message.success('提交成功!')
        // billDate.value=''
        getinvoiceSplittingBillPdfs(billDate.value)
        if(flag==1){
        imgList.value=null
        }else if(flag==2){
        healthInsuranceimgList.value=null
        }else if(flag==3){
          accumulationimgList.value=null
        }
        
      }

     })
     }
     
    
   
   }
   //编辑按钮
   function bjshowBut(item){
    console.log(item,'item');
    bjshow.value=bjshow.value?false:true
    bjshowId.value=item.id
    state.editData.correctAmount=item.correctAmount/100 as any || item.totalAmount/100 as any 
    state.editData.date=item.date
    state.editData.serialNumber=item.serialNumber
   }
   //分割单编辑按钮
   function fgdbjshowBut(){
    fgdbjshow.value=!fgdbjshow.value
    
   }
   //分割单确定编辑按钮
   function editFgdPDFs(){
     console.log(1);
     editfgds()
     
   }
   //编辑分割单方法
   function editfgds(){
     state.SplitSheetDetails.splitAmount=fengemoney.value*100
     if(typeNum.value==3){
       console.log(123);
       
     invoiceOkBut(1)

     }else{
     GeneratePartitionSheetBut()

     }
     fgdbjshow.value=!fgdbjshow.value

   }

   //展示列表编辑分割单按钮
   function editfengedan(e){

    //  getSplitSheetDetails({id:e.id}).then(res=>{
    //   console.log(res,'列表');
    //    fengedanDetails.value=res
    //  }).catch(err=>{
    //    message.error('服务器繁忙!')
    //  })
     
    fengedanDetails.value=e
     segmentationXQShow.value=true
    //  this.$refs.sgmXQ.visible=true
    console.log(e,'liebiaobianji');
    
   }
   //编辑PDF
   function editPDFs(item){
     console.log(item,'item');
     let billPDf={
       ...item
     }
     billPDf.date=state.editData.date+' 00:00:00'
     billPDf.correctAmount=(state.editData.correctAmount as any)*100 
     editPDF(billPDf).then(res=>{
       if(res=='修正成功'){
       message.success(res)
       getinvoiceSplittingBillPdfs(billDate.value)
       }else{
       message.error(res)
       }
       bjshow.value=false


     })
   }
   //编辑发票信息
   function editInvoices(item){
     let billPDf={
       ...item
     }
     billPDf.date=state.editData.date+' 00:00:00'
     billPDf.correctAmount=(state.editData.correctAmount as any)*100 
    editInvoice(billPDf).then(res=>{
     console.log(res,'000');
     getInvoiceLists(billDate.value)
       bjshow.value=false
       butJy.value=false
    })
   }
    //删除发票信息
   function deleteInvoices(item){
     deleteInvoice({id:item.id}).then(res=>{

        if(res){
       message.success(res)
        SelectInvoiceList.value.forEach((e,i)=>{
    if(e.id==item.id){
      SelectInvoiceList.value.splice(i,1)
    }
  })
       getInvoiceLists(billDate.value)
      }

     })
   }
   //删除PDF
   function delshowBut(item){
    
    deletePDF({id:item.id}).then(res=>{
      console.log(res,'123');
      if(res){
       message.success(res)
       getinvoiceSplittingBillPdfs(billDate.value)
      }
      
    })
   }
   //获取发票数据列表
   function getInvoiceLists(date){
     
         let month=''
         let year=''
      if(date){
        year=date.substr(0,4)
       if(date.substr(-2)>9){
          month=date.substr(-2)
       }else{
         month=date.substr(-1)
       }
      }
      let paramse = {
        // type:typeNum.value,
        month,
        year,
        pageNo:1,
        pageSize:100,
      }
    getInvoiceList(paramse).then(res=>{
    state.SplitSheetDetails=null
    state.fileList={
      socialSecurity:[], //社保
       healthInsurance:[], //医保
       accumulationFund:[], //公积金
       houseRent:[] //房租水电
  }
  res.forEach(e=>{
  e.check=false
  state.fileList.houseRent.push(e)
  })

     })

     }
   function invoiceOkBut(flag){
     console.log(flag,'flag');
    if(state.SplitSheetDetails){
    //  splitResonStr.value= splitResonStr.value.split("，")[0]+"，"
     state.SplitSheetDetails.splitReson=splitResonStr.value
    }
     if(flag==2){
     if( fengemoney.value==0||!splitResonStr.value||SelectInvoiceList.value.length==0){
       message.error('请填写分割原因和金额,并选择公司和发票文件!')
         return
       }
     }
      
       if(SelectInvoiceList.value[0]&&fengemoney.value>0&&splitResonStr.value&&SelectCompanyId.value){
       let obj={
        acceptCompanyId: SelectCompanyId.value,
        bill:state.SplitSheetDetails?{...state.SplitSheetDetails}:{},
        billReceipts: [
          
          ...SelectInvoiceList.value
          
        ],
        splitAmount: fengemoney.value*100,
        splitReason: splitResonStr.value
          }
      GenerateSplitSheetFromInvoice(obj).then(res=>{
      console.log(res,'发票详情--');
      if(res){
        message.success('请求成功!')
        companyData.value=''
        // splitResonStr.value=res.splitReson+(res.splitAmount/100)+'元'
        splitResonStr.value=res.splitReson
   
        state.SplitSheetDetails=res as any
        fengemoney.value=0
        // state.fengeData.splitReason=res.splitReason
        // state.fengeData.splitReason=''
        // cancelModal()
        if(flag==2){
         butJy.value=true
        }
        
      }else{
        message.error('请求失败!')

      }
      })
    }
   }
   //下载分割单
   function downloadfengedan(scope){
  console.log(scope,'scopescopescope123');
  if(scope.stampPdfUrl){
     window.open(scope.stampPdfUrl)
     message.success('下载成功!')
  }else{
     message.error('数据正在生成,请稍等!')

    getContractPrintOrderList(1)
  }
  // loading.value=true
  // GeneratePartitionSheet({id:scope.id}).then(res=>{
  //  console.log(res,'下载分割单');
  //  if(res){
  // loading.value=false

  //    message.success('下载成功!')
  //    window.open(res)
  //  }else{
  //    message.error('下载失败!')

  //  }
   
  // })
  
   }
   //生成分割单按钮
  function GeneratePartitionSheetBut(){
   
    if(state.SplitSheetDetails){
    //  splitResonStr.value= splitResonStr.value.split("，")[0]+"，"
    state.SplitSheetDetails.splitReson=splitResonStr.value
    }
    if(typeNum.value==1){
     if(state.fileList.healthInsurance[0] && state.fileList.socialSecurity[0] && SelectCompanyId.value){
     let obj ={
       acceptCompanyId:SelectCompanyId.value||'',
       belongMonth:state.fileList.healthInsurance[0].month,
       belongYear:state.fileList.healthInsurance[0].year,
       billPdfs:[
         ...state.fileList.healthInsurance,
         ...state.fileList.socialSecurity
       ],
       billType:1,
       bill:state.SplitSheetDetails?{...state.SplitSheetDetails}:{}
     }
    setSplittingBillPdfs(obj)
   }else{
     message.error('请选择数据!')
   }
   }
   else if(typeNum.value==2){
    if(state.fileList.accumulationFund[0] && SelectCompanyId.value){
      console.log(state.fileList.accumulationFund,'state.fileList.accumulationFund')
     let obj ={
       acceptCompanyId:SelectCompanyId.value||'',
       belongMonth:state.fileList.accumulationFund[0].month,
       belongYear:state.fileList.accumulationFund[0].year,
       billPdfs:[
         ...state.fileList.accumulationFund,
       ],
       billType:2,
       bill:state.SplitSheetDetails?{...state.SplitSheetDetails}:{}
     }
    setSplittingBillPdfs(obj)
   }else{
     message.error('请选择数据!')
   }
   }
   
  }
// 弹出日历和关闭日历的回调
function openChangeOne(status) {
if (status){
yearShowOne.value = true
}
}
// 得到年份选择器的值
function panelChangeOne(value) {    
yearQuarter.value = moment(value).format('YYYY-MM');
yearShowOne.value = false;
}
//上传弹框
function uploadShowModel(flag){
  uploadTypeNum.value=flag
  uploadvisible.value=!uploadvisible.value
  // flag=1社保,2医保,3公积金
  if(flag==1){
    actionUrl.value=baseURL+'/hr-server/socialSecurity/importExcel'
  }else if(flag==2){
    actionUrl.value=baseURL+'/hr-server/healthCare/importExcel'

  }else if(flag==3){
    actionUrl.value=baseURL+'/hr-server/payment/importExcel'

  }
}
function Selectfile(e,item){
console.log(e,item,'Selectfile');
item.check=e.target.checked
if(e.target.checked){
SelectInvoiceList.value.push(item)

}else{
  SelectInvoiceList.value.forEach((e,i)=>{
    if(e.id==item.id){
      SelectInvoiceList.value.splice(i,1)
    }
  })
}
console.log( SelectInvoiceList.value,' SelectInvoiceList.value');
butJy.value=false
}
function SelectfileQX(e){

SelectInvoiceList.value=[]

 state.fileList.houseRent.forEach(element=>{
   element.check=e.target.checked
   if(element.check){
     SelectInvoiceList.value.push(element)
   }
 })
 butJy.value=false
console.log(SelectInvoiceList.value,'qx');

}

     watch(()=>state.fileList,()=>{
       let s=state.fileList.socialSecurity
       state.fileList.socialSecurity=s
       state.fileList.healthInsurance=state.fileList.healthInsurance
     })
     watch(()=>state.SplitSheetDetails,()=>{
       if(state.SplitSheetDetails){
       fengemoney.value=state.SplitSheetDetails.splitAmount
       if(fengemoney.value){
         fengemoney.value=fengemoney.value/100
       }
    
       }
       
     })

       watch(()=>billDate.value,()=>{
        console.log(billDate.value,'billDate.value1111');

     if(typeNum.value==3){
       butJy.value=false
       SelectInvoiceList.value=[]

      if(billDate.value==null){
          state.fileList={
      socialSecurity:[], //社保
       healthInsurance:[], //医保
       accumulationFund:[], //公积金
       houseRent:[] //房租水电
  }
       
        getInvoiceLists(billDate.value)
        
      }
     }else{
        if(billDate.value==null){
          state.fileList.houseRent=[]
        getinvoiceSplittingBillPdfs(billDate.value)
        
      }
     }
     
      
       
     })
    onMounted(() => {
      splitResonStr.value=''
        getContractPrintOrderList(1)
        console.log( dataSource.value,' dataSource.value');
        
    })
    return {
      checked,
      SelectfileQX,
      SelectInvoiceList,
      Selectfile,
      butJy,
      splitResonStr,
      fengemoney,
      segmentationXQShow,
      fengedanDetails,
      editfengedan,
      editfgds,
      editFgdPDFs,
      fgdbjshow,
      fgdbjshowBut,
      uploadurl,
      actionUrl,
      uploadShowModel,
      uploadTypeNum,
      uploadhandleChange,
      uploadfileList,
      uploadcancelModal,
      uploadvisible,
      yearQuarter,
      yearShowOne,
      openChangeOne,
      panelChangeOne,
      acceptCompanyName,
      GeneratePartitionSheetBut,
      value1,
      downloadfengedan,
      deleteInvoices,
      editInvoices,
      invoiceOkBut,
      showdetailsModal,
      houseRentimgList,
      getInvoiceLists,
      accumulationimgList,
      delshowBut,
      EditChangeDate,
      bjshowId,
      bjshowBut,
      editPDFs,
      bjshow,
      healthInsuranceimgList,
      submitOkBut,
      handleFilePreview,
      handleFileRemove,
      billDate,
      imgList,
      setSplittingBillPdfs,
      SelectCompanyId,
      healthInsuranceData,
      socialSecurityData,
      healthInsuranceListRadio,
      getSplitSingle,
      formID,
      // handleimgListRadio,
      imgListRadio,
      getinvoiceSplittingBillPdfs,
      filterOption,
      companyData,
      companyList,
      getGenerateBillLists,
      callback,
      dataSource,
      columns,
      loading,
      pagination,
      getContractPrintOrderList,
      handleTableChange,
      state,
      searchQuery,
      searchReset,
      printAppoint,
      handleChange,
      to,
      selectAllCompanyList,
      handleResizeColumn: (w, col) => {
        col.width = w;
      },
      visible,
      showModal,
      selectList,
      onChangeDate,
      cancelModal,
      modelTitle,
      fileUrl,
      handleCancel,
      previewVisible,
      previewImage,
      handlePreview,
      typeNum
    };
  },
  components: {
    // CompanyInvoiceModal,
    // PrintAppoint,
    segmentationXQ,
    PrinterOutlined,
    SearchOutlined,
    ArrowLeftOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    DownOutlined,
    PlusOutlined,
    UploadOutlined
    
  },
});
