
import { 
  defineComponent,
  ref, 
  reactive,
  UnwrapRef,
  toRefs
} from 'vue'
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { _area } from '@/components/_util/Area'
import { addAndGetPayment, companyInvoiceBatchSave } from '@/api/manage'
import { message as Message } from 'ant-design-vue'

export default defineComponent({
  name: 'CompanyInvoiceModal',
  components: {
  },
  emits: ['ok'],
  setup(props, context) {
    
    const formState: UnwrapRef<{
      endInvoiceCode: string,
      invoiceType: string,
      startInvoiceCode: string,
    }> = reactive({
      endInvoiceCode: '',
      invoiceType: '',
      startInvoiceCode: '',
    })
    const state = reactive({
      loadingMore: false,
      confirmLoading: false,
    })

    const rules = {
      endInvoiceCode: [{ required: true, message: "结束票号-必填" }],
      invoiceType: [{ required: true, message: "发票类型-必填" }],
      startInvoiceCode: [{ required: true, message: "	开始票号-必填" }],
    };
    const add = () => {
      state.loadingMore = true
    }
    const cancel = () => {
      state.loadingMore = false
      state.confirmLoading = false
    }
    function onOk() {
      state.loadingMore = false
      context.emit('ok')
    }
    const formRef = ref()
    function onSubmit() {
      formRef.value.validate().then(async () => {
        state.confirmLoading = true
        console.log(formState);
        
        companyInvoiceBatchSave(formState).then(res => { 
          Message.success('添加成功')
          onOk()
          state.confirmLoading = false
        }).catch(err => {
          Message.warning('添加失败')
          state.confirmLoading = false
        })
      }).catch((error: ValidateErrorEntity<{
        endInvoiceCode: string,
        invoiceType: string,
        startInvoiceCode: string,
      }>) => {
        console.log("error", error);
      });
      
    }

    return {
      ...toRefs(state),
      formRef,
      cancel,
      rules,
      formState,
      onSubmit,
      onOk,
      add,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    };
  },
  
});
