
import { computed, defineComponent, reactive, ref, onMounted, watch, toRefs } from 'vue';
import type { UnwrapRef } from 'vue';
import { useStore } from 'vuex';
import { queryById, getProcessByActi, getTaskId, completeHandler, getTaskList } from '@/api/hrUser'
import { hrUserFormState } from '@/views/HrUser/data';
import { useRouter } from 'vue-router'
import { getUserPhone } from "@/utils/auth"

import CompanyTab from '@/views/HrUser/HrUserStep/CompanyTab.vue';
import ResultTab from '@/views/HrUser/HrUserStep/ResultTab.vue';

import HistoryList from "@/views/HrUser/modules/HistoryList.vue"
import { message } from 'ant-design-vue';

export default defineComponent({
  components: {
   
    CompanyTab,
   
    ResultTab,

    HistoryList
  },
  setup () {
    const router = useRouter()
    const activeKey = ref(0)
    const store = useStore()
    const state: {
      formState: hrUserFormState,
      confirmLoading: boolean,
      type: string,
      processData: any,
      taskId: string,
      compObj: {
        visible: boolean,
        title: string,
        type: string,
        dealValue: string
      },
      isActiviti: string,
      mode: string,
      instanceId: string
    } = reactive({
      formState: {} as hrUserFormState,
      confirmLoading: false,
      type: 'detail', // 查看
      processData: {},
      taskId: '',
      compObj: {
        visible: false,
        title: '',
        type: '',
        dealValue: ''
      },
      isActiviti: '1',
      mode: 'left',
      instanceId: ''
    });
    state.formState.mobilePhone = getUserPhone()

    function toNextTab (key, result) {
      activeKey.value = key
      if (result && typeof result === 'object') {
        state.formState = { ...state.formState, ...result }
      }
    }
    // 获取流程数据---编辑/查看/启动
    function getProc (activitiId: string) {
      getProcessByActi({ activitiId }).then(res => {
        state.processData = res
        state.instanceId = res.instanceId
        
      }).catch(err=> {
        message.warning('查询流程信息失败')
      })
    }
    // 获取taskId
    function initTaskId (instanceId: string) {
      getTaskId({ instanceId }).then(res => {
        state.taskId = res || ''
      }).catch(err => {
        message.warning('查询流程信息失败')
      })
    }
    // 通过 passTask
    function pass () {
      state.compObj = {
        visible: true,
        type: 'pass',
        title: '审批通过',
        dealValue: ''
      }
    }
    // 驳回
    function back () {
      state.compObj = {
        visible: true,
        type: 'back',
        title: '审批不通过',
        dealValue: ''
      }
    }
    // 审批操作
    function compHandler () {
      const flag = state.compObj.type === 'pass'
      state.confirmLoading = true
      completeHandler({
        taskId: state.taskId,
        instanceId: state.processData.instanceId,
        variables: {
          comment: state.compObj.dealValue,
          pass: flag
        }
      }).then(res => {
        state.taskId = ''
        activeKey.value = 9
        message.success('审核成功')
        state.confirmLoading = false

      }).catch(err => {
        message.warning('审批失败,联系系统人员')
        state.confirmLoading = false

      }).finally(() => {
        state.compObj.visible = false
        state.confirmLoading = false
      })
    }
    function changeMode(e) {
      // if (e.target.value === 'right') {
      //   state.instanceId = state.query.instanceId
      // }
    }
    onMounted(() => {
      const data: any = router.currentRoute.value.query
      console.log(data,'555666777');
      
      let tableId = data.tableId
      if (tableId) {
        state.confirmLoading = true
        queryById({
          id: tableId
        }).then(res => {
          state.formState = Object.assign(res)
        state.confirmLoading = false

          activeKey.value = 1
          if (Object.keys(data).includes('isActiviti')) {
            getProcessByActi({ activitiId: data.activitiId }).then(res => {
              // 2.获取待办列表
              getTaskList({
                businessKey: res.businessKey,
                definitionId: res.definitionId,
                pageSize: 10,
                pageNum: 1
              }).then(result => {
                // 判断有没有待办
                if (result.list.length) {
                  state.isActiviti = data.isActiviti
                  state.confirmLoading = false

                  initTaskId(data.instanceId)
                }
              }).finally(()=> {
                state.confirmLoading = false
              })
            }).catch(err=> {
              state.confirmLoading = false
              message.warning('查询流程信息失败')
            })
          }
        }).catch(err => {
          activeKey.value = 1
          state.confirmLoading = false
        })
        getProc(data.activitiId)
      } else {
        message.warning('请重新跳转连接')
      }
    })
    return {
      ...toRefs(state),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      activeKey,
      toNextTab,
      pass,
      back,
      compHandler,
      changeMode
    };
  },
});
