
import { watch, defineComponent, toRef, reactive, ref, toRefs, PropType, computed, onMounted } from 'vue'
import type { UnwrapRef } from 'vue'
import {
  qdApply,
  listAcademicTitle,
  deleteAcademicTitle,
  saveAndStartAct,
  getActStartProcess,
  EDIT_USER,
  getProcessByActi,
  getTaskId,
  completeHandler,
  getJhDictItems
} from "@/api/hrUser"
import { useStore } from 'vuex'
import { PostTab, hrUserFormState, FileItem, FileInfo, QdApplyHrUser } from '@/views/HrUser/data'
import { message } from 'ant-design-vue';
import { _area } from '@/components/_util/Area'
// import { EDIT_USER, getJhDictItems } from '@/api/hrUser'
import { getToken, getUserInfo, getUserPhone } from "@/utils/auth";
import { useRouter } from 'vue-router'
import { isArray, isNumber, isString } from 'lodash'
import moment from 'moment'
import { UploadOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { baseURL } from '@/config/index'
import { Option } from '@/interface/print'

export default defineComponent({
  emits: ['toNextTab'],
  props: {
    data: {
      type: Object as PropType<hrUserFormState>
    },
    type: {
      type: String,
      default: ''
    }

  },
  components: {
    UploadOutlined
  },
  setup(props, context) {
    const router = useRouter()
    const datas = toRef(props, 'data')
    const type = toRef(props, 'type')
    const disabled = computed(() => {
      if (type.value === 'detail') {
        return true
      } else {
        return false
      }
    })
    const postDisabled = computed(() => {
      if (formState.deptId) {
        return false
      }
      return false
    }
    )
    const store = useStore()
    const pcaaData = _area
    const state = reactive<{
      children: Array<Object>;
      childrenCity: Array<Object>;
      childrenCounty: Array<Object>;
      branchIndustry: Array<Object>;
      mainIndustryList: Array<Object>;
      branchtypeList:Array<Object>,
       aaumSignList:Array<Object>,
       BranchcompanyAddress:Array<Object>,
      confirmLoading: boolean;
      headers: Object;
      action: String;
      taskId: String | number;
      branchtypeisshow: boolean;
      postList:Array<Object>,
      mainIndustryValve:String

    }>({
      children: [],
      childrenCity: [],
      confirmLoading: false,
      childrenCounty: [],
      branchIndustry: [],
      mainIndustryList: [],
      headers: { "X-Access-Token": getToken() },
      action: baseURL + '/sys/oss/api/uploadRequest',
      taskId: '',
     mainIndustryValve:'',
      branchtypeisshow: false,
      branchtypeList:[
        '加盟公司',
        '直属公司',
      ],
       postList:[
        '分子公司负责人'
      ],
       aaumSignList:[
        {title:'是',key:1},
        {title:'否',key:0},

      ],
       BranchcompanyAddress:null

    })
    const formState: UnwrapRef<any> = reactive({
      mobilePhone: null as any,
      branchtype: null,
      deptId: '',
      post: null,
      joinCompanyDate: null,
      employmentWay: null,
      contractShape: null,
      salary: null,
      periodStartDate: null,
      periodEndDate: null,
      probationSalary: null,
      socialSecurityType: null,
      socialSecurityCompany: null,
      socialSecurityProvince: null,
      socialSecurityCity: null,
      actualWorkUnit: null,
      major: null,
      personnelType: null,
      deptIdname: null,
      id: null,
      province: null,
      city: null,
      county: null,
      scope: [],
      branchName: null,
      mainIndustry: null,
      aaumSign: null,
      license: null,
    });

    const rules = {
      branchtype: [{ required: true, message: '请选择公司类型' }],
      personnelType: [{ required: true, message: '请选择人员类型' }],
      post: [{ required: true, message: '请选择职位' }],
      employmentWay: [{ required: true, message: '请选择用工形式' }],
      socialSecurityType: [{ required: true, message: '请选择社保类型！' }],
      socialSecurityCompany: [{ required: true, message: '请选择社保所在公司！' }],
      socialSecurityProvince: [{ required: true, message: '请选择社保所在省份！' }],
      socialSecurityCity: [{ required: true, message: '请选择社保所在城市！' }],
      province: [{ required: true, message: '请选择分子公司所在地区！' }],
      // city: [{ required: true, message: '请选择分子公司所在城市！' }],
      // county: [{ required: true, message: '请选择分子公司所在区！' }],
      scope: [{ required: true, message: '请选择分子公司经营范围！' }],
      deptIdname: [{ required: true, message: '职员必填部门，请联系管理员！' }],
      actualWorkUnit: [{ required: true, message: '协作人员，必填实际工作地址！' }],
      major: [{ required: true, message: '协作人员，必填专业！' }],
      probationSalary: [{ required: true, message: '请输入数字，没有填 0 ' }],
      salary: [{ required: true, message: '请输入数字，没有填 0 ' }],
      mainIndustry: [{ required: true, message: '请输入主要产业 ' }],
      // branchName: [{ required: true, message: '请输入工商注册名称 ' }],
      joinCompanyDate: [{ required: true, message: '请输入入司时间 ' }],
      aaumSign: [{ required: true, message: '请选择是否需要工商注册 ' }],
      license: [{ required: true, message: '请上传分子公司营业执照 ' }],

    };
    const LicenseList = ref<FileItem[]>();
    const formRef = ref()
    const previewVisible = ref<boolean>(false);
    const previewImage = ref<string | undefined>('');
    function getBase64(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string;
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    }
    const handleCancel = () => {
      previewVisible.value = false;
    }
    const userInfo = getUserInfo()

    const handleLicenseChange = (info: FileInfo, key: string) => {
      if (info.file.status === 'uploading') {
        return;
      }
      if (info.file.status === 'done') {
        let resFileList = [...info.fileList]
        resFileList = resFileList.slice(-1);
        LicenseList.value = resFileList
        let tempFile = resFileList.map(el => el?.response?.result?.url)
        formState[key] = tempFile.join(',');
      }
      if (info.file.status === 'error') {
        message.error('上传失败');
      }
    };

    function disabledperiodStartDate(current) {
      //current为当前日期选择页的全部日期
      let endTime = moment(formState.joinCompanyDate).valueOf();//valueOf()将moment(this.startTime)转化为毫秒数值
      let currentTime = current.valueOf();
      return currentTime < endTime;//禁用小于开始时间的日期
    }

    function disabledperiodEndDate(current) {
      //current为当前日期选择页的全部日期
      let endTime = moment(formState.periodStartDate).valueOf();//valueOf()将moment(this.startTime)转化为毫秒数值
      let currentTime = current.valueOf();
      return currentTime < endTime;//禁用小于开始时间的日期
    }

    function changeAaumSign(v) {
      console.log(v, '是否需要公司注册');

    }
    function toNextTab(key) {
      formState.mobilePhone = getUserPhone()
      formState.entryType = 3
      //点击上一步则不效验
      if (key === -1) {

      } else {
        // const data: any = router.currentRoute.value.query
        const data: any = formState
        console.log(formState,'123');
        formState.accountFlag=1

        // return
        formRef.value.validate().then(async () => {
          state.confirmLoading = true
          // 流程处理并且需要保存表单
          if (Object.keys(data).includes('isActiviti')) {
            // 先处理流程
            getProc(data.activitiId).then(res => {
              getTaskId({ instanceId: res.instanceId }).then(ress => {
                state.taskId = ress || ""
                completeHandler({
                  taskId: state.taskId as any,
                  instanceId: res.instanceId,
                  variables: {
                    comment: '',
                    pass: true
                  }
                }).then(res => {
                  state.taskId = '';
                  message.success('提交成功');
                  // 流程审批完成后不需要保存步骤，直接下一页
                  context.emit('toNextTab', key)
                }).catch(err => {
                  message.warning('提交失败,联系系统人员');
                }).finally(() => {
                  state.confirmLoading = false
                })
              }).catch(err => {
                message.warning('查询流程信息失败')
                state.confirmLoading = false
              })
            }).catch(err => {
              message.warning('查询流程信息失败')
              state.confirmLoading = false
            })
            // 非流程
          } else {
            getActStartProcess({
              key: 'hr_entry_application'
            }).then(result => {
              saveAndStartAct({
                ...(datas.value as hrUserFormState),
                ...formState,
                employmentWay: '正式员工',
                username: userInfo.username,
                procDefId: result.id,
                id: formState.id,
                key: formState.id,
                processName: result.name,
                scope: formState.scope.join(','),
                stage: 10
              }).then(res => {
                let variables = {}
                getProc(res).then(ress => {
                  variables = ress.variables ? JSON.parse(ress.variables) : { type: '' };
                  qdApply({
                    username: userInfo.username,
                    definitionId: result.id,
                    id: ress.businessKey,
                    key: ress.businessKey,
                    variables: variables,
                    category: result.category
                  } as QdApplyHrUser).then(res => {
                    context.emit('toNextTab', key)
                  }).catch(() => {
                    message.warning('保存数据失败！')
                    state.confirmLoading = false
                  }).finally(() => {
                    state.confirmLoading = false
                  })
                })

              }).catch(() => {
                message.warning('保存数据失败！')
                state.confirmLoading = false
              })
            }).catch(() => {
              state.confirmLoading = false
            })
          }

        }).catch((error) => {
          console.log("error", error);
        })
      }

    }
    //获取流程数据---编辑/查看/启动
    const getProc = (activitiId: string) => {
      return getProcessByActi({ activitiId }).then(res => {
        if (res) {
          return res;
        } else {
          message.warning('获取variables失败，请联系管理员！');
        }
      })
    }

    function out() {
      store.dispatch('Logout').then(res => {
        message.success('退出登录成功！')
        let obj = { SubsidiaryReg: 1 }
        router.push({
          path: '/hr/login',
          query: {
            ...obj
          }
        })
        // router.go(-1)

      }).catch((err) => {
        message.warning(err.message)
        // router.go(-1)
      })
    }
  const filter = (inputValue: string, path: Option[]) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    function handleChange(...agme) {
      console.log(...agme, '666');

      if (agme[1] == 1) {
        formState.socialSecurityCity = null
      }
      for (let index = 0; index < pcaaData.length; index++) {
        const element = pcaaData[index]
        if (element.label === agme[0] || element.value === agme[0]) {
          state.children = element.children
          break
        }
      }
    }
    function handleChangeProvince(value) {
      formState.city = null
      for (let index = 0; index < pcaaData.length; index++) {
        const element = pcaaData[index]
        if (element.label === value || element.value === value) {
          state.childrenCity = element.children
          break
        }
      }
    }
        const onChangeBranchcompany= (e: any) => {
      formState.province = e[0] || ''
      formState.city = e[1] || ''
      formState.county = e[2] || ''

    }
    function handleChangeCity(value) {
      formState.county = null
      for (let index = 0; index < state.childrenCity.length; index++) {
        const element = state.childrenCity[index] as { label: string; value: number | string; children: Array<Object>; }
        if (element.label === value || element.value === value) {
          state.childrenCounty = element.children
          break
        }
      }
    }
    function getDictItems(dictCode: string) {
      getJhDictItems({ code: dictCode }).then(res => {
        const { branch_business } = res
        state.branchIndustry = branch_business
        if (formState.scope && formState.scope.length > 0 && typeof formState.scope === 'object') {
        state.mainIndustryList = []
        formState.scope.forEach((el, index) => {

        state.branchIndustry.forEach((e, i) => {
            if (el == e['value']) {
              state.mainIndustryList.push(e)
              if(formState.mainIndustry == e['value']){
               state.mainIndustryValve = e['title'] || e['text'] || ''
              }
            }
          })


        })
        console.log(state.mainIndustryList, 'state.mainIndustryList12312312');
         
      } else {
        state.mainIndustryList = []
        formState['mainIndustry'] = null
        state.mainIndustryValve=null

      }
        console.log(state.branchIndustry, 'state.branchIndustry');

      })
    }
    function changeBranchtype(e) {
      // formState.post = null
    }
    function changePost(e) {
      formState.employmentWay = null
    }
      function selectBut(e,str){

      if( formState[str]==e){
        // formState[str]=''
      }else{
        formState[str]=e
          if(str=='post'){
          formState.employmentWay = null
        }
      }

    }
    function setDept() {
      if (type.value === 'detail') {
        return
      }
      const data: any = router.currentRoute.value.query
      formState.deptIdname = data.departName || formState.deptIdname
      formState.deptId = data.departId || formState.deptId
      formState.branchtype = data.branchtype || formState.branchtype
    }
    onMounted(() => {
      const data: any = router.currentRoute.value.query
      formState.branchtype = data.branchtype || formState.branchtype
      if (data.branchtype) {
        state.branchtypeisshow = true
      } else {
        state.branchtypeisshow = false

      }
      getDictItems('branch_business')

    })
    watch(
      () => datas.value,
      () => {
        Object.keys(formState).forEach(item => {
          if ((datas.value as hrUserFormState)[item] || isNumber((datas.value as hrUserFormState)[item])) {
            formState[item] = (datas.value as hrUserFormState)[item]
          }
        })
         if (formState.license) {
          LicenseList.value = formState.license.split(',').map((e: any) => {
            let data = e.split('/').pop()
            return { name: data.slice(data.indexOf('_') + 1), url: e }
          })
        }
        if(formState.province || formState.city || formState.county) {
   state.BranchcompanyAddress=[formState.province || '',formState.city||'', formState.county||'']
        }else{
          state.BranchcompanyAddress=null
        }

        let s = formState.socialSecurityCity
        if (formState.socialSecurityProvince) {
          handleChange(formState.socialSecurityProvince)
        }
        formState.socialSecurityCity = s

        let t = formState.scope


        if (t == null) {
          formState.scope = []
        } else if (t) {
          if (t[0] == '') {
            formState.scope = []
          }
        }

        if (isString(t)) {
          formState.scope = t.split(',') as any

        }

        // formState.aaumSign


        setDept()
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(() => formState.joinCompanyDate, (newvalue, oldvalue) => {
      if (!newvalue) {
        formState.periodStartDate = ''
        formState.periodEndDate = ''
      }
    },
      { immediate: false }
    )

    watch(() => formState.periodStartDate, (newvalue, oldvalue) => {
      if (!newvalue) {
        // formState.periodStartDate=''
        formState.periodEndDate = ''
      }
    },
      { immediate: false }
    )
    watch(() => formState.scope, (newvalue, oldvalue) => {

 if (newvalue && newvalue.length > 0 && typeof formState.scope === 'object') {
        state.mainIndustryList = []
        newvalue.forEach((el, index) => {
        state.branchIndustry.forEach((e, i) => {
            if (el == e['value']) {
              state.mainIndustryList.push(e)
            }
          })
        })

      } else {
        state.mainIndustryList = []
        formState['mainIndustry'] = null
      }
    },
      { immediate: false }
    )

    return {
      formState,
      formRef,
      ...toRefs(state),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules,
      toNextTab,
      pcaaData,
      handleChange,
      disabled,
      handleChangeProvince,
      handleChangeCity,
      postDisabled,
      changeBranchtype,
      changePost,
      disabledperiodStartDate,
      disabledperiodEndDate,
      changeAaumSign,
      handlePreview,
      previewVisible,
      previewImage,
      getBase64,
      handleCancel,
      handleLicenseChange,
      LicenseList,
      out,
      getProc,
      selectBut,
      onChangeBranchcompany,
      filter

    };
  },
});
