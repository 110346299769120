import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contract_print_order_form = _resolveComponent("contract-print-order-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "订单详情",
    width: "100%",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    wrapClassName: "full-modal",
    onOk: _ctx.handleOk,
    onCancel: _ctx.handleCancel,
    okText: "全部打印",
    cancelText: "关闭"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_contract_print_order_form, {
        ref: "realForm",
        onOk: _ctx.submitCallback,
        disabled: _ctx.disableSubmit
      }, null, 8, ["onOk", "disabled"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}