
import { 
  defineComponent, 
  onMounted, 
  ref, 
  toRef,
  getCurrentInstance, 
  onBeforeUnmount, 
  h,
  reactive,
} from 'vue'
import { getPrintJob, setPrintByIds } from '@/api/manage'
import PrintModel from './PrintModel.vue'
import ExpressEditVO from './ExpressEditVO.vue'
import { deliveryStatusColor, deliveryStatus, paymentStatus, paymentStatusColor } from './statusType'
import { message as Message, Modal } from 'ant-design-vue'
export default defineComponent({
  name: 'ContractPrintOrderForm',
  props: {
  },
  components: {
    PrintModel,
    ExpressEditVO
  },
  setup(props, context) {
    const confirmLoading = ref<boolean>(false)
    const validatorRules = {}
    const model = ref({id: ''})
    const printList = ref([])
    const expressEditVO = ref(null)
    const expressEdit = () => {
      expressEditVO.value.edit(model.value)
    }
    const expressEditOk = (obj) => {
      model.value = {
        ...model.value,
        ...obj
      }
    }
    const edit = async (record: any) => {
      model.value = Object.assign({}, record);
      const { id } = model.value
      if (id) {
        const res = await getPrintJob({ printOrderId: id })
        printList.value = res
      }
    }
    const submitForm = async () => {
      if (!printList.value.length) {
        Message.warning("没有打印任务")
        return
      }
      confirmLoading.value = true
      let ids = printList.value.map(item=> item.id).join(',')
      const res = await setPrintByIds({ids})
      if (res.length && res[0].success) {
        let arr = []
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          for (let i = 0; i < printList.value.length; i++) {
            const item = printList.value[i];
            if (element.printItemId == item.id) {
              item.isOk = true
              element.isOk = true
              element.jobFileName = item.jobFileName
            }
          }
        }
        Modal.success({
          title: () => '打印结果',
          content: () => h('div', {}, [
            res.map(item=>{
              return h('p', { style: item.isOk ? '' : {"color": "red"} } , item.isOk ? '打印成功：' + item.jobFileName : '打印失败：' + item.jobFileName )
            })
          ]),
          onOk() {
            console.log('ok');
            confirmLoading.value = false
            context.emit('ok')
          },
        });
      } else {
        confirmLoading.value = false
        Message.warning('打印异常')
      }
    }
    onMounted(() => {

    })
    return {
      model,
      printList,
      confirmLoading,
      expressEdit,
      submitForm,
      edit,
      expressEditOk,
      validatorRules,
      deliveryStatusColor, deliveryStatus, paymentStatus, paymentStatusColor,
      expressEditVO
    };
  },
  
});
