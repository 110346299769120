
import {
  defineComponent,
  ref,
  toRefs,
  reactive,
  watch,
  UnwrapRef,
  onMounted,
  computed
} from "vue";
import {
  RuleObject,
  ValidateErrorEntity
} from "ant-design-vue/es/form/interface";
import { _area } from "@/components/_util/Area";
import { OtherCertificates, FileInfo } from "@/views/HrUser/data";
import { UploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { getToken } from "@/utils/auth";
import {
  addOtherCertificates,
  editOtherCertificates,
  editQualification,
  getSpecialWorkTree as getPracticing
} from "@/api/hrUser";
import { baseURL } from "@/config/index";
import { functions } from "lodash";

export default defineComponent({
  name: "OtherCertificatesModal",
  props: {},
  components: {
    UploadOutlined
  },
  emits: ["ok"],
  setup(props, context) {
    const formState: any = reactive({
      attachment: null,
      certificateType: null,
      certificateName: null,
      certificateNo: null,
      certificateDate: null,
      certificateEndDate: null,
      certificateUnit: null,
      hrEntryApplicationId: null,
      id: null,
      worktype: null,
      projectname: null,
      templateExtend: {
        worktype: "",
        projectname: ""
      }
    });
    const state = reactive({
      qualifyUrl: baseURL + "/sfsj-server/sys/oss/api/uploadRequest",
      fileList: [],
      isEdit: false,
      headers: {
        "X-Access-Token": getToken()
      },
      loadingMore: false,
      confirmLoading: false,
      treeData: [],
      worktypelist: [],
      projectlist: []
    });

    const rules = {
      attachment: [{ required: true, message: "请上传证书" }],
      certificateType: [{ required: true, message: "请选择证书分类" }],
      certificateName: [{ required: true, message: "请填写证书名称" }],
      certificateNo: [{ required: true, message: "请填写证书编号" }],
      certificateDate: [{ required: true, message: "请选择发证日期" }],
      certificateUnit: [{ required: true, message: "请填写发证单位" }],
      worktype: [{ required: true, message: "不能为空" }],
      projectname: [{ required: true, message: "不能为空" }]
    };

    const handleChange = (info: FileInfo, key: string) => {
      if (info.file.status === "uploading") {
        state.confirmLoading = true;
        return;
      }
      if (info.file.status === "done") {
        const imgUrl = info?.file?.response?.result?.url;
        formState[key] = imgUrl;
        state.confirmLoading = false;
      }
      if (info.file.status === "error") {
        state.confirmLoading = false;
        message.error("上传失败");
      }
    };
    const add = (id: string) => {
      if (!id) {
        message.warning("没有人员id");
        return;
      }
      Object.keys(formState).forEach(item => {
        formState[item] = null;
      });
      state.loadingMore = true;
      formState.hrEntryApplicationId = id;
      formState.templateExtend = {
        worktype: "",
        projectname: ""
      };
    };
    const edit = (record: any) => {
      state.loadingMore = true;
      state.isEdit = true;
      Object.keys(record).forEach(item => {
        formState[item] = record[item];
      });
      if (formState.templateExtend) {
        formState.templateExtend = JSON.parse(formState.templateExtend);
        formState.worktype = formState.templateExtend.worktype || "";
        formState.projectname = formState.templateExtend.projectname || "";
        console.log(state.treeData, "treeDatatreeData");
      }
      if (
        formState.certificateType &&
        (formState.certificateType == "应急特种" ||
          formState.certificateType == "省厅特种")
      ) {
        state.treeData.forEach(e => {
          if (e.categoryName == formState.certificateType) {
            selectChange(e, "worktypelist", 1);
          }
        });

        setTimeout(() => {
          if (formState.templateExtend.worktype) {
            state.worktypelist.forEach(item => {
              if (item.categoryName == formState.templateExtend.worktype) {
                selectChange(item, "projectlist", 1);
              }
            });
          }
        }, 100);
      }
    };
    const cancel = () => {
      // context.emit('ok')
    };
    function onOk() {
      state.loadingMore = false;
      context.emit("ok");
    }
    const formRef = ref();

    function onSubmit() {
      formRef.value
        .validate()
        .then(async () => {
          state.confirmLoading = true;
          formState.templateExtend = JSON.stringify(formState.templateExtend);
          if(!formState.id){
            addOtherCertificates(formState)
              .then(res => {
                onOk();
              })
              .finally(() => {
                state.confirmLoading = false;
                formState.templateExtend = JSON.parse(formState.templateExtend);
              });
          }else{
             editOtherCertificates(formState)
              .then(res => {
                onOk();
              })
              .finally(() => {
                state.confirmLoading = false;
                formState.templateExtend = JSON.parse(formState.templateExtend);
              });
          }
           
        })
        .catch((error: ValidateErrorEntity<any>) => {
          console.log("error", error);
          message.warning("信息未填写完整");
        });
    }
    // 递归遍历
    function intetorFun(data, list) {
      data.forEach(e => {
        if (e.children && e.children.length !== 0) {
          e.children.forEach((j, i) => {
            if (j.children && j.children !== 0) {
              e.disabled = true;
            } else {
              list.push(e.children[i]);
              delete e.children[i];
            }
          });
          intetorFun(e.children, list);
        }
      });
    }

    function getMajorList(parentId, str) {
      getPracticing({ parentId }).then(res => {
        if (res) {
          let treeData = JSON.parse(JSON.stringify(res || []));
          state[str] = treeData;
        }
      });
    }

    function selectChange(e, str, flag = 0) {
      if (str == "worktypelist" && flag == 0) {
        formState.templateExtend = {
          worktype: "",
          projectname: ""
        };
        formState.worktype = formState.templateExtend.worktype;
        formState.projectname = formState.templateExtend.projectname;
      }
      if (str == "projectlist" && flag == 0) {
        formState.templateExtend.projectname = "";
        formState.projectname = formState.templateExtend.projectname;
      }
      getMajorList(e.id, str);
    }

    onMounted(() => {
      getMajorList(0, "treeData");
    });
    return {
      ...toRefs(state),
      formRef,
      add,
      cancel,
      rules,
      formState,
      onSubmit,
      onOk,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      handleChange,
      edit,
      getMajorList,
      intetorFun,
      selectChange
    };
  }
});
