import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_BaseInfo = _resolveComponent("BaseInfo")!
  const _component_WorkUnit = _resolveComponent("WorkUnit")!
  const _component_PostInfo = _resolveComponent("PostInfo")!
  const _component_AnnexInfo = _resolveComponent("AnnexInfo")!
  const _component_JFormContainer = _resolveComponent("JFormContainer")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    spinning: _ctx.loading,
    tip: "加载中..."
  }, {
    default: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_JFormContainer, {
            key: 0,
            disabled: _ctx.disableSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_divider, {
                orientation: "left",
                style: {"border-color":"#7cb305"},
                dashed: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("基本信息")
                ]),
                _: 1
              }),
              _createVNode(_component_BaseInfo, {
                baseInfo: _ctx.baseInfo,
                disabled: _ctx.disableSubmit
              }, null, 8, ["baseInfo", "disabled"]),
              _createVNode(_component_a_divider, {
                orientation: "left",
                style: {"border-color":"#7cb305"},
                dashed: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("工作单位信息")
                ]),
                _: 1
              }),
              _createVNode(_component_WorkUnit),
              _createVNode(_component_a_divider, {
                orientation: "left",
                style: {"border-color":"#7cb305"},
                dashed: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("现任专业技术职务信息")
                ]),
                _: 1
              }),
              _createVNode(_component_PostInfo),
              _createVNode(_component_a_divider, {
                orientation: "left",
                style: {"border-color":"#7cb305"},
                dashed: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("职称申请资料附件（注：申报正高级需要获得奖项（省市））")
                ]),
                _: 1
              }),
              _createVNode(_component_AnnexInfo)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["spinning"]))
}