import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, _mergeProps({
    value: _ctx.arrayValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.arrayValue) = $event)),
    onChange: _ctx.onChange,
    disabled: _ctx.disabled,
    mode: _ctx.mode,
    placeholder: _ctx.placeholder,
    optionFilterProp: "label"
  }, _ctx.$attrs, { allowClear: _ctx.allowClearShow }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dictOptions, (item, index) => {
        return (_openBlock(), _createBlock(_component_a_select_option, {
          key: index,
          value: item.value
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              style: {"display":"inline-block","width":"100%"},
              title: item.text || item.label
            }, _toDisplayString(item.text || item.label), 9, _hoisted_1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["value", "onChange", "disabled", "mode", "placeholder", "allowClear"]))
}