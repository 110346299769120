import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "添加开票申请",
    visible: _ctx.loadingMore,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loadingMore) = $event)),
    onCancel: _ctx.cancel,
    onOk: _ctx.onSubmit,
    maskClosable: false,
    okText: "提交",
    cancelText: "关闭"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, {
        spinning: _ctx.confirmLoading,
        tip: "正在打印，请勿关闭"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.formState,
            rules: _ctx.rules,
            "label-col": _ctx.labelCol,
            "wrapper-col": _ctx.wrapperCol
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "发票类型",
                name: "invoiceType"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.invoiceType,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.invoiceType) = $event)),
                    placeholder: "请选择发票类型"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 'SpecialVat' }, {
                        default: _withCtx(() => [
                          _createTextVNode("增值税专用发票")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 'VAT' }, {
                        default: _withCtx(() => [
                          _createTextVNode("增值税普通发票")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "开始票号",
                name: "startInvoiceCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.startInvoiceCode,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.startInvoiceCode) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "结束票号",
                name: "endInvoiceCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.endInvoiceCode,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.endInvoiceCode) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "label-col", "wrapper-col"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["visible", "onCancel", "onOk"]))
}