
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  onMounted,
  toRef,
  PropType,
  watch,
} from "vue";
import type { UnwrapRef } from "vue";
import { BasicsTab, FileInfo } from "@/views/companySettled/data";
import { checkOnlyUser } from "@/api/hrUser";
import {
  addCompanySettled,
  parseBusinessLicense,
  editCompanySettled,
  getExisting,
  listHistory,
  getProcessByActi,
  getTaskList,
  queryById,
  completeHandler
} from "@/api/companySettled";
import { RuleObject } from "ant-design-vue/es/form/interface";
import { getToken, getUserInfo } from "@/utils/auth";
import { baseURL } from "@/config/index";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import ResultTab from "./ResultTab.vue";
import moment from "moment";
import Title from "ant-design-vue/lib/typography/Title";
export default defineComponent({
  components: {
    ResultTab,
  },
  setup() {
    const state = reactive({
      actStatus: null,
      isEdit:false,
      actLoading: true,
      loading: false,
      isFormSubmit: false,
      isParseBusiness: false,
      fileList: [],
      identUpUrl: baseURL + "/sfsj-server/sys/oss/api/uploadRequest", //营业执照
      headers: {
        "X-Access-Token": getToken(),
      },
      title: null,
      businessLicenseUrl: false,
      praseLicense: true,
      handleChange: (info: FileInfo, key: string) => {
        if (info.file.status === "uploading") {
          state[key] = true;
          return;
        }
        if (info.file.status === "done") {
          formState[key] = info?.file?.response?.result?.url;
          if (formState[key]) {
            let form = new FormData();
            form.append("url", formState[key]);
            parseBusinessLicense(form)
              .then((res) => {
                // console.log("businessLicense", res);
                if (res.success) {
                  state.praseLicense = true;
                  formState.capital = res.capital;
                  formState.companyAddress = res.address;
                  formState.business = res.business;
                  formState.companyName = res.name;
                  formState.companyType = res.type;
                  formState.establishDate = moment(res.establish_date).format(
                    "YYYY-MM-DD"
                  );
                  formState.legalPerson = res.person;
                  formState.regNum = res.reg_num;
                  formState.validPeriod = moment(res.valid_period).format(
                    "YYYY-MM-DD"
                  );
                } else {
                  state.praseLicense = false;
                  message.error("解析营业执照失败,请手动填写营业执照内容!!!");
                }
                state.isParseBusiness = true;
              })
              .catch((err) => {
                state.isParseBusiness = false;
                message.error("识别失败,请重新上传");
              });

            state[key] = false;
          }
        }
        if (info.file.status === "error") {
          state[key] = false;
          message.error("上传失败");
        }
      },
      complete: {
        taskId: '',
        instanceId:'',
        variables: {
           comment: '同意',
           pass: true,
        }
}
    });
    const formState: UnwrapRef<BasicsTab> = reactive({
      applicationName: null,
      applicationSex: null,
      applicationEmail: null,
      business: null,
      businessLicenseUrl: null,
      capital: null,
      companyAddress: null,
      companyName: null,
      companyType: null,
      establishDate: null,
      legalPerson: null,
      regNum: null,
      validPeriod: null,
    });
    const validateEmail = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return Promise.reject("请输入邮箱号");
      } else {
        if (
          new RegExp(
            /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
          ).test(value)
        ) {
          return new Promise<void>((resolve, reject) => {
            checkOnlyUser({
              email: value,
            })
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                if (err.success) {
                  resolve();
                } else {
                  reject("邮箱已重复!");
                }
              });
          });
        } else {
          return Promise.reject("请输入正确格式的邮箱号!");
        }
      }
    };
    const submit = computed(() => {
      let title = ["提交", "重新提交"];
      let index = 0;
      if(state.isEdit){
        index = 1
      }
      return title[index];
    });
    const rules = {
      applicationName: [
        { required: true, message: "请输入姓名", trigger: "change" },
      ],
      applicationSex: [{ required: true, message: "请选择性别" }],
      applicationEmail: [
        { required: true, validator: validateEmail, trigger: "change" },
      ],
      businessLicenseUrl: [
        { required: true, message: "请上传营业执照", trigger: "change" },
      ],
    };
    const formRef = ref();
    function submitForm() {
      formRef.value
        .validate()
        .then(() => {
            console.log(formState.id);
          state.loading = true;
          if(formState.id){
            editCompanySettled({ ...formState })
            .then((res) => {
              handlerComplete()
            })
            .catch((err) => {
              message.warning("提交失败,联系系统人员");
            })
            .finally(() => {
              state.loading = false;
            });
          }else{
            addCompanySettled({ ...formState })
            .then((res) => {
              console.log(res);
              state.isFormSubmit = true;
              state.title = "请等待工作人员审核通过.";
            })
            .catch((err) => {
              message.warning("提交失败,联系系统人员");
            })
            .finally(() => {
              state.loading = false;
            });
          }
          
        })
        .catch((error: any) => {
          console.log("error", error);
          state.loading = false;
        });
    }
    // 获取流程数据---编辑/查看/启动
    function getProc(activitiId: string) {
      return getProcessByActi({ activitiId }).then((res) => {
        return res;
      });
    }
    // 审核
    function handlerComplete(){
      completeHandler({...state.complete}).then((res)=>{
        state.isFormSubmit = true;
        state.isEdit = false
        state.title = "请等待工作人员审核通过.";
      })
    }
    // 查询详情
    function detail(id: string) {
      state.isParseBusiness = true;
      return queryById({id}).then((res) => {
        return res;
      });
    }
    onMounted(() => {
      getExisting()
        .then((res) => {
          // console.log(res)
          state.actStatus = res.actStatus;
          // 审核中
          if (res.actStatus == 1) {
            getTaskList({
              type: "todo",
              businessKey: res.id,
              // definitionId: res.definitionId,
              pageSize: 10,
              pageNum: 1,
            })
              .then((res) => {
                if (res.list.length) {
                  const obj = res.list[0];
                  // console.log(obj, "obj");
                  detail(obj.businessKey).then((res) => {
                    formState.id = res.id;
                    formState.applicationName = res.applicationName;
                    formState.applicationSex = res.applicationSex;
                    formState.applicationEmail = res.applicationEmail;
                    formState.business = res.business;
                    formState.businessLicenseUrl = res.businessLicenseUrl;
                    formState.capital = res.capital;
                    formState.companyAddress = res.companyAddress;
                    formState.companyName = res.companyName;
                    formState.companyType = res.companyType;
                    formState.establishDate = res.establishDate;
                    formState.legalPerson = res.legalPerson;
                    formState.regNum = res.regNum;
                    formState.validPeriod = res.validPeriod;
                  });
                  state.isFormSubmit = false;
                  state.isEdit = true
                  state.complete.taskId = obj.taskId
                  state.complete.instanceId= obj.instanceId
                } else {
                  state.isFormSubmit = true;
                  state.title = "请等待工作人员审核通过.";
                }
              })
              .catch((error) => {
                console.log(error);
                state.isFormSubmit = true;
              });
          } else if (res.actStatus == 2) {
            state.isFormSubmit = true;
            state.title = "审核通过!";
          } else {
            state.isFormSubmit = false;
          }
          state.actLoading = false;
        })
        .catch((error) => {
          console.log(error);
          state.isFormSubmit = false;
          state.actLoading = false;
        });
    });
    return {
      ...toRefs(state),
      formState,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules,
      submitForm,
      submit,
    };
  },
});
