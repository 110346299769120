
import { defineComponent, onMounted, ref, toRaw, toRef, reactive, watch } from 'vue';
import { PrinterOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { message as Message } from 'ant-design-vue'
import { setPrintByIds, editPrintItem } from '@/api/manage'
export default defineComponent({
  name: 'PrintModel',
  mixins: [],
  props: {
    printData: {
      type: Object,
      default: () => ({}),
      required: true
    },
  },
  setup(props, context) {
    const printData = toRef(props, 'printData')
    const model = ref({
      jpPageRange: '',
      id: ''
    })
    const start = ref(1)
    const finish = ref(2)
    const jpPageRange = ref('')
    const confirmLoading = ref(false)
    const validatorRules = {}
    const edit = (record: any) =>{
      model.value = Object.assign({}, record);
    }
    const setPageRange = () =>{
      let str: any = ''
      let arr = []
      if (start.value < finish.value) {
        for (let index = start.value; index <= finish.value; index++) {
          arr.push(index)
        }
        str = arr.join(',')
      } else if (start.value > finish.value) {
        for (let index = finish.value; index <= start.value; index++) {
          arr.push(index)
        }
        str = arr.join(',')
      } else if (start.value == finish.value) {
        str = start.value
      }
      if (str !== model.value.jpPageRange) {
        model.value.jpPageRange = str
      }
    }
    const formRef = ref()
    const submitForm = () =>{
      formRef.value
        .validate()
        .then(async () => {
          confirmLoading.value = true
          await editPrintItem(model.value)
          Message.success('修改成功')
          confirmLoading.value = false
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    const submitPrint = async () =>{
      if (!model.value.id) {
        Message.warning('没有打印id')
        return
      }
      confirmLoading.value = true
      const res = await setPrintByIds({
        ids: model.value.id
      })
      if (res.length && res[0].success) {
        Message.success(res[0].msg)
        confirmLoading.value = false
      } else {
        Message.warning('打印异常')
      }
    }
    onMounted(() => {
      //备份model原始值
      let record = JSON.parse(JSON.stringify(printData.value));
      edit(record)
    })
    watch(
      () => jpPageRange.value,
      (count, prevCount) => {
        if (jpPageRange.value != '1') {
          model.value.jpPageRange = jpPageRange.value
        } else {
          setPageRange()
        }
      }
    )
    watch(
      () => model.value.jpPageRange,
      (count, prevCount) => {
        jpPageRange.value = count
        if (count == '1') {
          let arr = []
          arr = count.split(',')
          if (arr.length == 1) {
            start.value = arr[0]
            finish.value = arr[0]
          } else if (arr.length > 1) {
            let len = arr.length
            start.value = arr[0]
            finish.value = arr[len - 1]
          }
        }
      }
    )
    watch(
      () => start.value,
      (count, prevCount) => {
        setPageRange()
      }
    )
    watch(
      () => finish.value,
      (count, prevCount) => {
        setPageRange()
      }
    )
    watch(
      () => printData.value,
      (count, prevCount) => {
        edit(printData.value)
      }
    )
    return {
      edit,
      formRef,
      model,
      validatorRules,
      confirmLoading,
      jpPageRange,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      submitPrint,
      submitForm,
      start,
      finish
    };
  },
  components: {
    PrinterOutlined
  },
});
