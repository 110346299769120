
import {
    defineComponent,
    ref,
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    toRaw,
    onMounted
} from 'vue'
import { useRouter} from 'vue-router'
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { Moment } from 'moment';
import { message } from 'ant-design-vue';
import { validateIdent } from '@/utils/date';
import { getToken, getUserInfo } from "@/utils/auth"
import { politicalOutlooks, maritalStatus } from '@/utils/customExpression'
import { _area } from '@/components/_util/Area'
import { addIntentionCustomer, getIntention, getIndustry, getWorkspace } from '@/api/dailyReportApi'
import {baseURL} from '@/config/index'
interface FormState {
    area: any,
    city: any,
    companyName: any,
    name: any,
    phone: any,
    province: any,
    type: any,
    nativeplace: any,
    cooperationIntention: any,
    sharedWorkspace: any,
    industry: any,
    wechat: any,
    source?:any,
}
export default defineComponent({
    name: 'intent-regist',
    description: '意向客户',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        baseInfo: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        console.log('=======baseInfo', props.baseInfo)
        const disableSubmit = toRef(props, 'disabled')
        const router = useRouter();
        const state = reactive({
            headers: {
                "X-Access-Token": getToken()
            },
            action: baseURL+'/sys/oss/api/uploadRequest',
            disabled: false,
            portrait: false,
            identityCardFace: false,
            identityCardBack: false,
            politicalOutlooks,
            maritalStatus,
            intention: [],
            sharedWorkspace: [],
            industry: [],
            source:1,
        });
        //省市区
        const pcaaData = _area;
        // form表单数据
        const formRef = ref();
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };
        const submitResult = ref(false)
        const formState: UnwrapRef<FormState> = reactive({
            area: '',
            city: '',
            companyName: '',
            name: '',
            phone: '',
            province: '',
            type: null,
            nativeplace: '',
            cooperationIntention: null,
            sharedWorkspace: null,
            industry: undefined,
            wechat: '',
        });
        // console.log('======进本信息',formState);
        // form表单数据验证
        onMounted(() => {
            console.log('router:', router.currentRoute.value.query)
            if(router.currentRoute.value.query.source == "2") {
                state.source = +router.currentRoute.value.query.source
                document.title = "正己科技"
            }else if(router.currentRoute.value.query.source == "3") {
                state.source = +router.currentRoute.value.query.source
                document.title = "智勋设计"
            }else if(router.currentRoute.value.query.source == "4"){
                state.source = +router.currentRoute.value.query.source
                document.title = "首印设计"
            }else {
                state.source = 1
            }
            getIntention().then((res) => {
                console.log('========res',res)
                state.intention = res.customer_intent
            })
            getIndustry().then((res) => {
                state.industry = res.major_industry
            })
            getWorkspace().then((res) => {
                state.sharedWorkspace = res.shared_workspace
            })
        })
        let validatePass = async (rule: RuleObject, value: string) => {
            if (value === '') {
                rule.message = '请输入手机号！'
                return Promise.reject('请输入手机号！');
            } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
                rule.message = '请输入正确的手机号'
                return Promise.reject('请输入正确的手机号！');
            } else {
                return Promise.resolve();
            }
        };
        const rules = {
            companyName: [{ required: true, message: '请输入公司！' }],
            name: [{ required: true, message: '请输入联系人！' }],
            nativeplace: [{ required: true, message: '请选择地址！' }],
            phone: [{ required: true, validator: validatePass, message: '请输入手机号！' }],
            type: [{ required: true, message: '请输入类型！' }],
            cooperationIntention: [{ required: true, message: '请选择合作意向！' }],
            sharedWorkspace: [{ required: true, message: '请选择共享办公区域！' }],
            industry: [{ required: true, message: '请选择行业！' }],
        };


        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                    if (formState.type == 2) {
                        formState.companyName = ''
                    }
                    const form = { ...formState }
                    if (formState.industry) {
                        form.industry = formState.industry.join(',')
                    }
                    form.province = form.nativeplace[0]
                    form.city = form.nativeplace[1]
                    form.area = form.nativeplace[2]
                    form.source = state.source
                    if(form.cooperationIntention !== '5'){
                        form.sharedWorkspace = ''
                    }
                    console.log("form======",form);
                    // console.log('values', formState,);
                    addIntentionCustomer(form).then((res) => {
                        submitResult.value = true
                    })
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        };
        const resetForm = () => {
            formRef.value.resetFields();
            formState.industry = undefined
        };
        return {
            ...toRefs(state),
            disableSubmit,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            pcaaData,
            formRef,
            formItemLayout,
            formState,
            rules,
            submitResult,
            submitForm,
            resetForm
        };
    }
})
