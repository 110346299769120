import request from '@/utils/request'
import tokenRequest from '@/utils/tokenRequest'
import { IUserState, PhoneLogin, Register } from '@/store/interface/user'
import {
  BasicsTab,
} from '@/views/companySettled/data'

interface CompleteHandler {
  taskId: string | number;
  instanceId: string | number;
  variables: {
    comment: string | number;
    pass: boolean;
  }
}
enum Api {
  addCompanySettled = '/hr-server/admission/admissionApplication/add',
  isCanSubmit = '/hr-server/admission/admissionApplication/canSubmit',
  parseBusinessLicense = '/hr-server/recognition/businessLicense/apply',
  getExisting = '/hr-server/admission/admissionApplication/getExisting',
  editCompanySettled = '/hr-server/admission/admissionApplication/edit',
  listHistory = '/activiti-server/activiti/process/listHistory',
  getProcessByActi = '/activiti-server/activiti/definition/find/',
  getTaskList = '/activiti-server/activiti/process/taskList',
  queryById = '/hr-server/admission/admissionApplication/queryById',
  completeHandler = '/activiti-server/activiti/process/complete',
}
// 公司入驻申请-添加
export const addCompanySettled = (params: BasicsTab): any => tokenRequest.post(Api.addCompanySettled, params)
// 公司入驻申请-编辑
export const editCompanySettled = (params: BasicsTab): any => tokenRequest.put(Api.editCompanySettled, params)
// 公司入驻申请-通过id查询
export const queryById = (params:{id:string}): any => tokenRequest.get(Api.queryById+'?id='+ params.id)
// 公司入驻申请-检查是否允许提交入驻申请
export const isCanSubmit = (params: FormData): any => request.post(Api.isCanSubmit, params)
// 营业执照识别
export const parseBusinessLicense = (params: FormData): any => tokenRequest.post(Api.parseBusinessLicense, params)
// 公司入驻申请-获取已存在申请表
export const getExisting = (): any => tokenRequest.post(Api.getExisting)
// 获取审批历史
export const listHistory = (params: { processInstanceId: string | number; }): any => tokenRequest.post(Api.listHistory, params)
// 查询流程
export const getProcessByActi = (params: { activitiId: string | number }): any => tokenRequest.get(Api.getProcessByActi + params.activitiId)
// 获取我的待办列表
export const getTaskList = (params: {
  businessKey: string | number;
  definitionId?: string | number;
  type?: string,
  pageSize: number;
  pageNum: number
}): any => tokenRequest.post(Api.getTaskList, params)
// 提交流程
export const completeHandler = (params: CompleteHandler): any => tokenRequest.post(Api.completeHandler, params)
