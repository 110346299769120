
import { 
    defineComponent, 
    ref, 
    toRef,
    toRefs,
    reactive,
    watch,
    UnwrapRef,
    toRaw
} from 'vue'
import { MinusCircleOutlined, PlusOutlined,UploadOutlined,CloudUploadOutlined } from '@ant-design/icons-vue';
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { Moment } from 'moment';
import { message } from 'ant-design-vue';
import { FileItem, FileInfo } from '@/views/HrUser/data';
import { getToken, getUserInfo } from "@/utils/auth";
import {baseURL} from '@/config/index'
interface Domain {
    educationalType:string;
    highEducational:string;
    graduateTime:Moment | undefined;
    educationalCode:string;
    graduateSchool:string;
    educationalMajor:string;
    educationSystem:string;
    degree:string;
    degreeCode:string;
    educationCard:string;
    degreeCard:string;
    educationCardFile:string;
    degreeCardFile:string;
    degreeCardPng:string;
    educationCardPdf:string;
    key: number;
}
export default defineComponent({
    name:'base-info',
    description:'教育经历',
    components: {
        MinusCircleOutlined,
        PlusOutlined,
        UploadOutlined,
        CloudUploadOutlined
    },
    setup(props, context){
        console.log('=======getToken',getToken())
        const state = reactive({
            headers: {"X-Access-Token": getToken()},
            action:baseURL+'/sys/oss/api/uploadRequest',
            disabled:false,
            educationCard:false,
            degreeCard:false,
        });
        // form表单数据
        const formRef = ref();
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
            },
        };
        const dynamicValidateForm: UnwrapRef<{ domains: Domain[] }> = reactive({
            domains: [],
        });
        //时间赋值
        const timeChange = (ev,key,index) => {
            // console.log('======ev,key,index',ev.format('YYYY-MM-DD'),key,index);
            dynamicValidateForm.domains[index][key] = ev.format('YYYY-MM-DD');
        }
        //上传附件
        const educationCardFileList = ref<FileItem[]>();
        const degreeCardFileList = ref<FileItem[]>();
        const educationFileList = ref<FileItem[]>();
        const degreeFileList = ref<FileItem[]>();
        const degreePngfileList = ref<FileItem[]>();
        const educationPdfFileList = ref<FileItem[]>();
        const handleChange = (info: FileInfo, key: string,index:number) => {
            if (info.file.status === 'uploading') {
                state[key] = true;
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.
                // const url = info?.file?.response?.result?.url;
                let resFileList = [...info.fileList]
                let tempFile = resFileList.map(el =>el?.response?.result?.url)
                console.log('===========info',info,key,index,tempFile);
                dynamicValidateForm.domains[index][key] = tempFile.join(',');
                state[key] = false;
            }
            if (info.file.status === 'error') {
                state[key] = false;
                message.error('上传失败');
            }
        };
        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                    console.log('values', dynamicValidateForm.domains,toRaw(dynamicValidateForm.domains));
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        };
        const resetForm = () => {
            formRef.value.resetFields();
        };
        const removeDomain = (item: Domain) => {
            let index = dynamicValidateForm.domains.indexOf(item);
            if (index !== -1) {
                dynamicValidateForm.domains.splice(index, 1);
            }
        };
        const addDomain = () => {
            dynamicValidateForm.domains.push({
                educationalType:'',
                highEducational:'',
                graduateTime:undefined,
                educationalCode:'',
                graduateSchool:'',
                educationalMajor:'',
                educationSystem:'',
                degree:'',
                degreeCode:'',
                educationCard:'',
                degreeCard:'',
                educationCardFile:'',
                degreeCardFile:'',
                degreeCardPng:'',
                educationCardPdf:'',
                key: Date.now()
            });
        };
        return {
            ...toRefs(state),
            formRef,
            formItemLayout,
            formItemLayoutWithOutLabel,
            dynamicValidateForm,
            educationCardFileList,
            degreeCardFileList,
            educationFileList,
            degreeFileList,
            degreePngfileList,
            educationPdfFileList,
            timeChange,
            handleChange,
            submitForm,
            resetForm,
            removeDomain,
            addDomain
        };
    }
})
