import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { bordered: false }, {
    default: _withCtx(() => [
      _createVNode(_component_a_result, {
        status: "success",
        title: "成功!",
        "sub-title": _ctx.title
      }, {
        extra: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "console",
            type: "primary",
            onClick: _ctx.refresh,
            loading: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode("刷新结果")
            ]),
            _: 1
          }, 8, ["onClick", "loading"]),
          _createVNode(_component_a_button, {
            key: "buy",
            onClick: _ctx.out
          }, {
            default: _withCtx(() => [
              _createTextVNode("退出")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["sub-title"])
    ]),
    _: 1
  }))
}