
import { computed, defineComponent, reactive, unref, watchEffect, toRefs, ref, onMounted ,nextTick} from 'vue'
import { useCountdown } from '@/views/HrUser/useCountdown'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons-vue'
import { UserRegister, 
checkOnlyUserPhone, 
GET_CODE, ADD_USER, 
getProcessByActi, 
getTaskList, 
getFormKey,
getByPhone
} from '@/api/hrUser'
import { message } from 'ant-design-vue'
import type { UnwrapRef } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getUserPhone } from "@/utils/auth"

import { Modal } from 'ant-design-vue';


interface FormState {
  phone: string;
  code: string;
}
export default defineComponent({
  components: {
    PhoneOutlined,
    MailOutlined
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      loadingCode: false,
      count: 60,
      loading: false,
      checked: true,
      visible: false,
      isType: '',
      agreementTime: 6,
      path: 'hrUser',
      query: {
        activitiId: '',
        instanceId: ''
      },
      codeShow:true
    })
    const { currentCount, isStart, start, reset } = useCountdown(state.count)
    const {
      currentCount: currentCountAgreement,
      isStart: isStartAgreement,
      start: startAgreement,
      reset: resetAgreement
    } = useCountdown(state.agreementTime)
    
    const validatePhone = async (rule: RuleObject, value: string) => {
      //   const data: any = router.currentRoute.value.query
      //    nextTick(()=>{
      //   if(data.departId && data.departName ){
      //   if(!data.branchtype||!data.deptHead){
      //       message.warning('当前二维码已停止使用,请联系负责人重新生成新的二维码!')
      //       return
      //   }}
      //  })
      if (value === '') {
        return Promise.reject('请输入手机号码')
      } else {
        if (new RegExp(/^1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4(?:(?:10|4[01])\d{3}|[68]\d{4}|[579]\d{2}))\d{6}$/).test(value)) {
          return new Promise<void>((resolve, reject) => {
            checkOnlyUserPhone({
              phone: value
            }).then(res => {
              console.log('res:', res)
              if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){
                message.warning('没有用户信息，不能直接注册分子公司！')
                //  router.go(-1)
                return
              }else{
            
              state.isType = 'register'

              }

              resolve()
            }).catch(err => {
              console.log(err,'err');
              console.log(router.currentRoute.value.query,'321222');
              if (err.success) {
                 if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){
                message.warning('没有用户信息，不能直接注册分子公司！')
                // router.go(-1)
                return
              }else{
               
               
                state.isType = 'register'

                
                }
                resolve()
              } else {
                 if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){
                   message.success('当前用户可以直接注册分公司！')
                  state.isType = 'register'
                }else{
                    console.log(123);
                    if(err.message=='签约系统默认创建账号'){
                  
                    state.isType = 'register'

                    }else{
                state.isType = 'login'

                    }
                }
                resolve()
              }
            })
          })
        } else {
          return Promise.reject('请输入正确格式的手机号码!')
        }
      }
    }
       const postDisabled = computed(() => {
      if (router.currentRoute.value.query.deptId) {
        return true
      }
        return false
      })
    const rules = {
      phone: [{ required: true, validator: validatePhone, trigger: 'change' }],
      code: [{ required: true, message: '请输入验证码', trigger: 'change' }]
    }
    const formRef = ref()
    const getButtonText = computed(() => {
      return !unref(isStart)
        ? '获取验证码'
        : unref(currentCount)
    })
    const getButtonAgreementText = computed(() => {
      return !unref(isStartAgreement)
        ? '已阅读'
        : unref(currentCountAgreement)
    })
    const formState: UnwrapRef<FormState> = reactive({
      phone: '',
      code: ''
    })
    watchEffect(() => {
     
      formState.code === undefined && reset()
    })
    function handleStart () {
      if (!state.path) {
        message.warning('没有路径参数，请联系管理员！')
        return
      }
       const data: any = router.currentRoute.value.query
       
        if(data.departId && data.departName ){
        if(!data.branchtype||!data.deptHead){
            message.warning('当前二维码已停止使用,请联系负责人重新生成新的二维码!')
            return
        }}
       
      
      formRef.value.validate(['phone']).then(async () => {
        state.loadingCode = true
        const hide = message.loading('验证码发送中..', 0)
        // let smsmode = state.isType === 'login' ? '0' : '1'
        let smsmode = '3'
        if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){
          smsmode = 0 as any
        }
        GET_CODE({ mobile: formState.phone, smsmode }).then(res => {
          console.log(res,'res-----');
          
          setTimeout(hide, 800)
          start()
        }).catch(err => {

          if (err.success) {

            setTimeout(hide, 800)
            start()
          } else {
             if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){
             setTimeout(hide, 800)
            start()
             }else{
            setTimeout(hide, 0)
            message.warning(err.message)

             }
          }
        }).finally(() => {
          state.loadingCode = false
        })
      }).catch((error) => {
        console.log('error', error)
      })
    }
    function onSubmit () {
     const data: any = router.currentRoute.value.query
       if(data.departId && data.departName ){
        if(!data.branchtype||!data.deptHead){
            message.warning('当前二维码已停止使用,请联系负责人重新生成新的二维码!')
            return
        }}
      if (!state.path) {
        message.warning('没有路径参数，请联系管理员！')
        return
      }
      if (!state.checked) {
        message.warning('请阅读入职系统用户须知！')
        return
      }
      formRef.value.validate().then(async () => {
        state.loading = true
        if (state.isType === 'login') {
          login()
        } else if (state.isType === 'register') {
          register()
        }
      }).catch((error) => {
        console.log('error', error)
      })
    }
    function register () {
      UserRegister({
        username: formState.phone,
        password: '123456',
        phone: formState.phone,
        smscode: formState.code
      }).then(res => {
        login()
      }).catch(err => {
        state.loading = false
         if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){   
         login()
      }else{
        message.error(err.message)

      }
      })
    }
    function login () {
       store.dispatch('login', {
        mobile: formState.phone,
        captcha: formState.code,
        remember_me: true
      }).then(res => {
        
        if (state.isType === 'login') {
          activiti()
        } else if (state.isType === 'register') {
          if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){ 
           activiti()
       
          }else{
            let flag=true
            getByPhone({phone:formState.phone}).then(res=>{
              console.log(res,'ressss1111');
       
              // if(res.entryType){
              //  if(router.currentRoute.value.query.departId){
              //   if(res.entryType==1 || res.entryType==3){
              //      activiti()
              //   }else{
                    
              //       message.error('请扫描正确的二维码!')
              //       flag=false
              //       state.loading = false
              //   }
              // }else{
              //     if(res.entryType==2 || res.entryType==3){
              //      activiti()
              //    }else{
              //       message.error('请扫描正确的二维码!')
              //       flag=false
              //       state.loading = false
              //    }
              // }
              // }else{
              //     activiti() 
              // }
      activiti() 
            
              
            }).catch(err=>{
              if(flag){
          ADD_USER({
            mobilePhone: formState.phone,
            stage: 1
          }).then(res => {
            activiti()
          }).catch(err => {
            message.warning('添加失败，请联系管理员!')
            state.loading = false
          })
              }
        
            })
           
          }
        
        }

      }).catch(err => {
        state.loading = false
        message.error(err.message)
      })
    }

    function activiti () {
      console.log(formState.phone,'formState.phone');
      
      //  getByPhone({
      //    phone:formState.phone
      //   }).then(res => {
      //      delete res['updateTime']
      //      delete res['createTime']
      //      delete res['createBy']
      //      delete res['updateBy']
      //     // state.formState = Object.assign(res)
      //     if(res.activitiId){
      //     state.query.activitiId=res.activitiId||''

      //     }
      //     })
      //     .catch(err=>{
      //       message.warning(err.message)
           
      //     })
      setTimeout(() => {
        message.success('验证成功')
        if (Object.keys(state.query).includes('activitiId')) {
          console.log(18,state.query);

          getProc(state.query.activitiId).then(res=>  {
            console.log(res,'77777777');
            
            state.query.instanceId = res.instanceId
            getTaskList({
              businessKey: res.businessKey,
              definitionId: res.definitionId,
              pageSize: 10,
              pageNum: 1
            }).then(result => {
              if (result.list.length) {
                const obj = result.list[0]
                getFormKey({
                  processDefinitionId: res.definitionId,
                  taskId: obj.taskId
                }).then(ress => {
                  if (ress.isEditable == '1') {
                    state.path = 'hrUser'
                    toUrl('1')
                  } else {
                    toUrl('1')
                  }
                }).finally(() => {
                  state.loading = false
                })
              } else {
                toUrl('2')
                state.loading = false
              }
            }).catch(err => {
              toUrl('2')
              state.loading = false
            })
          }).catch(err => {
            toUrl('2')
            state.loading = false
          })
        } else {
           if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){
             console.log('123----');
             
            toUrl()

           }else{
          toUrl()

           }
        }
      }, 1000)
    }
    function toUrl (isActiviti?: string) {
      const obj = { ...state.query } as any
      console.log(obj,'4321');
      console.log(state.path,'state.path');
      if(obj.SubsidiaryReg && obj.SubsidiaryReg==1 ){
        state.path='companyReg'
      }
      if (isActiviti) {
        if(router.currentRoute.value.query.SubsidiaryReg && router.currentRoute.value.query.SubsidiaryReg==1 as any){
        obj['isActiviti'] = isActiviti

        }else{
        obj['isActiviti'] = isActiviti

        }
      }
      router.push({
        path: '/hr/' + state.path,
        query: {
          ...obj
        }
      })
    }
    function agreement () {
      state.visible = true
      startAgreement()
    }
    // 获取流程数据---编辑/查看/启动
    function getProc (activitiId: string) {
      if (!activitiId) return
      return getProcessByActi({ activitiId }).then(res => {
        return res
      })
    }
    onMounted(() => {
      const UserPhone = getUserPhone()
      formState.phone = UserPhone || ''
      validatePhone('' as RuleObject, UserPhone)
     
      let data: any = router.currentRoute.value.query
      
        state.query=data
      if(data.departId && data.departName ){
         if(!data.branchtype||!data.deptHead){
              Modal.confirm({
              title: () => '当前二维码已停止使用,请联系负责人重新生成新的二维码!',
              cancelText: () => '退出',
              icon: () => '',
              onOk() {
                  router.go(-1)
              },
              onCancel() {
                  router.go(-1)
              },
              class: 'test',
              closable: false
            });
        }
       
      
      }

      try {
        state.path = data.path || 'hrUser'
      } catch (error) {
        console.log(error)
      }
    })
    return {
      formState,
      ...toRefs(state),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      getButtonText,
      handleStart,
      isStart,
      onSubmit,
      agreement,
      rules,
      formRef,
      getButtonAgreementText,
      isStartAgreement,
      resetAgreement,
      startAgreement,
      currentCountAgreement,
      postDisabled,
      router
    }
  }
})
