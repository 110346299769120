
const columns = [
    {
        title: '开始时间',
        align: "center",
        dataIndex: 'joincompanydate'
    },
    {
        title: '结束时间',
        align: "center",
        dataIndex: 'leavecompanydate'
    },
    {
        title: '单位',
        align: "center",
        dataIndex: 'companyname',
    },
    {
        title: '职务',
        align: "center",
        dataIndex: 'deptandpost',
    },
    {
        title: '从事何种专业技术工作',
        align: "center",
        dataIndex: 'description',
    },
    {
        title: '证明人',
        align: "center",
        dataIndex: 'certifier',
    },
    {
        title: '操作',
        align: "center",
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
        fixed: 'right',
    },
]
import {
    defineComponent,
    ref,
    toRef,
    toRefs,
    reactive,
    watch,
    onMounted,
    nextTick,
    UnwrapRef
} from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { getWorkExp, addWorkExp, editWorkExp, delWorkExp } from '@/api/largeScreenApi'
import { message } from 'ant-design-vue';
export default defineComponent({
    name: 'education',
    description: '工作经历',
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const id = toRef(props, 'id');
        const state = reactive({
            modalTitle: '',
            tableModal: false,
            columns: columns,
            dataSource: [],
            ipagination: {
                current: 1,
                pageSize: 5,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0,
            },
            loading: false,
            tableChange: (pagination) => {
                console.log('========翻页', pagination)
                state.ipagination.current = pagination.current
                state.ipagination.pageSize = pagination.pageSize
                state.loading = true;
                initData(id.value)
            },
            formState: {
                joincompanydate: '',
                leavecompanydate: '',
                companyname: '',
                deptandpost: '',
                description: '',
                certifier: '',
            }
        })
        const initData = (id) => {
            state.loading = true;
            getWorkExp({
                employeeId: id,
                pageNo: state.ipagination.current,
                pageSize: state.ipagination.pageSize
            }).then(res => {
                state.dataSource = res.records
                state.ipagination.total = res.total;
            }).finally(() => {
                state.loading = false
            })
        }
        const rules = {
            // joincompanydate:[{ required: true, message: '请选择开始时间！' }],
            // leavecompanydate: [{ required: true, message: '请选择结束时间！' }],
            // companyname: [{ required: true, message: '请输入专业及主要类容！' }],
            // deptandpost:[{ required: true, message: '请输入组织单位！' }],
            // description: [{ required: true, message: '请输入证书号！' }],
            // certifier: [{ required: true, message: '请输入学时数！' }],
        }
        //展示弹框
        const showModal = () => {
            state.tableModal = true
            state.modalTitle = '新增'
            state.formState = {
                joincompanydate: '',
                leavecompanydate: '',
                companyname: '',
                deptandpost: '',
                description: '',
                certifier: '',
            }
        }
        // 表单ref
        const formRef = ref();
        // 新增或修改
        const handleSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    if (state.modalTitle == '新增') {
                        addWorkExp({...state.formState,employeeid:id.value as string }).then((res) => {
                            message.success(res.message)
                            watchInitData()
                            state.tableModal = false
                        })
                    } else {
                        editWorkExp({...state.formState,employeeid:id.value as string}).then((res) => {
                            message.success(res.message)
                            watchInitData()
                            state.tableModal = false
                        })
                    }
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        }
        // 修改
        const handleEdit = (record) => {
            state.tableModal = true
            state.modalTitle = '修改'
            state.formState = { ...record }
        }
        // 删除
        const handleDel = (record) => {
            let form = new FormData()
            form.append('id',record.id)
            delWorkExp(form).then((res) => {
                message.success('删除成功')
                watchInitData()
            })
        }
        onMounted(() => {
            nextTick(() => {
                console.log('======教育经历初始化', id.value)
                initData(id.value)
            })
        })
        // 重置刷新
        const watchInitData = () => {
            state.ipagination.current = 1
            state.ipagination.pageSize = 5
            initData(id.value)
        }
        watch(id, watchInitData)
        return {
            labelCol: { span: 9 },
            ...toRefs(state),
            handleSubmit,
            handleEdit,
            handleDel,
            showModal,
            rules,
            formRef
        };
    }
})
