
const columns = [
    {
        title: '发表日期',
        align: "center",
        dataIndex: 'pulishTime'
    },
    {
        title: '名称',
        align: "center",
        dataIndex: 'paperName',
    },
    {
        title: '出版、登载获奖文件',
        align: "center",
        dataIndex: 'paperUrl',
    },
    {
        title: '操作',
        align: "center",
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
        fixed: 'right',
    },
]
import {
    defineComponent,
    ref,
    toRef,
    toRefs,
    reactive,
    watch,
    onMounted,
    nextTick,
    UnwrapRef
} from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { getMonograph, addMonograph, editMonograph, delMonograph } from '@/api/largeScreenApi'
import { message } from 'ant-design-vue';
export default defineComponent({
    name: 'monograph',
    description: '论文&专著',
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const id = toRef(props, 'id');
        const state = reactive({
            modalTitle: '',
            tableModal: false,
            columns: columns,
            dataSource: [],
            ipagination: {
                current: 1,
                pageSize: 5,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0,
            },
            loading: false,
            tableChange: (pagination) => {
                console.log('========翻页', pagination)
                state.ipagination.current = pagination.current
                state.ipagination.pageSize = pagination.pageSize
                state.loading = true;
                initData(id.value)
            },
            formState: {
                pulishTime: '',
                paperName: '',
                paperUrl: '',
            }
        })
        const initData = (id) => {
            state.loading = true;
            getMonograph({
                employeeId: id,
                pageNo: state.ipagination.current,
                pageSize: state.ipagination.pageSize
            }).then(res => {
                state.dataSource = res.records
                state.ipagination.total = res.total;
            }).finally(() => {
                state.loading = false
            })
        }
        const rules = {
            // pulishTime:[{ required: true, message: '请选择发表日期！' }],
            // paperName: [{ required: true, message: '请输入专业及主要类容！' }],
            // paperUrl:[{ required: true, message: '请输入组织名称！' }],
        
        }
        //展示弹框
        const showModal = () => {
            state.tableModal = true
            state.modalTitle = '新增'
            state.formState = {
                pulishTime: '',
                paperName: '',
                paperUrl: '',
            }
        }
        // 表单ref
        const formRef = ref();
        // 新增或修改
        const handleSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    if (state.modalTitle == '新增') {
                        addMonograph({...state.formState,employeeId:id.value as string }).then((res) => {
                            message.success(res.message)
                            watchInitData()
                            state.tableModal = false
                        })
                    } else {
                        editMonograph({...state.formState,employeeId:id.value as string}).then((res) => {
                            message.success(res.message)
                            watchInitData()
                            state.tableModal = false
                        })
                    }
                })
                .catch((error: ValidateErrorEntity<any>) => {
                    console.log('error', error);
                });
        }
        // 修改
        const handleEdit = (record) => {
            state.tableModal = true
            state.modalTitle = '修改'
            state.formState = { ...record }
        }
        // 删除
        const handleDel = (record) => {
            let form = new FormData()
            form.append('id',record.id)
            delMonograph(form).then((res) => {
                message.success('删除成功')
                watchInitData()
            })
        }
        onMounted(() => {
            nextTick(() => {
                console.log('======教育经历初始化', id.value)
                initData(id.value)
            })
        })
        // 重置刷新
        const watchInitData = () => {
            state.ipagination.current = 1
            state.ipagination.pageSize = 5
            initData(id.value)
        }
        watch(id, watchInitData)
        return {
            labelCol: { span: 9 },
            ...toRefs(state),
            handleSubmit,
            handleEdit,
            handleDel,
            showModal,
            rules,
            formRef
        };
    }
})
