
const columns = [
    {
        title: '合同时间',
        align: "center",
        dataIndex: 'signDate'
    },
    {
        title: '合同名称',
        align: "center",
        dataIndex: 'projectName',
        ellipsis:true
    },
    {
        title: '主设计师',
        align: "center",
        dataIndex: 'qzDesignerName',
    },
    {
        title: '专业负责人',
        align: "center",
        dataIndex: 'qzMajorPrincipleName'
    },
    {
        title: '项目负责人',
        align: "center",
        dataIndex: 'qzProjectManagerName',
    },
    {
        title: '图纸扫描',
        align: "center",
        dataIndex: 'paperList',
        slots: { customRender: 'printUrl' },
        width:200,
    },
    {
        title: '合同文本',
        align: "center",
        dataIndex: 'mainPDF',
        slots: { customRender: 'contract' },
        width:200,
    },
    {
        title: '电子合约',
        align: "center",
        dataIndex: 'signPdfFile',
        slots: { customRender: 'electronic' },
        width:200,
    },
    {
        title: '合同正式附件',
        align: "center",
        dataIndex: 'contractFJ',
        slots: { customRender: 'contractFJ' },
        width:200,
    }

]
import {
    defineComponent,
    ref,
    toRef,
    toRefs,
    reactive,
    watch,
    onMounted,
    nextTick,
    computed
} from 'vue'
import { Base64 } from 'js-base64'
import moment from 'moment'
import { getNowWork } from '@/api/largeScreenApi'
export default defineComponent({
    name: 'achievement',
    description: '业绩:项目+单据',
    props: {
        id: {
            type: String,
            default: ''
        },
        employeeId: {
            type: String,
            default: ''
        },
        ids: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        // const id = toRef(props, 'id');
        const employeeId = toRef(props, 'employeeId');
        const state = reactive({
            columns: columns,
            dataSource: [],
            ipagination: {
                current: 1,
                pageSize: 5,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0,
            },
            loading: false,
            tableChange: (pagination) => {
                console.log('========翻页', pagination)
                state.ipagination.current = pagination.current
                state.ipagination.pageSize = pagination.pageSize
                state.loading = true;
                initData(employeeId.value)
            }
        })
        // 双向绑定
        let selectedRowKeys = computed({
            get() {
                return props.ids
            },
            set(v) {
                emit('update:ids', v)
            }
        })
        const onSelectChange = (selectedRowKey) => {
            emit('update:ids', selectedRowKey)
            selectedRowKeys = selectedRowKey;
        };
        // 
        const initData = (id) => {
            state.loading = true;
            getNowWork({
                id: id,
                pageNo: state.ipagination.current,
                pageSize: state.ipagination.pageSize
            }).then(res => {
                state.dataSource = res.records
                state.ipagination.total = res.total;
            }).finally(() => {
                state.loading = false
            })
        }
        const toUrl = (url) => {
            let time = moment().format('_YYYY-MM-DD HH:mm')
            url = encodeURIComponent(url)
            url = url + time
            url = Base64.encode(url)
            window.open(`http://sfsj.shoufusheji.com/FileStore/Download.aspx?auth=${url}`)
        }
        onMounted(() => {
            nextTick(() => {
                // console.log('======业绩初始化', id.value)
                initData(employeeId.value)
            })
        })
        // 重置刷新
        const watchInitData = () => {
            state.ipagination.current = 1
            state.ipagination.pageSize = 5
            initData(employeeId.value)
        }
        watch(employeeId, watchInitData)
        return {
            ...toRefs(state),
            toUrl,
            onSelectChange,
            selectedRowKeys
        };
    }
})
