import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    layout: "horizontal",
    ref: "formRef",
    model: _ctx.formState,
    labelCol: _ctx.labelCol,
    wrapperCol: _ctx.wrapperCol,
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "现任专业技术职务系列",
                name: "jobSeries"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.jobSeries,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.jobSeries) = $event)),
                    placeholder: "请选择职务系列",
                    onSelect: _ctx.handlerSelect
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobSery, (item, key) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: key,
                          value: key
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "onSelect"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (!_ctx.noFlag)
            ? (_openBlock(), _createBlock(_component_a_col, {
                key: 0,
                span: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "现任专业技术职务名称",
                    name: "jobName"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.seriesVisible)
                        ? (_openBlock(), _createBlock(_component_a_select, {
                            key: 0,
                            value: _ctx.formState.jobName,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.jobName) = $event)),
                            placeholder: "请选择职务系列"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.series, (val, key) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: key,
                                  value: key
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(val), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value"]))
                        : (_openBlock(), _createBlock(_component_a_input, {
                            key: 1,
                            value: _ctx.formState.jobName,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.jobName) = $event))
                          }, null, 8, ["value"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (!_ctx.noFlag)
        ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "现任专业技术职务等级",
                    name: "jobGrade"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.formState.jobGrade,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.jobGrade) = $event)),
                        placeholder: "请输入职务等级"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: "现任专业技术职务的任职时间",
                    name: "jobTime"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_date_picker, {
                        value: _ctx.formState.jobTime,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.jobTime) = $event)),
                        format: "YYYY-MM-DD",
                        placeholder: "请选择任职时间",
                        style: {"width":"100%"}
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_row, { style: {"justify-content":"center"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            "html-type": "submit",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("提交")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            style: {"margin-left":"10px"},
            onClick: _ctx.resetForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("置空")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "labelCol", "wrapperCol", "rules"]))
}