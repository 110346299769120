
import { defineComponent, onMounted } from 'vue'
import { secret } from "@/api/hrUser"
import { useRouter } from 'vue-router'
import { message as Message } from 'ant-design-vue'

export default defineComponent({
  name: 'Url',
  components: {},
  setup () {
    const router = useRouter()
    onMounted(() => {
      const data: any = router.currentRoute.value.query
      console.log(data,'99999');
      
      try {
         console.log(123);
        secret({
         id: data.id
        }).then(res=> {
          console.log(res,'888888');
          
          try {
            const obj = {}
            if (res?.url.split('?').length > 1) {
              const arr = res?.url.split('?')[1].split('&')
              arr.forEach(item => {
                const query = item.split('=')
                obj[query[0]] = query[1]
              })
            }
            
            router.push({
              path: res?.url,
              query: {
                ...obj,
                ...res
              }
            })
          } catch (error) {
            console.log('error:', error);
            Message.warning(error)
            
          }
          
        })
      } catch (error) {
        console.log('error:', error);
         Message.warning(error)
      }
    })
    return {
    }
  }
})

