import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    model: _ctx.formState,
    labelCol: _ctx.labelCol,
    wrapperCol: _ctx.wrapperCol,
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "工作单位",
                name: "unit"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.unit,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.unit) = $event)),
                    placeholder: "请填写工作单位",
                    style: {"width":"90%","margin-right":"8px"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "所属区域",
                name: "area"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.area,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.area) = $event)),
                    placeholder: "请填写所属区域",
                    style: {"width":"90%","margin-right":"8px"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "岗位类别",
                name: "post"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.post,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.post) = $event)),
                    placeholder: "请填写岗位类别",
                    style: {"width":"90%","margin-right":"8px"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"justify-content":"center"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            "html-type": "submit",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("提交")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            style: {"margin-left":"10px"},
            onClick: _ctx.resetForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("置空")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "labelCol", "wrapperCol", "rules"]))
}