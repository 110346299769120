
import { computed, defineComponent, reactive, ref, onMounted, watch, toRefs, Ref } from 'vue'
import type { UnwrapRef } from 'vue'
import { useStore } from 'vuex'
import { getByPhone, EDIT_USER, getTaskList, getFormKey, getProcessByActi } from '@/api/hrUser'
import { hrUserFormState } from '@/views/HrUser/data'
import { useRouter } from 'vue-router'
import { getUserPhone, getUserInfo } from '@/utils/auth'

import CompanyTab from '@/views/HrUser/HrUserStep/CompanyTab.vue'
import ResultTab from '@/views/HrUser/HrUserStep/ResultTab.vue'
import HistoryList from '@/views/HrUser/modules/HistoryList.vue'
import { message } from 'ant-design-vue'
import { isArray, isString } from 'lodash'

export default defineComponent({
  components: {

    CompanyTab,
    ResultTab,
    HistoryList
  },
  setup () {
    const router = useRouter()
    const activeKey = ref(1)
    const store = useStore()
    const userInfo = getUserInfo()
    const state: {
      formState: any;
      confirmLoading: boolean;
      mode: string;
      historicFlow: Array<any>;
      query: any;
      instanceId: string
    } = reactive({
      formState: {} as hrUserFormState,
      confirmLoading: false,
      mode: 'left',
      historicFlow: [],
      query: {},
      instanceId: ''
    })
    if (userInfo) {
      state.formState.mobilePhone = userInfo.phone
    } else {
      state.formState.mobilePhone = getUserPhone()
    }
    function toNextTab (key, result) {
      state.confirmLoading = true
      if (result && typeof result === 'object') {
        state.formState = { ...state.formState, ...result }
        /**
         * 修改表单操作
         * 判断stage = 10, 等于10说明已经提交流程，删除stage属性
         **/
        if (key == 6) {
          getProc(state.formState.activitiId).then(res => {
            if (res) {
              state.instanceId = res.instanceId
            }
          })
        }
        if (Object.keys(state.query).includes('isActiviti')) {
          delete result.stage
        }
        EDIT_USER(result).then(res => {
          activeKey.value = key
        }).finally(() => {
          state.confirmLoading = false
        })
      } else {
        activeKey.value = key
        state.confirmLoading = false
      }
    }
    const scrollTop = ref(0)
    function goTop () {
      let timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn () {
        if (scrollTop.value > 0) {
          scrollTop.value -= 250
          document.body.scrollTop = document.documentElement.scrollTop = scrollTop.value
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
        }
      })
    }
    function handleScroll () {
      scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    }

    function changeMode (e) {
      if (e.target.value === 'right') {
        // historyList.value.initHistoryList(state.query.instanceId)
        // state.instanceId = state.query.instanceId
      }
    }

    watch(
      () => activeKey.value,
      () => {
        handleScroll()
        goTop()
      }
    )
    watch(
      () => state.formState.activitiId,
      () => {
        if (state.formState.activitiId) {
          getProc(state.formState.activitiId).then(res => {
            if (res) {
              state.instanceId = res.instanceId
            }
          })
        }
      },
      { immediate: true }
    )
    onMounted(() => {
      const phone = getUserPhone()
      if (phone) {
        state.confirmLoading = true
        getByPhone({
          phone
        }).then(res => {
          delete res.updateTime
          delete res.createTime
          delete res.createBy
          delete res.updateBy
          state.formState = Object.assign(res)
          state.query = router.currentRoute.value.query
          console.log(state.query, 'state.query')
          console.log(state.formState, 'state.formState000000000000000')
          // state.query.activitiId=state.formState.activitiId
          if (state.query.deptHead) {
            state.formState.deptHead = state.query.deptHead || ''
          }
          if (state.formState.entryType != 3) {
            activeKey.value = 1
            state.formState.branchtype = ''
            state.formState.post = ''
            state.formState.province = ''
            state.formState.city = ''
            state.formState.county = ''
            state.formState.scope = ''
            state.formState.aaumSign = ''
            state.formState.branchName = ''
            state.formState.mainIndustry = ''
            state.formState.id = ''
            state.formState.activitiId = ''
            state.formState.deptId = ''
            state.formState.actStatus = 1
            return
          }
          // state.query.tableId=state.formState.id
          if (state.formState.stage === 10) {
            /**
             * 表单stage = 10后续的操作不能改表单的stage
             * 判断是否流程进入
             **/
            if (state.formState.actStatus == 1) { // actStatus=1为流程中,可以查看审批历史
              state.confirmLoading = true
              if (Object.keys(state.query).includes('isActiviti')) {
              // 1.获取流程相关信息
                getProc(state.query.activitiId).then(res => {
                  console.log('jinlaimei ', res)

                  state.query.instanceId = res.instanceId
                  // 2.获取待办列表
                  getTaskList({
                    businessKey: res.businessKey,
                    definitionId: res.definitionId,
                    pageSize: 10,
                    pageNum: 1
                  }).then(result => {
                    console.log(result, 'result')

                    // 判断有没有待办
                    if (result.list.length) {
                      const obj = result.list[0]
                      // 有待办，获取审批时是否可以修改表单
                      getFormKey({
                        processDefinitionId: res.definitionId,
                        taskId: obj.taskId
                      }).then(ress => {
                        if (ress.isEditable == '1') {
                        // 可以修改表单
                          activeKey.value = 1
                        } else {
                          console.log('vvvcc1')
                          toUrl('1')
                        }
                      }).finally(() => {
                        state.confirmLoading = false
                      })
                    } else {
                      console.log('vvvcc2')
                      toUrl('1')
                      state.confirmLoading = false
                    }
                  }).catch(err => {
                    console.log('vvvcc3')
                    toUrl('1')
                    state.confirmLoading = false
                  })
                }).catch((err: any) => {
                  console.log('vvvcc4')
                  toUrl('1')
                  state.confirmLoading = false
                })
              } else {
                activeKey.value = 6
              }
            } else {
              // actStatus=2为流程结束,直接进入新的分子公司注册页面
              activeKey.value = 1
              state.formState.branchtype = ''
              state.formState.post = ''
              state.formState.province = ''
              state.formState.city = ''
              state.formState.county = ''
              state.formState.scope = ''
              state.formState.aaumSign = ''
              state.formState.branchName = ''
              state.formState.mainIndustry = ''
              state.formState.id = ''
              state.formState.activitiId = ''
              state.formState.deptId = ''
              state.formState.actStatus = 1

              console.log(state.formState.actStatus, 'actStatus=2为流程结束,直接进入新的分子公司注册页面')
            }
          } else if (state.formState.stage) {
            activeKey.value = 1
          } else if (state.formState.stage === 0) {
            activeKey.value = 1
          }
        }).catch(err => {
          console.log('vvvcc')

          // message.warning('此账号不能申请！请换一个账号')

          // let obj={SubsidiaryReg:1}
          //   router.push({
          //   path: '/hr/login',
          //   query: {
          //     ...obj
          //   }})
        }).finally(() => {
          state.confirmLoading = false
        })
      } else {
        message.warning('请重新跳转连接')
      }
    })
    // 获取流程数据---编辑/查看/启动
    function getProc (activitiId: string) {
      console.log('getProc', activitiId)

      if (!activitiId) return
      return getProcessByActi({ activitiId }).then(res => {
        return res
      })
    }
    function toUrl (isActiviti?: string) {
      const obj = { ...state.query }
      console.log('toUrl')

      if (isActiviti) {
        obj.isActiviti = isActiviti
      }
      router.push({
        path: '/hr/userExamine',
        query: {
          ...obj
        }
      })
    }
    function refresh (v) {
    //  console.log(v,'321vvv');
      if (state.formState.activitiId) {
        getProc(state.formState.activitiId).then(res => {
          if (res) {
            state.instanceId = res.instanceId
          }
        })
      }
    }
    function backto () {
      if (state.mode == 'right') {
        state.mode = 'left'
      }
    }
    return {
      ...toRefs(state),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      userInfo,
      activeKey,
      toNextTab,
      changeMode,
      refresh,
      backto
    }
  }
})
